import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";

import logo from "../../src/Assets/images/sm-logo.svg";
import Checkbox from "@mui/material/Checkbox";

import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object({
  employeeCode: Yup.string()
    .required("Employee ID can't be blank.")
    .matches(/^\d+$/, "Enter valid Employee ID.")
    .matches(/^\d{1,4}$/, "Enter valid Employee ID."),
  password: Yup.string().required("Password is required"),
});

const Signin = () => {
  const history = useHistory();

  const session = JSON.parse(localStorage.getItem("session")) || null;

  const savedUserData = JSON.parse(localStorage.getItem("userData")) || {};

  const initialValues = {
    employeeCode: savedUserData.employeeCode || "",
    password: "",
    rememberMe: savedUserData.rememberMe || false,
  };
  const calculateExpiryWithRememberMe = () => {
    const expiresInDays = 1; // 7 days, for example
    const currentTime = new Date();
    return new Date(
      currentTime.getTime() + expiresInDays * 24 * 60 * 60 * 1000
    );
  };

  const calculateExpiryWithoutRememberMe = () => {
    const expiresInHours = 1; // 7 days, for example
    const currentTime = new Date();
    return new Date(currentTime.getTime() + expiresInHours * 60 * 60 * 1000);
  };

  const errorStyles = {
    margin: "12px 2px",
    fontSize: "12px", // Adjust the font size as needed
    color: "red", // Change the color if necessary
  };

  const [isLoading, setLoading] = useState(false);

  const signInApi = async (values) => {
    if (values.rememberMe) {
      // save user details in local storage for remeber me
      localStorage.setItem(
        "userData",
        JSON.stringify({
          employeeCode: values.employeeCode,
          rememberMe: values.rememberMe,
        })
      );
    }
    const formData = new FormData();
    formData.append("employee_code", values.employeeCode);
    formData.append("password", values.password);
    formData.append("secret_key", process.env.REACT_APP_GET_ACCESS_KEY_TOKEN);

    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_SSO_ACCESS_TOKEN_URL,
        formData,
        { mode: "no-cors" }
      );
      if (response.statusText === "OK") {
        const tformData = new FormData();
        tformData.append("C-X-ssoJwt", response.data["access_token"]);
        const tresponse = await axios.post(
          process.env.REACT_APP_TIMESHEET_TOKEN_URL,
          tformData,
          { mode: "no-cors" }
        );
        if (tresponse.statusText === "OK") {
          const { token, employee_code, name } = tresponse.data;
          const expirationTime = initialValues.rememberMe
            ? calculateExpiryWithRememberMe()
            : calculateExpiryWithoutRememberMe();

            // storing user data in local storage
          localStorage.setItem(
            "session",
            JSON.stringify({
              accessToken: token,
              employeeCode: employee_code,
              employeeName: name,
            })
          );
          localStorage.setItem("expireTime", expirationTime.toISOString());

          // redirect to previously accessed page
          const redirect = localStorage.getItem("pre-url") || '/'
          localStorage.removeItem("pre-url");
          history.push(redirect, {
            accessToken: token,
            employeeCode: employee_code,
            employeeName: name,
          });
        
          console.log("pushed without remember me");
        }
      }
    } catch (error) {
      toast.error("Incorrect combination of employee code & password");
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (values) => {
    await signInApi(values);
  };

  const expireTimeString = localStorage.getItem("expireTime");
  useEffect(() => {
    console.log(localStorage);
    if (expireTimeString) {
      const expireTime = new Date(expireTimeString);
      const currentTime = new Date();

      if (currentTime > expireTime) {
        console.log("User logged out due to expired session.");
        localStorage.clear();
        history.push("/signin");
      } else {
        console.log(localStorage);

        console.log(session);
        if (session) {
         
          const redirect = localStorage.getItem("pre-url") || '/'
          localStorage.removeItem("pre-url");
          history.push(redirect, {
            accessToken: token,
            employeeCode: employee_code,
            employeeName: name,
          });
          console.log("pushed with session");
        }
      }
    }
  }, [history, expireTimeString]);
  if (!session) {
    return (
      <>
        <Box className="page_container">
          <Container>
            <Box className="main_bx">
              <Grid container alignItems="center" justifyContent="center">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="login_bx">
                    <Box className="al_center">
                      <img src={logo} alt="logo" className="ai_logo" />
                    </Box>

                    <Box className="al_center">
                      <Typography variant="h4" className="fw6 col1">
                        AI Web Scraper Portal
                      </Typography>
                    </Box>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      validateOnChange
                      onSubmit={handleSubmit}
                    >
                      {({ isValid, dirty, values, setFieldValue }) => (
                        <Form>
                          <Box className="textfield" mt={4}>
                            <Field
                              as={TextField}
                              id="filled-password-input"
                              type="text"
                              name="employeeCode"
                              fullWidth
                              className="input_field"
                              placeholder="Employee Id"
                            />
                            <ErrorMessage
                              name="employeeCode"
                              component="div"
                              style={errorStyles}
                              className="error_message"
                            />
                          </Box>

                          <Box className="textfield" mt={2}>
                            <FormControl fullWidth className="input_field">
                              <Field
                                as={FilledInput}
                                id="filled-adornment-password"
                                placeholder="Password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                      disableRipple
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            <ErrorMessage
                              name="password"
                              component="div"
                              style={errorStyles}
                              className="error_message"
                            />
                          </Box>

                          <Box className="checkbox" mt={1}>
                            <FormControlLabel
                              disableRipple
                              control={<Checkbox disableRipple />}
                              label="Remember me"
                              checked={values.rememberMe}
                              onChange={(e) =>
                                setFieldValue("rememberMe", e.target.checked)
                              }
                            />

                            <Typography variant="h6" className="fw5 bl">
                              Note :{" "}
                              <span className="gray fw5">
                                Use Timesheet Credentials for Login
                              </span>
                            </Typography>
                          </Box>

                          <Box mt={2}>
                            <Button
                              variant="contained"
                              className="sign_in_btn bg1"
                              fullWidth
                              disabled={!isValid || !dirty}
                              type="submit"
                            >
                              {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                              ) : (
                                "Sign In"
                              )}
                            </Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <ToastContainer
          position="top-center"
          style={{ fontSize: "16px", width: "600px", height: "200px" }}
        />
      </>
    );
  }
};

export default Signin;
