import React, { useEffect, useState } from 'react';
import { InputAdornment, Radio,FormControl,RadioGroup, Box,   Typography,IconButton,Dialog, DialogTitle, DialogContent, TextField, Button } from '@mui/material';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogContentText from "@mui/material/DialogContentText";

import { AgGridReact } from "ag-grid-react";
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import apiInstance from '../apiInterceptor';
import { handleApiError } from '../utils';

function findCommonBaseUrl(urls) {
    if (urls.length ==0) return '';
    let parts = urls[0].split('/');
    let commonLength = parts.length;
    for (let i = 1; i < urls.length; i++) {
        console.log(urls[i],"url")
        let currentParts = urls[i].split('/');
        let j = 0;
        while (j < commonLength && parts[j] === currentParts[j]) {
            j++;
        }
        commonLength = j;
    }
    return parts.slice(0, commonLength).join('/');
  }

const UpdateUrlModal = ({ open, setOpen, details, version, refresh, setRefresh}) => {
    const [openHeaderSelector, setOpenHeaderSelector] = useState(false)
    const [baseUrl, setBaseUrl] = useState("")
    const [urlData, setUrlData] = useState([])
    const [addUrl, setAddUrl] = useState("")
    const [excelHeaders, setExcelHeaders] = useState([])
    const [selectedHeader, setSelectedHeader] = useState('');
    const [fileName, setFileName] = useState("")
 
    let check = false
    const urlDataFormatter = (data) =>{
      check = false
      data.map(d=>{
        if(d.isInvalid && check==false){
          check=true
        }
      })
        return data.map((d,i) => ({
            url: d.url,
            statusUrl: d.isAdded ? "New URL" : d.isInvalid? "Invalid" :"Valid",
            srno:i+1,
          }));
          
    }
    const handleClose = ()=>{
        setOpen(false)
    }
    const handleAddUrlChange = (e) =>{
        setAddUrl(e.target.value)
    }
    const checkDuplicate = (url, urlArray) => {
        return urlArray.find(obj => obj.url === url)
    }
    const handleSubmit =async ()=>{
      if(urlData.length ==0){
        toast.error("Atleast 1 URL should be present")
        return;
      }
      if(check){
        toast.error("Invalid URLs are present")
        return;
      }
      const submitBody = {
        update_type:"urls",
        url_id: details.url_id,
        urls_data:urlData.map(obj => obj.url),
      }
      const endp = `${version}/update_prompt_comment_info`;
      try {
        const response = await apiInstance.patch(endp, submitBody);
        if(response.statusText==="OK"){
        console.log("PATCH request successful:", response.data);
        setOpen(false)
        toast.success("URLs updated successfully")
        setTimeout(() => {
          setRefresh(!refresh);
        }, 2000);
      }
      } catch (error) {
        console.error("Error sending PATCH request:", error);
        handleApiError(error)
      }
    }
    const handleAddUrl = ()=>{
        if(checkDuplicate(addUrl, urlData)){
            toast.error("URL already present")
            setAddUrl("")
            return
        }
        if(!addUrl.includes(baseUrl) || baseUrl.length<=8){
                toast.error("Invalid url")  
            setAddUrl("")
            return
        }
        let newUrlData = [...urlData];
        newUrlData = [{url:addUrl, isAdded:true}, ...newUrlData]
        setAddUrl("")
        setUrlData(newUrlData)
        toast.success(`New link added`)
    }
    const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const getSelectedRows = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data.url);
    console.log(selectedData,"selected rows"); 
    let newUrlData = [...urlData]

    newUrlData = newUrlData.filter(data => !selectedData.includes(data.url))
    console.log(newUrlData)
    setUrlData(newUrlData)
    if(selectedData.length>0){
      toast.success(`${selectedData.length} rows removed`)
    }else{
      toast.error("No rows selected")
    }
    
  };
  const fetchDataForColumn = () => {
    const file = document.getElementById('url-excel-input').files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
  
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
  
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      const headerRow = jsonData[0];
      const columnIndex = headerRow.indexOf(selectedHeader);
  
      if (columnIndex !== -1) {
        // Extract data under the selected column
        const columnData = jsonData.slice(1).map(row => row[columnIndex]);
        let tempUrlArray = []
        let invalidArray=[]
        let tempBaseUrl = baseUrl;
        if(baseUrl == details.website_url){
          tempBaseUrl = findCommonBaseUrl([details.website_url,columnData[0],columnData[1]]);
          console.log("base2",tempBaseUrl)
          
        }
        for (let row1 of jsonData.slice(1)) {
            // Check if the entire row is empty
            if (row1.every(cell => cell === undefined || cell === null || cell === "")) {
              console.log("Entirely empty row encountered, stopping iteration.");
              break;
            }
            const value = row1[columnIndex];
            if(!urlData.find(obj => obj.url == value) && !tempUrlArray.includes(value)){
              if(value && value.includes(tempBaseUrl) && tempBaseUrl.length > 8){
                tempUrlArray.push(value)
              }else{
                invalidArray.push(value)
              }
            }
            
            
        }
          const newTempUrlData = tempUrlArray.map(url => ({ url: url, isAdded: true }));
          const newTempInvalidData = invalidArray.map(url => ({ url: url, isInvalid:true }));
        setUrlData([...newTempInvalidData,...newTempUrlData, ...urlData])
        if(tempUrlArray.length>0){
          toast.success(`${tempUrlArray.length + invalidArray.length} new links added`)
        }else{
          toast.error(`No new valid links found`)
        }
        if(invalidArray.length>0){
          toast.error(`${invalidArray.length} invalid links found`)
        }
        
        setOpenHeaderSelector(false)
        document.getElementById('url-excel-input').value=""
        setFileName("")
      } else {
        console.log('Selected header not found');
      }
    };
  
    reader.readAsArrayBuffer(file);
    
  };
const handleRadioChange = (event) => {
    setSelectedHeader(event.target.value);
  };
  const handleFileChange = (event)=>{
    const file = event.target.files[0];
    setFileName(file ? file.name : "")
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assuming first sheet is the one you want to extract headers from
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Extract column headers
      const headers = [];
      for (let cell in worksheet) {
        if (cell[0] === '!') continue;
        if (cell[1] === '1') {
          headers.push(worksheet[cell].v);
        } else {
          break;
        }
      }

    setExcelHeaders(headers)
    setOpenHeaderSelector(true)
    };

    reader.readAsArrayBuffer(file);
  }
  
    const [columnDefs] = useState([
        { checkboxSelection: true, headerCheckboxSelection: true, width: 50 },
        {headerName:"Seq", field:"srno",flex:2, maxWidth:"60"},
        {
            headerName: "URLs",
            field: "url",
            minWidth: 250,
            flex: 2,
          },
          {
            headerName: "Status",
            field: "statusUrl",
            maxWidth: 150,
            flex: 2,
          },
      ]);

    useEffect(()=>{
      if(details.urls_data && details.urls_data.length > 0){
        const urlsWithFlags = details.urls_data.map(url => ({ url:url, isAdded: false }));
        setUrlData(urlsWithFlags)
        if(details && details.urls_data){
            if(details.urls_data.length>1){
                setBaseUrl(findCommonBaseUrl([details.website_url,details.urls_data[0], details.urls_data[1]]))
            }else{
              console.log("base",findCommonBaseUrl([details.website_url,details.urls_data[0]]))
                setBaseUrl(findCommonBaseUrl([details.website_url,details.urls_data[0]]))
            }
        }
      }else{
        if(details.website_url){
          const parsedUrl = new URL(details.website_url);
        setBaseUrl(`${parsedUrl.protocol}//${parsedUrl.host}`);
        }
      }
      
        
    },[details])
  return (
    <>
    <Box mx={3} my={3} className="data preview">
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Update Scraper URLs :"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
              <Box className="input_field" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <TextField
                    value={fileName}
                    variant="outlined"
                    fullWidth
                    placeholder='Upload Excel file'
                    InputProps={{
                    type: 'text', // Change input type to text
                    readOnly: true, // Make it read-only to prevent manual text input

                    }}
                />
                <InputAdornment position="end">
                        <Button className="approve_btn" component="label" htmlFor="url-excel-input" style={{ height:'40px', width:'90px' }} >
                        Upload
                        </Button>
                        <input
                        id="url-excel-input"
                        
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept=".xls,.xlsx,.csv"
                        />
                   
                </InputAdornment>
                </Box>
                <Box mt={2} className="input_field" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <TextField
                    id="filled-basic"
                    className=""
                    variant="filled"
                    name="message"
                    fullWidth
                    size='small'
                    placeholder='Add new URL here...'
                    value={addUrl}
                    onChange={handleAddUrlChange}
                    
                />
                <Button style={{height:'40px', width:'100px'}}
                        className="approve_btn"
                        onClick={handleAddUrl}
                      >
                        Add
                      </Button>
                </Box>

                <Box className="whitebx" mt={2}>
                <Box className="fx_sb ai_end heading_bx">
                  <Box>
                    <Typography variant="h6" className=" col1 card_title ">
                      Update URLs
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={"ag-theme-quartz  "}
                  style={{ width: "100%", height: "240px" }}
                >
                  <AgGridReact
                    rowData={urlData ? urlDataFormatter(urlData) :[]}
                    columnDefs={columnDefs}
                    rowHeight={24}
                    headerHeight={28}
                    rowSelection="multiple"
                    onGridReady={onGridReady}
                    filter={true}
                    enableCellTextSelection={true}
                  />
                </Box>
              </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",  
                    height: "30px",
                    margin: "20px 0px 10px 0px",
                  }}
                >
                 
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        className="approve_btn"
                        style={{height:'40px'}}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                      <Button style={{marginLeft:'10px', height:'40px'}}
                        className="approve_btn"
                        onClick={getSelectedRows}
                      >
                        Remove 
                      </Button>
                     
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>

      <Dialog
          open={openHeaderSelector}
          onClose={()=> setOpenHeaderSelector(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Select Column Containing Links :"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={()=> setOpenHeaderSelector(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
              <FormControl component="fieldset">
          <RadioGroup id='column-header-modal-body' value={selectedHeader} onChange={handleRadioChange} >
            {excelHeaders.map((header,index) => {
                return (<FormControlLabel  key={index}
                    value={header}
                    control={<Radio />}
                    label={header} />)
            })}
          </RadioGroup>
        </FormControl>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "20px",
                    margin: "10px 0px 20px 0px",
                  }}
                >
                 
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                        
                      }}
                    >
                      <Button
                        style={{height:'40px', width:'100px'}}
                        className="approve_btn"
                        onClick={fetchDataForColumn}
                      >
                        Submit
                      </Button>
                     
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
    
    </>
  );
};

export default UpdateUrlModal;