import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import apiInstance from "../apiInterceptor";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Image } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CodeIcon from '@mui/icons-material/Code';
import { Link, useHistory, useParams } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import LinkIcon from "@mui/icons-material/Link";
import EditButton from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import CommentIcon from "@mui/icons-material/Comment";
import Visibility from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import CheckIcon from "@mui/icons-material/Check";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Schedule_Using_Date_Time_Frequency from "./Schedule_Using_Date_Time_Frequency";


import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import remove from "../Assets/images/icon/denied 1.gif";
import Checkbox from "@mui/material/Checkbox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilledInput from "@mui/material/FilledInput";
import CircularProgress from "@mui/material/CircularProgress";

import PythonCompiler from "../Components/PythonCompiler";
import CodeDisplayModal from "../Components/CodeDisplayModal";
import useCodeEditorStore from "../store/codeEditorStore";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import axios from "axios";
import Output_History_Table from "./Output_History_Table";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AlertBox from "../Components/AlertBox";
import { runScraperOnce } from "../Api/runScraperOnce";
import scheduling from "../Assets/images/icon/schedule_1.gif";
import { cronToText,formatFrequencyData,
  formatDateString, dataFormatter, dataFormatterOutputHistory, dateFormatter, fileName, handleApiError, isLatestObjectCompleted, jsonFilePath, transformString } from "../utils";
import { LoadingBtn } from "../Components/LoadingBtn";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import useHomeStore from "../store/homeStore";

const Failed_Details_Page = ({ detailType, version,}) => {
  const session = JSON.parse(localStorage.getItem("session")) || null;
  const location = useLocation()
  useEffect(()=>{
    if(!JSON.parse(localStorage.getItem("session"))){
      localStorage.setItem("pre-url",location.pathname)
      history.push("/signin");
    }
  },[])
  const setHomeRowData = useHomeStore((state) => state.setRowData);
  const { urlid } = useParams();
  const history = useHistory();
  const pushVersion = version == "v2" ? "" : "v1/"
  // dropdown option
  // radio button

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [csure, setSure] = useState(false);
  const [usnOpen, setUsnOpen] = useState(false);

 

  const [enableState, setEnableState] = useState(false)
  const [cronexp, setCronExp] = useState("");
  const [schedule] = useState(false);
  const [open4, setOpen4] = useState(false);
  const cronRegex =
  /^((\*|([0-5]?\d)(-([0-5]?\d))?)(\/([0-5]?\d)?)?)\s+((\*|([01]?[0-9]|2[0-3])(-([01]?[0-9]|2[0-3]))?)(\/([01]?[0-9]|2[0-3]))?)\s+((\*|([0-2]?[0-9]|3[0-1])(-([0-2]?[0-9]|3[0-1]))?)(\/([0-2]?[0-9]|3[0-1]))?)\s+((\*|([1-9]|1[0-2])(-([1-9]|1[0-2]))?)(\/([1-9]|1[0-2]))?)\s+((\*|[0-7]|([0-7](,[0-7])+))(\-([0-7]))?)$/;


  const [update, setUpdate] = useState({});
  const [temp, setTemp] = useState("");


  const {handleOpenEditor} = useCodeEditorStore()


  const [details, setDetails] = useState([]);
  const [outputHistoryData, setOutputHistory] = useState();
  const [fileData, setFileData] = useState({
    data: {},
    id: null,
  });

  const [files, setFiles] = useState([]);
  const [openRunOnce, setOpenRunOnce] = useState(false);

  const [activitylogs, setActivitylogs] = useState([]);
  const [openal, setOpenal] = React.useState(false);
 
  const handleClickOpenal = () => {
    if(activitylogs.length == 0){
      toast.info("No activity logs found")
      return
    }
      setOpenal(true);
  };
  const handleCloseal = () => {
      setOpenal(false);
  };


  const [refresher, setRefresher] = useState(false);

  const [imageModal, setImageModal] = useState(false)
  const [currImage, setCurrImage] = useState("")

  // row data
  const [rowData, setRowData] = useState();
  // header row
  const [colDefs] = useState([
    {
      headerName: "",
      field: "comments",
      value: "1",
      minWidth: 20,
      flex: 1,
      cellRenderer: (params) => (
        <>
          <Box className="fx_pc">
            <Tooltip
              title={
                params.data.command === "scrape"
                  ? "Update Prompt"
                  : "Update Comment"
              }
            >
              <IconButton
                className="iconbutton_comment"
                onClick={() => {
                  setTemp(
                    params.data.command === "scrape"
                      ? params.data.prompts
                        ? params.data.prompts
                        : "?"
                      : params.data.comments
                      ? params.data.comments
                      : "-"
                  );
                  setUpdate({
                    step_id: `${params.data.seq}`,
                    url_id: urlid,
                    update_type:
                      params.data.command === "scrape" ? "prompt" : "comment",
                    [params.data.command === "scrape" ? "prompt" : "comment"]:
                      params.data.command === "scrape"
                        ? params.data.prompt
                          ? `${params.data.prompt}`
                          : "-"
                        : params.data.comment
                        ? `${params.data.comment}`
                        : "-",
                  });
                  handleClickOpen();
                }}
              >
                {params.data.command === "scrape" ? null : params.data
                    .command === "open" ? null : (
                  <CommentIcon fontSize="small" className="comment_icon" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ),
    },

    { headerName: "Seq", field: "seq", minWidth: 60, flex: 1 },
    { headerName: "Command", field: "command", minWidth: 100, flex: 1 },

    { headerName: "XPath", field: "xpath", minWidth: 130, flex: 3 },
    {
      headerName: "Prompts",
      field: "prompts",
      minWidth: 250,
      flex: 2,
    },
    { headerName: "Comments", field: "comments", minWidth: 250, flex: 2 },
    // {
    //   field: "",
    //   value: "1",
    //   minWidth: 55,
    //   flex: 1,
    //   headerClass: "center-header",
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    //   cellRenderer: (params) => (
    //    <>
    //     {(params.data.command != "open" && params.data.image != "-") &&  <IconButton
    //       onClick={() =>{
    //        setCurrImage(params.data.image)
    //        setImageModal(true)
    //       }
    //       }
    //     >
    //       <Image className="comment_icon" fontSize="small" />
    //     </IconButton>}
    //     </>
    //   ),
    // },
  ]);

  /* modal box1 */

  const handleClickOpen = () => {
    console.log(update);
    setOpen(true);
  };
  const [selectedOption, setSelectedOption] = useState("b");
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [isDownloadingState, setDownloadingState] = useState(false)

  const handleClose = () => {
    let updation = { ...update };
    updation["prompt"] = temp;
    updateAPI(updation);

    setOpen(false);
    setTemp("");
    setSure(false);
    setUpdate({});
  };

  /* modal box2 */
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClickOpen2 = async (value) => {
    await fetchJSONAPI(value);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);

    setFileData({ data: {}, id: null });
    setSure(false);
  };
  const handleClickOpenRunOnce = () => {
    setOpenRunOnce(true);
  };
  const handleCloseRunOnce = () => {
    setOpenRunOnce(false);
  };

  const handleCloseUsn = () => {
    let updation = { ...update };
    updation["scrapper_name"] = temp;
    updateAPI(updation);

    setUsnOpen(false);
    setTemp("");
    setSure(false);
    setUpdate({});
  };

  useEffect(()=>{
    if(outputHistoryData && outputHistoryData.some(data=> data.status == "Scheduled" || data.status == "In_progress")){
      setTimeout(()=>{
        urlidAPI(detailType == "disabled" ? "disabled" : "not_working")
      },20000)
    }
},[outputHistoryData])

  const urlidAPI = async (status) => {

    const endp = `/${version}/get_url_data?url_id=${urlid}&status_type=${status}`;
    try {
      const response = await apiInstance.get(endp);
   
      if (response.statusText === "OK") {
        if(detailType == "disabled"){
          response.data.schedular_history = response.data.schedular_history.filter(data => data.progress_status == "completed" || data.progress_status == "failed")
        }
        setOutputHistory(
          
          dataFormatterOutputHistory(response.data.schedular_history)
        );
        setRowData(dataFormatter(response.data.xpath_master));
        setActivitylogs(response.data.activity_logs);
        response.data.transactions
          ? setFiles(jsonFilePath(response.data.transactions))
          : console.log("no transactions");
        setDetails(response.data);
      }
    } catch (e) {
      setRowData([]);
      console.log(e);
    }
  };

  useEffect(() => {
    urlidAPI(detailType == "disabled" ? "disabled" : "not_working");
  }, [urlid, refresher]);

  const fetchJSONAPI = async (value,flag) => {
    const url = `${version}/preview_json_data`;

    try {

      if(version == "v2"){
        const response = await apiInstance.post(url,{
          prj_id:`${details.project_code}`,
          json_bucket_file_path:value.path
        });
  
        if (response.statusText === "OK") {
          setFileData({ data: response.data.preview, id: value.id });
        }
      }else{
        const response = await axios.get(process.env.REACT_APP_BUCKET_URL + value.path);
        if (response.statusText === "OK") {
          setFileData({ data: response.data, id: value.id });
        }
      }
      
    } catch (e) {
      console.log(e);
    }
  };

  const extractLogsFromUrl = async (url) => {
    if (!url) return "No logs found";
    const response = await axios.get(url);
    const data = await response.data;
    return data;
  };


  const schedulerAPI = async (value) => {
    if (cronRegex.test(value)) {
  
      const urlcron = encodeURIComponent(value);

      const endp = `${version}/reschedule-task/?job_id=${details.job_id}&reschedule_by=${session.employeeCode}&new_cron_expression=${urlcron}`;

      try {
        const response = await apiInstance.post(endp, null);
        if (response.statusText === "OK") {
      
          toast.success(`Succesfully scheduled scraper for ${urlid}`);
          setTimeout(() => {
 
            history.push(`/${pushVersion}`);
          }, 2000);
        }
      } catch (e) {
        console.log(e);
        handleApiError(e)
      }
    }
  };

  const updateAPI = async (data) => {
    setUpdate((prev) => ({
      ...prev,
      prompt: temp,
    }));

    const endp = `${version}/update_prompt_comment_info`;

    try {
      const response = await apiInstance.patch(endp, data);
      console.log("PATCH request successful:", response.data);
    } catch (error) {
      handleApiError(error)
      console.error("Error sending PATCH request:", error);
    }
  };

  const removeAPI = async () => {
    const endp = `${version}/delete_scrapper`;
    const data = {
      url_id: urlid,
      emp_code: session.employeeCode,
      disable_flag: false,
    };
    try {
      const response = await apiInstance.patch(endp, data);
      console.log("PATCH request successful:", response.data);
      toast.success("Successfully removed scraper");

      setTimeout(() => {
        setHomeRowData([]);
        history.push(`/`, { status: "disabled" });
      }, 2000);
    } catch (error) {
    handleApiError(error)
      console.error("Error sending PATCH request:", error);
    }
  };


  const rowHeight = 24;
  const headerHeight = 28;




  const handleClickOpenOutputHistory = async (value,details1, type,setIsDownloading) => {

    await fetchJSONOutputHistory(value,details1,type,setIsDownloading);
    if(type.includes("pre")) setOpen2(true);
  };

  const handleDownload = (data,filename,type) => {
    if(version == "v2"){
      if(!data){
        setDownloadingState(false)
        return;
      }
      const link = document.createElement('a');
    link.href = data;
    link.download = fileName; 

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      setDownloadingState(false)
    }, 1000);
    toast.success("Download started successfully")
    document.body.removeChild(link);
    return;
    }
   
    const jsonData = JSON.stringify(data);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename; 
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  };

  const fetchJSONOutputHistory = async (value,details1,type,setIsDownloading) => {
    const url = `${version}/preview_json_data`;

    try {

      if(version == "v2"){
        const response = await apiInstance.post(url,{
          prj_id:`${details1.project_code}`,
          json_bucket_file_path:value.json?.replace(process.env.REACT_APP_BUCKET_URL,""),
          log_bucket_file_path: value.log?.replace(process.env.REACT_APP_BUCKET_URL,"")
          
        });
        let tempValue = value.json;
        if(type.includes("log")){
          tempValue = value.log
        }
       
  
        if (response.statusText === "OK") {
          setIsDownloading(false)
          if(!tempValue){
            tempValue = "none"
          }
          const parts = tempValue
            ?.replace(process.env.REACT_APP_BUCKET_URL, "")
            .split("/");
          const fileNameDownload = parts[parts.length - 1];
          if (type.includes("json")) {
            setFileData({
              data: response.data.preview,
              id: tempValue.id,
              type: type,
              fileName: fileNameDownload,
              presigned_url: response.data.json_presigned_url,
            });
          } else {
            const logContent = await extractLogsFromUrl(
              response.data.log_presigned_url
            );
          
            setFileData({
              data: logContent,
              id: tempValue.id,
              type: type,
              fileName: fileNameDownload,
              presigned_url: response.data.log_presigned_url,
            });
          }
          

          if(!type.includes("pre")){
            const parts  =tempValue?.replace(process.env.REACT_APP_BUCKET_URL,"").split('/');
            const fileNameDownload = parts[parts.length-1];
            if(type == "logs"){
              handleDownload(response.data.log_presigned_url, fileNameDownload, type)
            }else{
              handleDownload(response.data.csv_presigned_url ? response.data.csv_presigned_url: response.data.json_presigned_url, fileNameDownload, type)
            }
            
          }
        }
      }else{
        let tempValue = value.json;
        if(type.includes("log")){
          tempValue = value.log
        }
        const response = await apiInstance.get(tempValue);
        if (response.statusText === "OK") {
          setFileData({ data: response.data, id: tempValue.id });
          if(type!=""){
            const parts  =tempValue.replace(process.env.REACT_APP_BUCKET_URL,"").split('/');
        
            const fileNameDownload = parts[parts.length-1];
          
            if(type == "logs"){
              handleDownload(response.data, fileNameDownload, type)
            }else{
              handleDownload(response.data.preview, fileNameDownload, type)
            }
          }
        }
      }
      
    } catch (e) {
      console.log(e);
    }
  };
  function getMostRecentAddedOn(dataArray) {
    if (dataArray.length === 0) {
        return null; 
    }

    let mostRecentDate = dataArray[0].added_on; 
    for (let i = 1; i < dataArray.length; i++) {
        if (new Date(dataArray[i].added_on) > new Date(mostRecentDate)) {
            mostRecentDate = dataArray[i].added_on; 
        }
    }
    return mostRecentDate; 
}


  // header and row height

  return (
    <>
      <Header />
      <Box id="page-container">
        <Container maxWidth>
          <Grid container spacing={1} mb={1} justifyContent="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="fx_sb_mob">
                <Box>
                  <Typography variant="h4" className="col1 fw5 ">
                    {detailType == "disabled"
                      ? "Disabled Scraper Details"
                      : "Failed Scraper Details"}
                  </Typography>
                </Box>
                <Box className="fx_fs margin_10">
                  <Box mr={2}>
                    <Button
                      className="approve_btn"
                      onClick={() => {
      
                        history.push(`/`);
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box mr={2}>
                    <Button
                      className="approve_btn"
                      onClick={() => {
                        setOpen3(true);
                      }}
                    >
                      Remove
                    </Button>
                  </Box>
                  <Box>
                    <Button className="approve_btn" onClick={handleClickOpenal}>
                      Activity Logs
                    </Button>
                  </Box>
                  {(detailType =="disabled" || detailType =="failed") && (<Box ml={2}>
                    <Button
                      className="Enable_btn  "
                      disabled={enableState}
                      onClick={() => {
                        if((files.some((file) => file.status == "approved") &&
                        isLatestObjectCompleted(outputHistoryData)) || detailType == "disabled" ){
                          setEnableState(true)
                        }else{
                          toast.info("Latest Transaction should be completed, Run Scapper once")
                        }
                        
                      }}
                    >
                      Enable
                    </Button>
                  </Box>)}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={2.4} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        window.open(details.website_url, "_blank");
                      }}
                    >
                      <LinkIcon fontSize="small" className="col2 card_icon4" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        {urlid}
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                        title={details.website_url ? details.website_url : "-"}
                      >
                        {details.website_url ? details.website_url : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item lg={2.4} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        setTemp(
                          details.scraper_name ? details.scraper_name : "-"
                        );
                        setSure(false);
                        setUpdate({
                          url_id: urlid,
                          update_type: "scrapper_name",
                          scrapper_name: details.scraper_name
                            ? details.scraper_name
                            : "-",
                        });
                        setUsnOpen(true);
                      }}
                    >
                      <EditButton
                        fontSize="large"
                        className="col2 card_icon4"
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scraper Name{" "}
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {details.scraper_name ? details.scraper_name : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item lg={2.4} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <DescriptionIcon
                        fontSize="large"
                        className="col2 card_icon4"
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scraper Type
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {details.urls_data &&
                            details.urls_data.length > 0 && details.scraper_type && details.scraper_type === "PAGINATION" ?
          transformString(details.scraper_type +
          " > " +
          details.pagination_type +
          " > " +
          details.data_source_type + " > " + "MULTI_URL")
           : details.scraper_type && details.scraper_type === "PAGINATION"
            ? transformString(details.scraper_type +
            " > " +
            details.pagination_type +
            " > " +
            details.data_source_type)
            :details.urls_data &&
            details.urls_data.length > 0 && details.scraper_type && details.scraper_type === "NO_PAGINATION"? transformString("NO_PAGINATION > MULTI_URL") : details.file_download_flag ? "File Download" : details.scraper_type ? transformString(details.scraper_type) : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>

            {/* <Grid item lg={2} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <ScheduleIcon
                        fontSize="large"
                        className="col2 card_icon4"
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scheduler Frequency{" "}
                      </Typography>
                      <Tooltip
                        title={
                          details.chrone_expression
                            ? cronToText(details.chrone_expression)
                            : "-"
                        }
                      >
                        <Typography
                          variant="h5"
                          className=" gray card_subtitle one"
                        >
                          {details.chrone_expression
                            ? cronToText(details.chrone_expression)
                            : "-"}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid> */}

            <Grid item lg={2.4} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <TodayIcon fontSize="large" className="col2 card_icon4" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scheduled On
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {details.schedular_history
                          ? dateFormatter(getMostRecentAddedOn(details.schedular_history))
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item lg={2.4} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <TodayIcon fontSize="large" className="col2 card_icon4" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        {detailType == "disabled" ? "Disabled On" : "Failed On"}
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {detailType == "disabled"
                          ? details.disabled_on
                            ? dateFormatter(details.disabled_on)
                            : "-"
                          : details.failed_on
                          ? dateFormatter(details.failed_on)
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Box className="whitebx" mt={1}>
                <Box className="fx_sb ai_end heading_bx">
                  <Box>
                    <Typography variant="h6" className=" col1 card_title ">
                      Scrapping Commands
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={"ag-theme-quartz  "}
                  style={{ width: "100%", height: "173px" }}
                >
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    filter={true}
                    enableCellTextSelection={true}
                  />
                </Box>
              </Box>

              <Box className="whitebx" mt={1} style={{height:'240px'}}>
                <Output_History_Table
                  response_data={outputHistoryData}
                  on_preview={handleClickOpenOutputHistory}
                  on_download={handleClickOpenOutputHistory}
                  details={details}
                />
                {(version =="v2" && detailType=="failed") &&
                <Box mt={2} className="al_right">
              <Button onClick={()=> handleClickOpenRunOnce()} className="run_scraper_btn">  Run Scraper   </Button>
              </Box>
                }   
              </Box>
            </Grid>

            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="whitebx" mt={1}>
                    <Box className="heading_bx">
                      <Typography variant="h6" className=" col1 card_title ">
                        Data Preview
                      </Typography>
                    </Box>

                    <Box className="preview_mainbx">
                      {files.map((file, index) =>
                        file.id ? (
                          <Box className="preview_bx al_center " p={1}>
                            <Box>
                              <Typography
                                variant="h6"
                                className="one bl xstext  "
                              >
                                {"File Name:"}
                              </Typography>
                              <Typography
                                variant="h6"
                                className="card_preview_heading bl xstext  "
                              >
                                {file.status === "approved" ||
                                file.status === "completed"
                                  ? fileName(file.path)
                                  : "Preview In Pending"}
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Tooltip
                                title={
                                  file.path
                                    ? file.status === "approved"
                                      ? "Approved"
                                      : "Approve"
                                    : null
                                }
                                arrow
                              >
                                {" "}
                                <IconButton
                                  style={{
                                    backgroundColor:
                                      file.status === "approved"
                                        ? "green"
                                        : "#1930ab",
                                  }}
                                  disabled={true}
                                >
                                  {file.loader ? (
                                    <CircularProgress
                                      size={12}
                                      style={{ color: "white" }}
                                    />
                                  ) : (
                                    <CheckIcon
                                      className="approve_icon"
                                      style={{ color: "white" }}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Preview" arrow>
                                <IconButton
                                  onClick={() => handleClickOpen2(file)}
                                  disabled={file.path ? false : true}
                                  className="eye_btn"
                                >
                                  <Visibility
                                    fontSize="medium"
                                    className="wh approve_icon  "
                                  />
                                </IconButton>
                              </Tooltip>
                              {localStorage.getItem("techLead") =="true" &&  
                              <Tooltip
                                style={{
                                  display:
                                     file.status !="approved"
                                      ? "none"
                                      : "inline-flex",
                                

                                }}
                                title="Code"
                                arrow
                              >
                                <IconButton
                                    onClick={() => {handleOpenEditor(file, details)}}
                                    disabled={
                                      file.path
                                        ? file.status != "approved"
                                          ? true
                                          : false
                                        : true
                                    }
                                  className="eye_btn"
                                >
                                  <CodeIcon
                                    fontSize="medium"
                                    className="wh approve_icon "
                                  
                                  />
                                </IconButton>
                              </Tooltip>
}
                            </Box>
                          </Box>
                        ) : null
                      )}
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {enableState ? (<Box className="whitebx2 schedule">
                    <Box className="heading_bx fx_sb_mob">
                      <Box className="">
                        <Typography variant="h6" className=" col1 card_title ">
                          Schedule
                        </Typography>
                      </Box>

                      <Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={selectedOption}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="b"
                              control={<Radio />}
                              label="Using Date, Time & Frequency"
                            />
                            <FormControlLabel
                              value="a"
                              control={<Radio />}
                              label="Using Cron Expression"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>

                    {
                      selectedOption === "a" ? (
                        <Box className="cron_expression" my={2}>
                          <Grid container justifyContent="center" spacing={1}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <FormControl fullWidth className="input_field">
                                <FilledInput
                                  className="textfield_cron"
                                  id="filled-adornment-password"
                                  placeholder="Enter Cron Expression"
                                  onChange={(event) => {
                                    setCronExp(event.target.value);
                                  }}
                                />
                              </FormControl>
                              <Box mt={1} className="al_left">
                                <Typography variant="h6" className="gray desc">
                                  {" "}
                                  Note: A cron expression is a string
                                  representing a schedule in a time-based job
                                  scheduling system (like Unix cron). It is
                                  typically made up of five or six fields,
                                  separated by space, that represent different
                                  units of time.
                                </Typography>

                                <Typography
                                  variant="h6"
                                  className="xstext"
                                  mt={1}
                                >
                                  {" "}
                                  Example: A cron expression 0 12 * * Mon means
                                  the task will run at 12:00 PM every Monday.
                                  Learn more{" "}
                                  <a
                                    href="https://crontab.guru/"
                                    className="a_underline"
                                    target="_blank"
                                  >
                                    click here
                                  </a>
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box className="fx_c" mt={1}>
                            <Button
                              className="approve_btn"
                              disabled={ !cronRegex.test(cronexp)}
                              onClick={() => {
                                setOpen4(true);
                              }}
                            >
                              Re-schedule
                            </Button>
                          </Box>
                          
                        </Box>
                      ) : (
                        <Schedule_Using_Date_Time_Frequency
                          urlid={urlid}
                          schedule_flag={true}
                          emp_code={session.employeeCode}
                          job_id={details.job_id ? details.job_id : null}
                          reschedule_flag={true}
                          version={version}
                          allowed={detailType == "disabled" ||
                            (files.some((file) => file.status == "approved") &&
                            isLatestObjectCompleted(outputHistoryData))
                          }
                          cancelEnable={()=>{setEnableState(false)}}
                          pageType="failed"
                        />
                      )
                    }
                  </Box>):(
                  <Box className="whitebx2 schedule">
                    <Box className="heading_bx fx_sb_mob  ">
                      <Box className="">
                        <Typography variant="h6" className=" col1 card_title ">
                          {detailType == "disabled"
                            ? "Disabled Reason"
                            : "Exceptions"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box style={{ width: "100%" }}>
                      {detailType == "disabled" ? (
                        <AlertBox
                          title={""}
                          text={
                            details.disable_reason
                              ? details.disable_reason
                              : '-'
                          }
                        />
                      ) : (
                        <AlertBox
                          title={""}
                          text={
                            details.exceptions
                              ? JSON.stringify(
                                  details.exceptions.error_msg,
                                  null,
                                  2
                                )
                              : details.schedular_history? (details.schedular_history.length>0 && details.schedular_history[0].error_msg)  ? `${details.schedular_history[0].error_msg.slice(0,600)}...` :'-' :'-'
                          }
                        />
                      )}
                    </Box>
                  </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Schedule cron exp */}
      <Box className="data preview">
        <Dialog
          open={open4}
          onClose={handleClose4}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to re-schedule?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  schedulerAPI(cronexp);
                }}
                autoFocus
                className="approve_btn"
              >
                Re-schedule
              </Button>
            </Box>
            <Box>
              <Button onClick={handleClose4} autoFocus className="cancel_btn">
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      {/* remove alert dialog */}

      <Box className="data preview">
        <Dialog
          open={open3}
          onClose={() => {
            setOpen3(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={remove}
                      alt="remove"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to remove?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  removeAPI();
                  setOpen3(false);
                }}
                autoFocus
                className="approve_btn"
              >
                Ok
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  setOpen3(false);
                }}
                autoFocus
                className="cancel_btn"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      {/* modal box1 */}

      <Box className="comment">
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" className=" col1 card_title">
            {update.update_type == "prompt" ? "Prompts :" : "Comments :"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    name="message"
                    placeholder="Prompts information"
                    fullWidth
                    value={temp}
                    onChange={(event) => setTemp(event.target.value)}
                  />
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "30px",
              margin: "10px 22px 10px 22px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="Are you sure"
                  checked={csure}
                  onChange={() => {
                    setSure(!csure);
                  }}
                  size="10px"
                />
              }
              label={
                <span style={{ fontSize: "12px" }}>
                  Are you sure you want to update changes?
                </span>
              }
            />
            <Box>
              <Button
                onClick={() => {
             
                  handleClose();
                }}
                autoFocus
                className="approve_btn"
                disabled={!csure}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
            {/* Run Once Modal */}
            <Box className="data preview">
        <Dialog
          open={openRunOnce}
          onClose={handleCloseRunOnce}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Re-Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to run scraper once ?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={async () => {
                  await runScraperOnce(urlid,session.employeeCode, version)
                  setOpenRunOnce(false)
                  urlidAPI(detailType == "disabled" ? "disabled" : "not_working");
                }}
                autoFocus
                className="approve_btn"
              >
                Run Once
              </Button>
            </Box>
            <Box>
              <Button onClick={handleCloseRunOnce} autoFocus className="cancel_btn">
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      {/* activity log */}

      <React.Fragment>
                <Dialog
                    onClose={handleCloseal}
                    aria-labelledby="customized-dialog-title"
                    open={openal} 
                    maxWidth="lg"
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
                        <Typography variant='h4' className='col1 fw5' gutterBottom>Activity</Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                      
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    <DialogContent dividers className="history_drawer" my={2}>
                   <Box my={2}>
                    
                   <table>
                        <tbody>
                          {activitylogs.map(activity =>{
                            return (<tr className='ai_top mb2'>
                              <td className='ai_top  '>
                                  <IconButton className='padding0'><FiberManualRecordIcon className='xtext_icon' /></IconButton> <Typography variant='h6' className='bl xtext lg2 fw4'>
                                      { <span>
                                        {(activity.action_by_emp_id == session.employeeCode ? "You" : activity.action_by)} {/* Underline action_by */}
                                        {" "}
                                        <strong>{activity.action_type=="RENAME_COLUMN" ?
                                        <>
                                       
                                         {"updated output preview keys" } 
                                         <br/>
                                         <br/>
                                        <span style={{marginTop:'3px'}}>{`Updated headers:  ${JSON.stringify(activity.action_changes ? activity.action_changes.new_changes : '-')}`}</span>
                                       </>
                                         :activity.action_msg}</strong> {/* Bold action_msg */}
                                        {"."}
                                        {activity.action_changes && activity.action_changes.new_changes ? (
                                          " " +
                                          (activity.action_type === 'RE-SCHEDULE' || activity.action_type === 'SCHEDULE'
                                            ? typeof activity.action_changes.new_changes === 'string'
                                              ? cronToText(activity.action_changes.new_changes) + "."
                                              : formatFrequencyData(activity.action_changes.new_changes)
                                            : activity.action_type === 'RENAME_COLUMN'
                                            ? ""
                                            : activity.action_type === 'CODE_CHANGES'
                                            ? ""
                                            : "New " + activity.action_msg + ": " + activity.action_changes.new_changes + ".")
                                        ) : ""}
                                      </span>
                                      }</Typography>
                              </td>
                              <td><Typography variant='h6' ml={5} className='bl xtext lg fw4'>{activity.action_on.includes('T') ? dateFormatter(activity.action_on): formatDateString(activity.action_on)}</Typography></td>
                          </tr>)
                          })}
                            
                            

                        </tbody>
                    </table>
                   </Box>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button className='sendbtn wh' onClick={handleCloseal}>
                            Save changes
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </React.Fragment>
      {/* modal box2 */}

      <Box className="data preview">
        <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Data Preview :"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen2(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={2}
                    maxRows={8}
                    name="message"
                    fullWidth
                    value={ fileData.type && fileData.type.includes("logs")
                      ? fileData.data
                      : JSON.stringify(fileData.data, null, 2)}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {fileData.presigned_url != undefined && (
                  <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",

                    margin: "10px 0px 0px 0px",
                  }}
                >
               
                  <Box>
                  {isDownloadingState ? <LoadingBtn/> : 
                    <Button
                      autoFocus
                      className="approve_btn"
                      onClick={()=>{
                        setDownloadingState(true)
                        handleDownload(fileData.presigned_url, fileData.fileName, fileData.type)
                      }}            
                    >
                      Download
                    </Button>
}
                  </Box>
                </Box>
                  )}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "20px",
                    marginTop: "5px",
                  }}
                ></Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
      <Box className="data preview">
        <Dialog
          open={imageModal}
          onClose={() => {
            setImageModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: '1200px', // Set the desired width here
              height: '600px', // Set the desired height here (80% of the viewport height)
              maxWidth: 'none', // Ensures that maxWidth isn't limited by the `maxWidth` prop
            },
          }}
       
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
       
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setImageModal(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent >
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>

                    <img
                      src={currImage}
                      alt="remove"
               
                      style={{
                        width: '1200px',    // Ensure the image takes up the full width
                        height: '600px',   // Ensure the image takes up the full height

                      }}
                    />
 
                </Box>

              </Box>
            </DialogContentText>
          </DialogContent>

        </Dialog>
      </Box>
      <CodeDisplayModal/>
      {/* update scraper name modal */}
      <Box className="data preview">
        <Dialog
          open={usnOpen}
          onClose={() => setUsnOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"New Scraper Name :"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setUsnOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
              <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={2}
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={temp}
                    onChange={(event) => setTemp(event.target.value)}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",

                    margin: "10px 0px 0px 0px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Are you sure"
                        checked={csure}
                        onChange={() => {
                          setSure(!csure);
                        }}
                        size="10px"
                      />
                    }
                    label={
                      <span style={{ fontSize: "12px" }}>
                        Are you sure you want to update changes?
                      </span>
                    }
                  />
                  <Box>
                    <Button
                      onClick={() => {
                      
                        handleCloseUsn();
                        setRefresher(!refresher);
                      }}
                      autoFocus
                      className="approve_btn"
                      disabled={!csure}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
      <ToastContainer
        position="top-center"
        style={{ fontSize: "16px", width: "600px", height: "200px" }}
      />
      <Footer />
    </>
  );
};

export default Failed_Details_Page;
