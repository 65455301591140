import { Box } from '@mui/material'
import React from 'react'

export const LoadingBtn = () => {
    return (
        <>
            <Box className="al_left">
                <Box className="loadingbtn-mainbx">
                    <Box className="loadingbtn-container"  >
                        <Box className="dot" />
                    </Box>
                </Box>
            </Box>
        </>
    )
}