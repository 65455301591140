import React from 'react'
import useFilterStore from '../store/filterStateStore';
import {
    Box,
    Grid,
    IconButton,
    TextField,
    Autocomplete
  } from "@mui/material";
  
  import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
  import TaskAltIcon from '@mui/icons-material/TaskAlt';
 
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const FilterRow = ({progress, handleApplyFilter, handleClearFilter}) => {
    const {projectFilterList} = useFilterStore();
  const {creatorFilterList} = useFilterStore();
  const {startDate, setStartDate} = useFilterStore();
  const {toDate, setToDate} =useFilterStore();
  const {currentProjectFilter, setCurrentProjectFilter} =useFilterStore();
  const {currentCreatorFilter, setCurrentCreatorFilter} = useFilterStore();
  return (
    <>
 
        <Grid item lg={2} md={2} sm={6} xs={12}>

            <Box className="schedule ">
            <Autocomplete
                id="country-select-demo"
                options={projectFilterList}
                autoHighlight
                value={currentProjectFilter}
                onChange={(e,newVal)=> setCurrentProjectFilter(newVal)}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                <Box component="li" {...props}  >
                    <Box>{option.name} {`(${option.count})`}</Box>
                    {/* <Box sx={{marginLeft: '50px'}}>{option.count}</Box> */}
                </Box>
                )}
                
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select a Project"
                    inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
                )}
            />
            </Box>

        </Grid>
        <Grid item lg={2} md={1.5} sm={6} xs={12}>

            <Box className="schedule ">
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={currentCreatorFilter}
                options={creatorFilterList}
                fullWidth
                getOptionLabel={(option) => option.name}
                onChange={(e,n)=> setCurrentCreatorFilter(n)}
                renderOption={(props, option) => (
                <Box component="li" {...props}  >
                    <Box>{option.name} {`(${option.count})`}</Box>
                    {/* <Box sx={{marginLeft: '50px'}}>{option.count}</Box> */}
                </Box>
                )}
                renderInput={(params) => <TextField {...params} label={progress == "pending" ? `Created By`:`Scheduled By`} />}
            />
            </Box>

        </Grid>
        <Grid item lg={1.5} md={1.5} sm={6} xs={12}>
            <Box className="datepicker schedule">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                label="Start Date*"
                fullWidth
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(props) => <TextField {...props} style={{ height: '30px' }} />}
                />
            </LocalizationProvider>
            </Box>
        </Grid>
        <Grid item lg={1.5} md={1.5} sm={6} xs={12}>
            <Box className="datepicker  schedule ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                label="To Date*"
                fullWidth
                value={toDate}
                onChange={(date) => setToDate(date)}
                />
            </LocalizationProvider>

            </Box>
        </Grid>

        <Grid item lg={1} md={1.5} sm={6} xs={12}>
            <Box className="al_left">
            <IconButton
                autoFocus 
                className="col1"
                onClick={handleApplyFilter}
                title="Apply Filter"
            >
                <TaskAltIcon fontSize="small"/> 
            </IconButton>
            <IconButton
                autoFocus 
                className="col1"
                onClick={handleClearFilter}
                title="Clear Filter"
            >
                <FilterAltOffIcon fontSize="small"/> 
            </IconButton>
            </Box>
        </Grid> 
    </>
  )
}

export default FilterRow