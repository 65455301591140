 import React from 'react'
import { PythonProvider } from 'react-py'
import CodeBlock from './CodeBlock'



const scope = { React };


const PythonCompiler = () => {
    const packages = {
        official: ['asciitree'],
        micropip: ['beautifulsoap4'],
      }
  return (
    <PythonProvider>
    <CodeBlock/>
    </PythonProvider>

  )
}



export default PythonCompiler;
