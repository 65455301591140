import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import CodeIcon from '@mui/icons-material/Code';
import ImageIcon from '@mui/icons-material/Image';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "react-toastify/dist/ReactToastify.css";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Link, useHistory, useParams } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import LinkIcon from "@mui/icons-material/Link";
import EditButton from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import Visibility from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import remove from "../Assets/images/icon/denied 1.gif";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Dayjs from "dayjs";

import axios from "axios";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Schedule_Using_Date_Time_Frequency from "./Schedule_Using_Date_Time_Frequency";

import scheduling from "../Assets/images/icon/schedule_1.gif";
import resend from "../Assets/images/icon/resend.gif";
import Output_History_Table from "./Output_History_Table";
import { runScraperOnce } from "../Api/runScraperOnce";
import UpdateUrlModal from "../Components/UpdateUrlModal";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HeaderMappingBox from "../Components/HeaderMappingBox";
import apiInstance from "../apiInterceptor";
import { dataFormatter,formatFrequencyData,
  formatDateString, dataFormatterOutputHistory, dateFormatter, extractLogsFromUrl, fileName, generateCronExpression, handleApiError, isLatestObjectCompleted, jsonFilePath, transformString } from "../utils";
import { LoadingBtn } from "../Components/LoadingBtn";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Image } from "@mui/icons-material";
import PythonCompiler from "../Components/PythonCompiler";
import CodeDisplayModal from "../Components/CodeDisplayModal";
import useCodeEditorStore from "../store/codeEditorStore";
import { Editor } from "@monaco-editor/react";


const cronRegex =
  /^((\*|([0-5]?\d)(-([0-5]?\d))?)(\/([0-5]?\d)?)?)\s+((\*|([01]?[0-9]|2[0-3])(-([01]?[0-9]|2[0-3]))?)(\/([01]?[0-9]|2[0-3]))?)\s+((\*|([0-2]?[0-9]|3[0-1])(-([0-2]?[0-9]|3[0-1]))?)(\/([0-2]?[0-9]|3[0-1]))?)\s+((\*|([1-9]|1[0-2])(-([1-9]|1[0-2]))?)(\/([1-9]|1[0-2]))?)\s+((\*|[0-7]|([0-7](,[0-7])+))(\-([0-7]))?)$/;


const rowHeight = 24;
const headerHeight = 28;

const Frequency = [
  { label: "Select Frequency" },
  { label: "Hourly" },
  { label: "Daily" },
  { label: "Weekly" },
  { label: "Monthly" },
];

const FrequencyHourly = new Array(12).fill(null).map((_, i) => ({
  label: `Every ${i + 1} Hours`,
  hourInterval: i + 1,
}));


const dataFormatterScrape = (data) => {
  return data
    .map((d, index) => ({
      index: index,
      seq: d.command_seq_id,
      command: d.command,
      xpath: d.long_xpath,
      value: d.value,
      prompts: d.prompt_info,
      comments: d.step_comment,
    }))
    .filter((d) => d.command === "scrape");
};



const Pending_Details_Page = ({ version }) => {

  const session = JSON.parse(localStorage.getItem("session")) || null;
  const location = useLocation()
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("session"))) {
      localStorage.setItem("pre-url",location.pathname)
      history.push("/signin");
    }
  }, []);

  const { urlid } = useParams();
  const history = useHistory();
  const pushVersion = version == "v2" ? "" : "v1/";
  // dropdown option
  // radio button
  const [selectedOption, setSelectedOption] = useState("b");

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);

  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [openRunOnce, setOpenRunOnce] = useState(false);
  const [openUpdateUrl, setOpenUpdateUrl] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [usnOpen, setUsnOpen] = useState(false);


// state for output history
  const [outputHistoryData, setOutputHistory] = useState();

  const [currPrompt, setCurrPrompt] = useState("");
  const [currPromptAdd, setCurrPromptAdd] = useState("");

  const [activitylogs, setActivitylogs] = useState([]);
  const [openal, setOpenal] = React.useState(false);
 
  const handleClickOpenal = () => {
    if(activitylogs.length == 0){
      toast.info("No activity logs found")
      return
    }
      setOpenal(true);
  };
  const handleCloseal = () => {
      setOpenal(false);
  };

  const [csure, setSure] = useState(false);
  const [csure2, setSure2] = useState(false);
  const [checkVisible, setCheckVisible] = useState(true);

  const [update, setUpdate] = useState({});
  const [temp, setTemp] = useState("");

  const [details, setDetails] = useState([]);

  const [isDownloadable, setIsDownloadable] = useState(false);

  const [isDownloadingState, setDownloadingState] = useState(false)

  const checkDownload = (data) => {
    if (data.file_download_flag) {
      setIsDownloadable(true);
    }

  };

  const [fileData, setFileData] = useState({
    data: {},
    id: null,
  });

  const [expvalues] = useState({
    date: Dayjs(),
    time: Dayjs(),
    frequency: Frequency[0].label,
    hourInterval: FrequencyHourly[0].hourInterval,
    weeklyInterval: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
  });

  const {handleOpenEditor} = useCodeEditorStore()

  const [open9, setOpen9] = React.useState(false);

  const [dataKeys, setDataKeys] = useState([]);
  const [mappingOptions, setMappingOptions] = useState([]);
  const [keyValueMap, setKeyValueMap] = useState({});
  const [keyValueMapFinal, setKeyValueMapFinal] = useState({});
  const [isSinglePage, setIsSinglePage] = useState(false);

  const handleDataKeyChange = (key, value) => {
    setKeyValueMap((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleRemoveOption = (labelToRemove) => {
    setMappingOptions((prevOptions) =>
      prevOptions.filter((option) => option.label !== labelToRemove)
    );
  };

  const handleApplyMapping = async (data, value) => {
    if (value == "" || !value) {
      toast.info("Please enter a value first", {
        position: "bottom-right",
      });
      return;
    }
    const specialCharactersRegex = /[!@#\$%\^\&*\)\(+=._-]+/;
    if (specialCharactersRegex.test(value)) {
      toast.info("Special characters not allowed", {
        position: "bottom-right",
      });
      return;
    }
    if (
      !mappingOptions.some((option) => option.label === value.toUpperCase())
    ) {

      //add via api

      const endp = `${version}/save_header_details/`;
      try {
        const response = await apiInstance.post(endp, {
          project_id: details.project_code,
          header_name: value,
        });
        console.log(response.data)
        if(response.statusText ==="OK"){
        fetchMappingOptions();
        toast.success("Added sucessfully, select the option again");
        }
      } catch (e) {
        handleApiError(e)
        console.log(e);
      }
    } else {
      toast.info("Entered value already exists", {
        position: "bottom-right",
      });
    }

  };

  useEffect(() => {
    fetchMappingOptions();
    setIsSinglePage(
      details && details.scraper_type ? true : false
    );
    if (details && details.headers) {
      setKeyValueMap(details.headers);
      setKeyValueMapFinal(details.headers);
    }
  }, [details]);
  function hasDuplicateValues(obj) {
    const valueOccurrences = {};
    for (const key in obj) {
      const value = obj[key].toLowerCase();
      if (valueOccurrences[value]) {
        return true;
      } else {
        valueOccurrences[value] = true;
      }
    }

    return false;
  }
  const updateKeyValueMap = async () => {


    const updatedKeyValueMap = { ...keyValueMap };
    for (const key in updatedKeyValueMap) {
      if (
        updatedKeyValueMap.hasOwnProperty(key) &&
        updatedKeyValueMap[key] === ""
      ) {
        delete updatedKeyValueMap[key];
      }
    }
    if (hasDuplicateValues(updatedKeyValueMap)) {
      toast.error("Duplicates not allowed");
      return;
    }
    setKeyValueMap(updatedKeyValueMap);

    const endp = `${version}/update_headers/`;

    try {
      const response = await apiInstance.post(endp, {
        scraper_id: details.url_id,
        headers: updatedKeyValueMap,
      });

      if (response.statusText === "OK") {
        toast.success("Headers updated successfully");
        setOpen2(false);
        setKeyValueMapFinal(response.data.data.headers);
      }
    } catch (e) {
      handleApiError(e)
      console.log(e);
    }
  };

  const fetchMappingOptions = async () => {
    if (!details.project_code) return;

    const url = `${version}/get_headers/${details.project_code}`;
    try {
      const response = await apiInstance.get(url, null);

      if (response.statusText === "OK") {
        let tempArray = response.data.headers.map((obj) => {
          return { label: obj };
        }).sort((a, b) => a.label.localeCompare(b.label));
        setMappingOptions(tempArray);
        console.log(mappingOptions)
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [refresh, setRefresh] = useState(false);

  const [files, setFiles] = useState([]);

  const [schedule, enableScheduler] = useState(false);
  const [cronexp, setCronExp] = useState("");
  // row data
  const [rowData, setRowData] = useState();

  const [imageModal, setImageModal] = useState(false)
  const [currImage, setCurrImage] = useState("")


  useEffect(() => {
    let flagCheck = true;
    for (let i = 0; i < files.length; i++) {
      if (files[i].status != "approved") {
        flagCheck = false;
      }
    }

  }, [files]);
  // header row
  const [colDefs] = useState([
    { headerName: "Seq", field: "seq", minWidth: 60, flex: 1 },
    { headerName: "Command", field: "command", minWidth: 100, flex: 1 },

    { headerName: "XPath", field: "xpath", minWidth: 110, flex: 3 },
    {
      headerName: "Prompts",
      field: "prompts",
      minWidth: 180,
      flex: 2,
    },
    { headerName: "Comments", field: "comments", minWidth: 250, flex: 2 },
    // {
    //   field: "",
    //   value: "1",
    //   minWidth: 55,
    //   flex: 1,
    //   headerClass: "center-header",
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    //   cellRenderer: (params) => (
    //     <>
    //     {(params.data.command != "open" && params.data.image != "-") &&  <IconButton
    //       onClick={() =>{
    //        setCurrImage(params.data.image)
    //        setImageModal(true)
    //       }
    //       }
    //     >
    //       <Image className="comment_icon" fontSize="small" />
    //     </IconButton>}
    //     </>
       
    //   ),
    // },
  ]);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  /* modal box1 */



  const handleClickOpen2 = async (value) => {
    await fetchJSONAPI(value);
    value.status === "approved"
      ? setCheckVisible(false)
      : setCheckVisible(true);
    setOpen2(true);
  };

  const [currPromptIndex, setCurrPromptIndex] = useState(null);
  const handleClickOpenResend = async (value, index) => {
    console.log(index);
    setRefresh(!refresh);
    console.log(rowData,"data")
    setCurrPrompt(rowData[index - 1].prompts);
    setCurrPromptIndex(index - 1);
    await fetchJSONAPI(value);
    setOpen9(true);
  };

  function getValuesFromKeys(keysArray, obj) {
    return keysArray.map(key => obj[key]).filter(value => value !== undefined);
}

  const handlePromptUpdate = async () => {
    if (currPrompt == "") {
      toast.error("Prompt cannot be empty");
      return;
    }
    let message = "Prompt updated successfully";
    const endp = `${version}/update_prompt_comment_info`;
    // console.log(,"keys")
    // return
    const data = {
      url_id: urlid,
      step_id: `${currPromptIndex + 1}`,
      update_type: "prompt",
      prompt: currPrompt,
      existing_headers_keys: getValuesFromKeys(dataKeys, keyValueMapFinal),
    };
    if (currPromptAdd != "") {
      data.update_type = "additional_prompt";
      data.additional_prompt = currPromptAdd;
      message = "Additional prompt updated successfully";
      if (currPrompt != rowData[currPromptIndex].prompts) {
        data.update_type = "combined_additional_prompt";
        message = "Prompt & additional prompt updated successfully";
      }
    }
    try {
      const response = await apiInstance.patch(endp, data);
      if(response.statusText==="OK"){
      setCurrPromptAdd("");

      if (open9) {
        setOpen9(false);
        toast.success(`${message}`);
        setTimeout(() => {
          setRefresh(!refresh);

        }, 2000);
      } else {
        toast.success("Resent for preview");
      }
    }
    } catch (error) {
      setCurrPromptAdd("");
      console.error("Error sending PATCH request:", error);
      handleApiError(error)
    }
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose = () => {
    let updation = { ...update };
    updation["prompt"] = temp;
    updateAPI(updation);

    setOpen(false);
    setTemp("");
    setSure(false);
    setUpdate({});
  };

  /* modal box2 */



  const handleClickOpenRunOnce = () => {
    setOpenRunOnce(true);
  };

  const handleCloseRunOnce = () => {
    setOpenRunOnce(false);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleCloseUsn = () => {
    let updation = { ...update };
    updation["scrapper_name"] = temp;
    updateAPI(updation);

    setUsnOpen(false);
    setTemp("");
    setSure(false);
    setUpdate({});
  };





  const handleApprove = (id) => {
    approveAPI(id);

  };

  const handleClickOpenOutputHistory = async (value, details1, type,setIsDownloading) => {
    await fetchJSONOutputHistory(value, details1, type, setIsDownloading);
    if (type.includes("pre")) setOpenDownload(true);
  };
  const handleDownload = (data, fileName, type) => {
    if (version == "v2") {
      if (!data){
        setDownloadingState(false)
        return
      }
      const link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        setDownloadingState(false)
      }, 1000);
      toast.success("Download started successfully")
      document.body.removeChild(link);
      return;
    }

    const jsonData = JSON.stringify(data);
    let blob = new Blob([jsonData], { type: "application/json" });
    if (type == "logs") {
      blob = new Blob([data], { type: "text/plain" });
    }
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };




  const fetchJSONOutputHistory = async (value, details1, type,setIsDownloading) => {
    const url = `${version}/preview_json_data`;

    try {
      if (version == "v2") {
        const response = await apiInstance.post(url, {
          prj_id: `${details1.project_code}`,
          json_bucket_file_path: value.json?.replace(
            process.env.REACT_APP_BUCKET_URL,
            ""
          ),
          log_bucket_file_path: value.log?.replace(
            process.env.REACT_APP_BUCKET_URL,
            ""
          ),
        });
        let tempValue = value.json;
        if (type.includes("log")) {
          tempValue = value.log;
        }
        if(!tempValue){
          tempValue = "none"
        }
        if (response.statusText === "OK") {
          if(setIsDownloading) setIsDownloading(false)
          const parts = tempValue
            .replace(process.env.REACT_APP_BUCKET_URL, "")
            .split("/");
          const fileNameDownload = parts[parts.length - 1];
          if (type.includes("json")) {
            setFileData({
              data: response.data.preview,
              id: tempValue.id,
              type: type,
              fileName: fileNameDownload,
              presigned_url: response.data.json_presigned_url,
            });
          } else {
            const logContent = await extractLogsFromUrl(
              response.data.log_presigned_url
            );
            setFileData({
              data: logContent,
              id: tempValue.id,
              type: type,
              fileName: fileNameDownload,
              presigned_url: response.data.log_presigned_url,
            });
          }
          setCheckVisible(false);
          if (!type.includes("pre")) {
            const parts = tempValue
              .replace(process.env.REACT_APP_BUCKET_URL, "")
              .split("/");
            const fileNameDownload = parts[parts.length - 1];
            if (type == "logs") {
              handleDownload(
                response.data.log_presigned_url,
                fileNameDownload,
                type
              );
            } else {
              handleDownload(
                response.data.csv_presigned_url
                  ? response.data.csv_presigned_url
                  : response.data.json_presigned_url,
                fileNameDownload,
                type
              );
            }
          }
        }
      } else {
        let tempValue = value.json;
        if (type.includes("log")) {
          tempValue = value.log;
        }
        const response = await axios.get(tempValue);
        if (response.statusText === "OK") {
          const parts = tempValue
            .replace(process.env.REACT_APP_BUCKET_URL, "")
            .split("/");
          const fileNameDownload = parts[parts.length - 1];
          setFileData({
            data: response.data,
            id: tempValue.id,
            type: type,
            fileName: fileNameDownload,
          });
          if (!type.includes("pre")) {
            if (type == "logs") {
              handleDownload(response.data, fileNameDownload, type);
            } else {
              handleDownload(response.data.preview, fileNameDownload, type);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(()=>{
      if(outputHistoryData && outputHistoryData.some(data=> data.status == "Scheduled" || data.status == "In_progress")){
        setTimeout(()=>{
          urlidAPI("pending")
        },100000)
      }
  },[outputHistoryData])

  const urlidAPI = async (status) => {
    const endp = `${version}/get_url_data?url_id=${urlid}&status_type=${status}`;
    try {
      const response = await apiInstance.get(endp);
      if (response.statusText === "OK") {
        setOutputHistory(
          dataFormatterOutputHistory(
            response.data.schedular_history
              ? response.data.schedular_history
              : []
          )
        );
        setRowData(dataFormatter(response.data.xpath_master));
        setActivitylogs(response.data.activity_logs);
        response.data.transactions
          ? setFiles(jsonFilePath(response.data.transactions))
          : console.log("no transactions");
        setDetails(response.data);
        checkDownload(response.data);
      }
    } catch (e) {
      setRowData([]);
      console.log(e);
    }
  };

  useEffect(() => {
    console.log("refreshing due to refresh", refresh);
    urlidAPI("pending");
  }, [urlid, update, refresh]);

  function getAllUniqueKeys(obj, keys = new Set()) {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        for (const item of obj) {
          getAllUniqueKeys(item, keys);
        }
      } else {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            keys.add(key);
            getAllUniqueKeys(obj[key], keys);
          }
        }
      }
    }
    return Array.from(keys);
  }

  const fetchJSONAPI = async (value) => {
    const endp = `${version}/preview_json_data`;

    try {
      if (version == "v2") {
        const response = await apiInstance.post(endp, {
          prj_id: `${details.project_code}`,
          json_bucket_file_path: value.path,
        });

        if (response.statusText === "OK") {
          setDataKeys(getAllUniqueKeys(response.data.preview));
          setFileData({ data: response.data.preview, id: value.id });
        }
      } else {
        const response = await axios.get(
          process.env.REACT_APP_BUCKET_URL + value.path
        );
        if (response.statusText === "OK") {
          setFileData({ data: response.data, id: value.id });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const approveAPI = async (tid) => {
    const endp = `${version}/approve-transaction/?transaction_id=${tid}&approved_by=${session.employeeCode}`;
    setFiles((prev) =>
      prev.map((i) => ({
        path: i.path,
        id: i.id,
        status: i.status,
        loader: i.id === tid ? true : false,
      }))
    );
    try{
      const response = await apiInstance.post(endp, null);
      if (response.statusText === "OK") {
        setFiles((prev) =>
          prev.map((i) => ({
            path: i.path,
            id: i.id,
            status: i.id === tid ? "approved" : i.status,
            loader: false,
          }))
        );
        toast.success("Data approved");
        if (!isSinglePage) {
          setOpen2(false);
        }
        setCheckVisible(false);
        // setFiles([])
        urlidAPI("pending")
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000);
      }
    }catch(e){
      handleApiError(e)
    }
    
  };

  const removeAPI = async () => {
    const endp = `${version}/delete_scrapper`;
    const data = {
      url_id: urlid,
      emp_code: session.employeeCode,
      disable_flag: false,
    };
    try {
      const response = await apiInstance.patch(endp, data);
      if(response.statusText==="OK"){
      console.log("PATCH request successful:", response.data);
      toast.success("Scraper removed successfully");
      setTimeout(() => {
        history.push(`/${pushVersion}`);
      }, 2000);
    }
    } catch (error) {
      console.error("Error sending PATCH request:", error);
      handleApiError(e)
    }
  };

  const updateAPI = async (data) => {
    const message =
      data.update_type === "prompt"
        ? `Prompt updated successfully & resend for preview`
        : data.update_type === "comment"
        ? "Comment updated successfully"
        : "Scraper Name updated successfully";
    const endp = `${version}/update_prompt_comment_info`;

    try {
      const response = await apiInstance.patch(endp, data);
      if(response.statusText==="OK"){
      if (!open6) {
        toast.success(`${message}`);
        setTimeout(() => {
          setRefresh(!refresh);
        }, 2000);
      
      } else {
        toast.success("Resent for preview");
      }
    }
    } catch (error) {
      console.error("Error sending PATCH request:", error);
      handleApiError(e)
    }
  };

  const schedulerAPI = async (value) => {
    if (cronRegex.test(value)) {
      const urlcron = encodeURIComponent(value);

      const endp = `${version}/schedule-task/?url_id=${urlid}&chrone_expression=${urlcron}&schedule_by=${session.employeeCode}`;

      try {
        const response = await apiInstance.post(endp, null);
        if (response.statusText === "OK") {
          toast.success(`Succesfully scheduled scraper for ${urlid}`);
          setTimeout(() => {
  
            history.push(`/${pushVersion}`);
          }, 2000);
        }
      } catch (e) {
        console.log(e);
        handleApiError(e)
      }
    }
  };

  useEffect(() => {
    const allFilesApproved = files.every((file) => file.status === "approved");
    if (allFilesApproved) {
      enableScheduler(true);
    } else {
      enableScheduler(false);
    }
    if (
      files.some(
        (file) => file.status == "in_progress" || file.status == "pending"
      )
    ) {
      setTimeout(() => {
        urlidAPI("pending");
      }, 100000);
    }
  }, [files]);

  // header and row height
  const dataBoxWidth = (details.scraper_type != "SINGLE_PAGE" )  ? 2.4 : 3;

  return (
    <>
      <Header />
      <Box id="page-container">
        <Container maxWidth>
          <Grid container spacing={1} mb={1} justifyContent="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="fx_sb_mob">
                <Box>
                  <Typography variant="h4" className="col1 fw5 ">
                    Pending Scraper Details
                  </Typography>
                </Box>
                <Box className="fx_fs margin_10">
                  <Box mr={2}>
                    <Button
                      className="approve_btn"
                      onClick={() => {
   
                        history.push('/');
                        // window.close()
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box mr={2}>
                    <Button className="approve_btn" onClick={handleClickOpen3}>
                      Remove
                    </Button>
                  </Box>
                  <Box>
                    <Button className="approve_btn" onClick={handleClickOpenal}>
                      Activity Logs
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        window.open(details.website_url, "_blank");
                      }}
                    >
                      <LinkIcon fontSize="small" className="col2 card_icon4" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        {urlid}
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                        title={details.website_url ? details.website_url : "-"}
                      >
                        {details.website_url ? details.website_url : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        setTemp(
                          details.scraper_name ? details.scraper_name : "-"
                        );
                        setSure(false);
                        setUpdate({
                          url_id: urlid,
                          update_type: "scrapper_name",
                          scrapper_name: details.scraper_name
                            ? details.scraper_name
                            : "-",
                        });
                        setUsnOpen(true);
                      }}
                    >
                      <EditButton
                        fontSize="large"
                        className="col2 card_icon4"
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scraper Name{" "}
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {details.scraper_name ? details.scraper_name : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <DescriptionIcon
                        fontSize="large"
                        className="col2 card_icon4"
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Scraper Type
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {details.urls_data && details.urls_data.length>0 && details.scraper_type && details.scraper_type === "PAGINATION" ?
          transformString(details.scraper_type +
          " > " +
          details.pagination_type +
          " > " +
          details.data_source_type + " > " + "MULTI_URL")
           : details.scraper_type && details.scraper_type === "PAGINATION"
            ? transformString(details.scraper_type +
            " > " +
            details.pagination_type +
            " > " +
            details.data_source_type)
            : details.urls_data && details.urls_data.length>0 &&  details.scraper_type && details.scraper_type === "NO_PAGINATION"? transformString("NO_PAGINATION > MULTI_URL") :
             details.file_download_flag ? "File Download" : details.scraper_type ? transformString(details.scraper_type) : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
              <Link to="#">
                <Box className="cardbx fx_sb" p={1}>
                  <Box className="iconbx">
                    <IconButton disableRipple>
                      <TodayIcon fontSize="large" className="col2 card_icon4" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" col1 card_title one">
                        Created On
                      </Typography>
                      <Typography
                        variant="h5"
                        className=" gray card_subtitle one"
                      >
                        {details.added_on
                          ? dateFormatter(details.added_on)
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>
            {(details.scraper_type != "SINGLE_PAGE" )   && (
              <Grid item lg={dataBoxWidth} md={6} sm={6} xs={12}>
                <Link to="#">
                  <Box className="cardbx fx_sb" p={1}>
                    <Box
                      onClick={() => {(details.urls_data && details.urls_data.length > 0) ? setOpenUpdateUrl(true) : setOpenUpdateUrl(false)}}
                      className="iconbx"
                    >
                      <IconButton disableRipple>
                        <EditNoteIcon
                          fontSize="large"
                          className="col2 card_icon4"
                        />
                      </IconButton>
                    </Box>
                    <Box>
                      <Box className="al_right" m={1}>
                        <Typography
                          variant="h6"
                          className=" col1 card_title one"
                        >
                          Number of URLs
                        </Typography>
                        <Typography
                          variant="h5"
                          className=" gray card_subtitle one"
                        >
                          {(details.urls_data && details.urls_data.length > 0) ? details.urls_data.length : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Box className="whitebx" mt={1}>
                <Box className="fx_sb ai_end heading_bx">
                  <Box>
                    <Typography variant="h6" className=" col1 card_title ">
                      Scrapping Commands
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={"ag-theme-quartz  "}
                  style={{ width: "100%", height: "173px" }}
                >
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    filter={true}
                    enableCellTextSelection={true}
                  />
                </Box>
              </Box>
              <Box className="whitebx" mt={1}>
                <Output_History_Table
                  response_data={outputHistoryData}
                  on_preview={handleClickOpenOutputHistory}
                  on_download={handleClickOpenOutputHistory}
                  details={details}
                  isDownloadable={isDownloadable}
                  // response_data={[]}
                />
                {version == "v2" && (
                  <Box mt={2} className="al_right">
                    <Button
                      disabled={files.some((file) => file.status != "approved")}
                      onClick={() => handleClickOpenRunOnce()}
                      className="run_scraper_btn"
                    >
                      {" "}
                      Run Scraper{" "}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="whitebx" mt={1}>
                    <Box className="heading_bx">
                      <Typography variant="h6" className=" col1 card_title ">
                        {isSinglePage
                          ? "Step 1: Data Preview, Approval, and Key Renaming (if necessary)"
                          : "Step 1: Data Preview and Approval"}
                      </Typography>
                    </Box>

                    <Box className="preview_mainbx">
                      {files.map((file, index) =>
                        file.id ? (
                          <Box className="preview_bx al_center " p={1} key={index}>
                            <Box>
                       
                              <Typography
                                variant="h6"
                                className="one bl xstext  "
                              >
                                {file.status.startsWith("error")
                                  ? "Status: Failed"
                                  : "Status: " +
                                    file.status.charAt(0).toUpperCase() +
                                    file.status.slice(1).toLowerCase()}
                              </Typography>
                              <Typography
                                variant="h6"
                                className="card_preview_heading bl xstext"
                              >
                                {file.status === "approved" ||
                                file.status === "completed"
                                  ? fileName(file.path)
                                  : file.status.startsWith("error")
                                  ? "Change prompt and try again"
                                  : "Preview In Pending"}
                              </Typography>
                            </Box>
                            <Box mt={2}>
                              <Tooltip
                                title={
                                  file.path
                                    ? file.status === "approved"
                                      ? "Approved"
                                      : "Pending for Approve"
                                    : null
                                }
                                arrow
                                style={{
                                  display:
                                    file.status.startsWith("error") ||
                                    file.status == "pending"
                                      ? "none"
                                      : "inline-flex",
                                }}
                              >
                                {" "}
                                <IconButton
                                  style={{
                                    backgroundColor:
                                      file.status === "approved"
                                        ? "green"
                                        : "#1930ab",
                                  }}
                                  disabled={true}
                                >
                                  {file.loader ? (
                                    <Box className="loadingbtn-container3"  >
                                    <Box className="dot3" />
                                    </Box>
                                  ) : (
                                    <CheckIcon
                                      className="approve_icon"
                                      style={{ color: "white" }}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                style={{
                                  display:
                                    file.status.startsWith("error") ||
                                    file.status == "pending"
                                      ? "none"
                                      : "inline-flex",
                                }}
                                title="Preview"
                                arrow
                              >
                                <IconButton
                                  onClick={() => handleClickOpen2(file)}
                                  disabled={
                                    file.path
                                      ? file.status === "in_progress"
                                        ? true
                                        : false
                                      : true
                                  }
                                  className="eye_btn"
                                >
                                  <Visibility
                                    fontSize="medium"
                                    className="wh approve_icon "
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Resend for preview" arrow>
                              {file.status == "pending" ? 
                                  <Box ml={4} className="loadingbtn-container2"  >
                                    <Box className="dot2" />
                                    </Box>
                                    :
                                <IconButton
                                  disabled={
                                    file.status == "in_progress" ||
                                    file.status == "pending"
                                  }
                                  onClick={() => {
                                    handleClickOpenResend(
                                      file,
                                      parseInt(
                                        fileName(file.path ? file.path : file.html_path).split("_")[2]
                                      )
                                    );
                                  }}
                                  className="repeat_btn"
                                >
                                  {file.status == "in_progress" ||
                                  file.status == "pending" ? (
                                    <Box className="loadingbtn-container3"  >
                                    <Box className="dot3" />
                                    </Box>
                                  ) : (
                                    <RepeatIcon
                                      fontSize="medium"
                                      className="wh approve_icon "
                                    />
                                  )}
                                </IconButton>
}
                              </Tooltip> 
                              {localStorage.getItem("techLead") =="true" &&  
                              <Tooltip
                                style={{
                                  display:
                                     file.status !="approved"
                                      ? "none"
                                      : "inline-flex",
                                

                                }}
                                title="Code"
                                arrow
                              >
                                <IconButton
                                    onClick={() => {handleOpenEditor(file, details)}}
                                    disabled={
                                      file.path
                                        ? file.status != "approved"
                                          ? true
                                          : false
                                        : true
                                    }
                                  className="eye_btn"
                                >
                                  <CodeIcon
                                    fontSize="medium"
                                    className="wh approve_icon "
                                  
                                  />
                                </IconButton>
                              </Tooltip>
}
                            </Box>
                          </Box>
                        ) : null
                      )}
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="whitebx2 schedule">
                    <Box className="heading_bx fx_sb_mob">
                      <Box className="">
                        <Typography variant="h6" className=" col1 card_title ">
                          Step 3: Scheduling of Scraper
                        </Typography>
                      </Box>

                      <Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={selectedOption}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value="b"
                              control={<Radio />}
                              label="Using Date, Time & Frequency"
                            />
                            <FormControlLabel
                              value="a"
                              control={<Radio />}
                              label="Using Cron Expression"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>

                    {
                      selectedOption === "a" ? (
                        <Box className="cron_expression" my={2}>
                          <Grid container justifyContent="center" spacing={1}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <FormControl fullWidth className="input_field">
                                <FilledInput
                                  className="textfield_cron"
                                  id="filled-adornment-password"
                                  placeholder="Enter Cron Expression"
                                  onChange={(event) => {
                                    setCronExp(event.target.value);
                                  }}
                                />
                              </FormControl>
                              <Box mt={1} className="al_left">
                                <Typography variant="h6" className="gray desc">
                                  {" "}
                                  Note: A cron expression is a string
                                  representing a schedule in a time-based job
                                  scheduling system (like Unix cron). It is
                                  typically made up of five or six fields,
                                  separated by space, that represent different
                                  units of time.
                                </Typography>

                                <Typography
                                  variant="h6"
                                  className="xstext"
                                  mt={1}
                                >
                                  {" "}
                                  Example: A cron expression 0 12 * * Mon means
                                  the task will run at 12:00 PM every Monday.
                                  Learn more{" "}
                                  <a
                                    href="https://crontab.guru/"
                                    className="a_underline"
                                    target="_blank"
                                  >
                                    click here
                                  </a>
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box className="fx_c" mt={1}>
                            <Button
                              className="approve_btn"
                              disabled={!schedule || !cronRegex.test(cronexp)}
                              onClick={() => {
                                setOpen4(true);
                              }}
                            >
                              Schedule
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Schedule_Using_Date_Time_Frequency
                          urlid={urlid}
                          schedule_flag={schedule}
                          emp_code={session ? session.employeeCode : null}
                          reschedule_flag={false}
                          version={version}

                          pageType="pending"
                          allowed={
                            files.some((file) => file.status == "approved") &&
                            isLatestObjectCompleted(outputHistoryData)
                          }
                        />
                      )

                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* modal box1 */}

      <Box className="comment">
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" className=" col1 card_title">
            {update.update_type === "prompt" ? "Prompts :" : "Comments :"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    name="message"
                    placeholder="Prompts information"
                    fullWidth
                    value={temp}
                    onChange={(event) => setTemp(event.target.value)}
                  />
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "30px",
              margin: "10px 22px 10px 22px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="Are you sure"
                  checked={csure}
                  onChange={() => {
                    setSure(!csure);
                  }}
                  size="10px"
                />
              }
              label={
                <span style={{ fontSize: "12px" }}>
                  Are you sure you want to update?
                </span>
              }
            />
            <Box>
              <Button
                onClick={() => {
                  handleClose();
                }}
                autoFocus
                className="approve_btn"
                disabled={!csure || temp === ""}
              >
                Update & Resend
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      {/* modal box download */}

      <Box className="data preview">
        <Dialog
          open={openDownload}
          onClose={() => setOpenDownload(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Data Preview"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenDownload(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
           
                <Editor
                        height="400px"
                        defaultLanguage="text"
                        value={
                          fileData.type && fileData.type.includes("logs")
                            ? fileData.data
                            : JSON.stringify(fileData.data, null, 2)
                        }
                        options={{minimap: {
                          enabled: false,
                          
                      },
                      wordWrap: 'on'}}
                      />
                  {/* <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={2}
                    maxRows={8}
                    name="message"
                    fullWidth
                    value={
                      fileData.type && fileData.type.includes("logs")
                        ? fileData.data
                        : JSON.stringify(fileData.data, null, 2)
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                  {fileData.presigned_url != undefined && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",

                        margin: "10px 0px 0px 0px",
                      }}
                    >
                      <Box>
                      {isDownloadingState ? <LoadingBtn/> :
                        <Button
                          autoFocus
                          className="approve_btn"
                          onClick={() => {
                            setDownloadingState(true)
                            handleDownload(
                              fileData.presigned_url,
                              fileData.fileName,
                              fileData.type
                            );
                          }}
                        >
                          Download
                        </Button>
}
                      </Box>
                    </Box>
                  )}
                </Box>

                {checkVisible ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "30px",
                      marginTop: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Are you sure"
                          checked={csure}
                          onChange={() => {
                            setSure(!csure);
                          }}
                          size="10px"
                        />
                      }
                      label={
                        <span style={{ fontSize: "12px" }}>
                          Are you sure you want to approve?
                        </span>
                      }
                    />
                    <Box>
                      <Button
                        onClick={() => handleApprove(fileData.id)}
                        autoFocus
                        disabled={!csure}
                        className="approve_btn"
                      >
                        Approve
                      </Button>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
      {/* modal box2 */}

      <Box className="data preview">
        <Dialog
          open={open2}
          onClose={() => {
            setOpen2(false);
            setKeyValueMap(keyValueMapFinal);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={
            details && details.scraper_type && !isDownloadable ? "md" : "xs"
          }
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Data Preview"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen2(false);
              setKeyValueMap(keyValueMapFinal);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <Box my={1} className="data_preview">
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  container
                  spacing={2}
                  alignItems="top"
                  justifyContent="center"
                >
                  <Grid
                    item
                    lg={isSinglePage && !isDownloadable  ? 6 : 12}
                    md={isSinglePage  && !isDownloadable  ? 6 : 12}
                    sm={isSinglePage  && !isDownloadable  ? 6 : 12}
                    xs={12}
                  >
                    <Box className=" text_area ">
                      <Box className="input_field datapreview_bx">
                        <TextField
                          id="filled-basic"
                          className="filleddp padding0"
                          variant="filled"
                          multiline
                          minRows={7}
                          maxRows={7}
                          name="message"
                          fullWidth
                          value={JSON.stringify(fileData.data, null, 2)}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>

                      {checkVisible ? (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "30px",
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="Are you sure"
                                checked={csure}
                                onChange={() => {
                                  setSure(!csure);
                                }}
                                size="10px"
                              />
                            }
                            label={
                              <span style={{ fontSize: "12px" }}>
                                Are you sure you want to approve?
                              </span>
                            }
                          />
                          <Box>
                            <Button
                              onClick={() => handleApprove(fileData.id)}
                              autoFocus
                              disabled={!csure}
                              className="approve_btn"
                            >
                              Approve
                            </Button>
                          </Box>
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                  {(details && details.scraper_type  && !isDownloadable) && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className="datapreview_bx schedule">
                        {dataKeys.map((data, index) => {
                          const filteredOptions = mappingOptions.filter(
                            (option, index, self) => {
                              const isOptionIncluded = Object.values(keyValueMap).includes(option.label);
                              const isUniqueOption = index === self.findIndex((o) => o.label === option.label);
                              const isSpecialCase = option.label.startsWith(option.label[0]);
                              return (!isOptionIncluded || isSpecialCase) && isUniqueOption;
                            }
                          );
                          return (
                            <HeaderMappingBox key={index}
                              data={data}
                              filteredOptions={filteredOptions}
                              keyValueMap={keyValueMap}
                              handleApplyMapping={handleApplyMapping}
                              handleDataKeyChange={handleDataKeyChange}
                              keyValueMapFinal={keyValueMapFinal}
                              handleRemoveOption={handleRemoveOption}
                              mappingOptions={mappingOptions}
                            />
                          );
                        })}
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Are you sure"
                              checked={csure2}
                              onChange={() => {
                                setSure2(!csure2);
                              }}
                              size="10px"
                            />
                          }
                          label={
                            <span style={{ fontSize: "12px" }}>
                              Are you sure you want to rename keys?
                            </span>
                          }
                        />
                        <Box>
                          <Button
                            onClick={() => updateKeyValueMap()}
                            autoFocus
                            disabled={!csure2}
                            className="approve_btn"
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
                  <CodeDisplayModal/>
      {/* activity log */}

      <React.Fragment>
                <Dialog
                    onClose={handleCloseal}
                    aria-labelledby="customized-dialog-title"
                    open={openal} 
                    maxWidth="lg"
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
                        <Typography variant='h4' className='col1 fw5' gutterBottom>Activity</Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                      
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    <DialogContent dividers className="history_drawer" my={2}>
                   <Box my={2}>
                    
                   <table>
                        <tbody>
                          {activitylogs.map(activity =>{
                            return (<tr className='ai_top mb2'>
                              <td className='ai_top  '>
                                  <IconButton className='padding0'><FiberManualRecordIcon className='xtext_icon' /></IconButton> <Typography variant='h6' className='bl xtext lg2 fw4'>
                                      { <span>
                                        {(activity.action_by_emp_id == session.employeeCode ? "You" : activity.action_by)} {/* Underline action_by */}
                                        {" "}
                                        <strong>{activity.action_type=="RENAME_COLUMN" ?
                                        <>
                                         {"updated output preview keys"} 
                                         <br/>
                                         <br/>
                                        <span style={{marginTop:'3px'}}>{`Updated headers:  ${JSON.stringify(activity.action_changes ? activity.action_changes.new_changes : '-')}`}</span>
                                       </>
                                         : activity.action_msg}</strong> {/* Bold action_msg */}
                                        {"."}
                                        {activity.action_changes && activity.action_changes.new_changes ? (
                                          " " +
                                          (activity.action_type === 'RE-SCHEDULE' || activity.action_type === 'SCHEDULE'
                                            ? typeof activity.action_changes.new_changes === 'string'
                                              ? cronToText(activity.action_changes.new_changes) + "." + getJobId(activity)
                                              : formatFrequencyData(activity.action_changes.new_changes) + getJobId(activity)
                                            : activity.action_type === 'RENAME_COLUMN'
                                            ? ""
                                            : activity.action_type === 'CODE_CHANGES'
                                            ? ""
                                            : "New " + activity.action_msg + ": " + activity.action_changes.new_changes + ".")
                                        ) : ""}
                                      </span>
                                      }</Typography>
                              </td>
                              <td><Typography variant='h6' ml={5} className='bl xtext lg fw4'>{activity.action_on.includes('T') ? dateFormatter(activity.action_on): formatDateString(activity.action_on)}</Typography></td>
                          </tr>)
                          })}
                            
                            

                        </tbody>
                    </table>
                   </Box>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button className='sendbtn wh' onClick={handleCloseal}>
                            Save changes
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </React.Fragment>
      {/* update scraper name modal */}
      <Box className="data preview">
        <Dialog
          open={usnOpen}
          onClose={() => setUsnOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"New Scraper Name :"}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setUsnOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={2}
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={temp}
                    onChange={(event) => setTemp(event.target.value)}
                  />
                  

                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "20px",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Are you sure"
                        checked={csure}
                        onChange={() => {
                          setSure(!csure);
                        }}
                        size="10px"
                      />
                    }
                    label={
                      <span style={{ fontSize: "12px" }}>
                        Are you sure you want to update?
                      </span>
                    }
                  />
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleCloseUsn();
                        }}
                        autoFocus
                        className="approve_btn"
                        disabled={!csure || temp === ""}
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>

      {/* remove alert dialog */}

      <Box className="data preview">
        <Dialog
          open={open3}
          onClose={() => {
            setOpen3(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={remove}
                      alt="remove"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to remove?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  removeAPI();
                  setOpen3(false);
                }}
                autoFocus
                className="approve_btn"
              >
                Ok
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  setOpen3(false);
                }}
                autoFocus
                className="cancel_btn"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      <Box className="data preview">
        <Dialog
          open={imageModal}
          onClose={() => {
            setImageModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: '1200px', // Set the desired width here
              height: '600px', // Set the desired height here (80% of the viewport height)
              maxWidth: 'none', // Ensures that maxWidth isn't limited by the `maxWidth` prop
            },
          }}
       
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
       
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setImageModal(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent >
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>

                    <img
                      src={currImage}
                      alt="remove"
               
                      style={{
                        width: '1200px',    // Ensure the image takes up the full width
                        height: '600px',   // Ensure the image takes up the full height

                      }}
                    />
 
                </Box>

              </Box>
            </DialogContentText>
          </DialogContent>

        </Dialog>
      </Box>
      {(details.scraper_type != "SINGLE_PAGE" )  && (
        <UpdateUrlModal
          open={openUpdateUrl}
          setOpen={setOpenUpdateUrl}
          details={details}
          version={version}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}

      {/* Schedule cron exp */}
      <Box className="data preview">
        <Dialog
          open={open4}
          onClose={handleClose4}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to schedule?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  schedulerAPI(cronexp);
                }}
                autoFocus
                className="approve_btn"
              >
                Schedule
              </Button>
            </Box>
            <Box>
              <Button onClick={handleClose4} autoFocus className="cancel_btn">
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      {/* Schedule Modal Date Time Frequency*/}

      <Box className="data preview">
        <Dialog
          open={open5}
          onClose={handleClose5}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to schedule?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  schedulerAPI(generateCronExpression(expvalues));
                  setOpen5(false);
                }}
                autoFocus
                className="approve_btn"
              >
                Schedule
              </Button>
            </Box>
            <Box>
              <Button onClick={handleClose5} autoFocus className="cancel_btn">
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      {/* Run Once Modal */}
      <Box className="data preview">
        <Dialog
          open={openRunOnce}
          onClose={handleCloseRunOnce}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Re-Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to run scraper once ?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={async () => {
                  await runScraperOnce(urlid, session.employeeCode, version);
                  setOpenRunOnce(false)
                  urlidAPI("pending");
                }}
                autoFocus
                className="approve_btn"
              >
                Run Once
              </Button>
            </Box>
            <Box>
              <Button
                onClick={handleCloseRunOnce}
                autoFocus
                className="cancel_btn"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>

      {/* Resend For Preview */}
      <Box className="data preview">
        <Dialog
          open={open6}
          onClose={() => setOpen6(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={resend}
                      alt="Resend"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to resend for preview ?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  updateAPI(update);
                  setOpen6(false);
                  setRefresh(!refresh);
                  pressCtrlR();
                }}
                autoFocus
                className="approve_btn"
              >
                Resend
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => setOpen6(false)}
                autoFocus
                className="cancel_btn"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      {/* Resend For Preview */}
      <Box className="data preview">
        <Dialog
          open={open9}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
            {"Data Preview"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen9(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={4}
                    maxRows={4}
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={JSON.stringify(fileData.data, null, 2)}
                  />
                </Box>
                <Box my={1}>
                  <Typography variant="h6" className=" col1 card_title ">
                    Existing Prompt <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Box>
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={currPrompt =="Downloadable Links" || currPrompt =="LIST_ITEM" || currPrompt =="DATA_SOURCE" || currPrompt=="PAGINATION_SOURCE" || currPrompt =="Hyperlinks"}
                    value={currPrompt}
                    onChange={(event) => setCurrPrompt(event.target.value)}
                  />
                </Box>
                <Box my={1}>
                  <Typography variant="h6" className=" col1 card_title ">
                    Additional Prompt (Optional)
                  </Typography>
                </Box>
                <Box className="input_field">
                  <TextField
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    minRows={1}
                    name="message"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={currPromptAdd}
                    onChange={(event) => setCurrPromptAdd(event.target.value)}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "20px",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Are you sure"
                        checked={csure}
                        onChange={() => {
                          setSure(!csure);
                        }}
                        size="10px"
                      />
                    }
                    label={
                      <span style={{ fontSize: "12px" }}>
                        Are you sure you want to update?
                      </span>
                    }
                  />
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handlePromptUpdate();
                        }}
                        autoFocus
                        className="approve_btn"
                        disabled={!csure}
                      >
                        Update & Resend
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
      <ToastContainer
        position="top-center"
        style={{ fontSize: "16px", width: "400px", height: "200px" }}
      />
      <Footer />
    </>
  );
};

export default Pending_Details_Page;

function pressCtrlR() {
  window.location.reload();
}
