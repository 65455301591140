import {create} from 'zustand';
import apiInstance from '../apiInterceptor';
import { fetchPythonFileContents, handleApiError, updatePythonCode } from '../utils';
import { toast } from 'react-toastify';
import beautify from 'js-beautify';

const useCodeEditorStore = create((set,get) => ({
    fileData:{},
    setFileData:(newVal)=>set({fileData:newVal}),
    htmlCode:{},
    setHtmlCode:(newVal)=>set({htmlCode:newVal}),
    initialCode:"",
    initialHtml:"",
    isTechLead: false,
    setIsTechLead:(newVal)=>set({isTechLead:newVal}),
    detailData:{},
    setDetailData:(newVal)=>set({detailData:newVal}),
    code:'',
    setCode:(newVal)=>set({code:newVal}),
    openCodeEditor:false,
    setOpenCodeEditor:(newVal)=>set({openCodeEditor:newVal}),
    handleOpenEditor:async (file, details)=>{
        set({fileData:file})
        set({detailData:details})
        try{
            const body = {
                prj_id:`${details.project_code}`,
                log_bucket_file_path:file.python_path
            }
            const response = await apiInstance.post('/v2/preview_json_data',body)
            if(response.statusText == "OK"){
                try{
                    const responseNew = await apiInstance.post('/v2/preview_json_data',{
                        prj_id:`${details.project_code}`,
                        log_bucket_file_path:file.html_path
                    })
                    const html_code = await fetchPythonFileContents(responseNew.data.log_presigned_url)
                    const python_code = await fetchPythonFileContents(response.data.log_presigned_url)
                    console.log({code:python_code})
                    set({htmlCode:beautify.html(html_code, {
                        indent_size: 2,  // Set indentation size
                        preserve_newlines: true,  // Preserve existing line breaks
                        end_with_newline: true,
                      })})

                      set({initialHtml:beautify.html(html_code, {
                        indent_size: 2,  // Set indentation size
                        preserve_newlines: true,  // Preserve existing line breaks
                        end_with_newline: true,
                      })})
                    
                    set({code:python_code})
                      set({initialCode:python_code})
                }catch(error){

                }
            }
        }catch(error){
            handleApiError(error)

        }
        set({openCodeEditor:true})
    },

    handleSaveCode:async (code,emp_code)=>{
        const {fileData, detailData} = get()
        const body  = {
            scraper_id:detailData.url_id,
            code_file_path: fileData.python_path,
            code_content: code,
            project_id: detailData.project_code,
            emp_id:parseInt(emp_code)

        }
        console.log("body",body)
        try{
            const response = await apiInstance.post('/v2/save_code_file',body)
            if(response.statusText == "OK"){
                toast.success("Code updated successfully")
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
        }catch(error){
            handleApiError(error)
        }
    },

    handleSaveCodeHtml:async (code,emp_code)=>{
        const {fileData, detailData} = get()
        const body  = {
            code_file_path: fileData.html_path,
            code_content: code,
            project_id: detailData.project_code,
            emp_id:parseInt(emp_code)

        }
        console.log("body",body)
        try{
            const response = await apiInstance.post('/v2/save_code_file',body)
            if(response.statusText == "OK"){
                // toast.success("Code updated successfully")
                // setTimeout(() => {
                //     window.location.reload()
                // }, 2000);
            }
        }catch(error){
            handleApiError(error)
        }
    }

}));

export default useCodeEditorStore;
