import {
  Box,
  Button,
  IconButton,

  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import Dialog from "@mui/material/Dialog";;
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import apiInstance from "../apiInterceptor";
import DownloadAccordian from "../Components/DownloadAccordian";
import {  toast } from "react-toastify";
import DownloadLoaderCell from "../Components/DownloadLoaderCell";
import PreviewLoaderCell from "../Components/PreviewLoaderCell";
import LogLoaderCell from "../Components/LogLoaderCell";
const Output_History_Table = ({
  response_data,
  on_preview,
  on_download,
  details,
  isDownloadable,
}) => {
  const isApproved =true ;
  useEffect(() => {
    setColDefs([
      {
        headerName: "Last Schedule Time",
        field: "scheduletime",
        minWidth: 150,
        flex: 1,
      },
      {
        headerName: "Completed On",
        field: "completed_on",
        minWidth: 130,
        flex: 1,
      },
      { headerName: "Status", field: "status", minWidth: 130, flex: 1 },
      {
        field: "Csv",
        value: "1",
        minWidth: 18,
        flex: 1,
        headerClass: "center-header",
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        cellRenderer: (params) => (
         <DownloadLoaderCell params={params} on_download={on_download} details={details}/>
        ),
      },
      {
        field: "Json",
        value: "1",
        minWidth: 15,
        flex: 1,
        headerClass: "center-header",
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        cellRenderer: (params) => (
          <PreviewLoaderCell params={params} details={details} on_preview={on_preview}/>
        ),
      },

      ...(isApproved
        ? [
            {
              field: "Logs",
              value: "1",
              minWidth: 20,
              flex: 1,
              headerClass: "center-header",
              cellStyle: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              cellRenderer: (params) => (
               <LogLoaderCell params={params} details={details} on_preview={on_preview}/>
              ),
            },
          ]
        : []),
      {
        headerName: "Duration",
        field: "duration",
        minWidth: 60,
        flex: 1,
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
        },
        cellRenderer: (params) => (
          <>
            {params.data.status == "Scheduled" || params.data.status=="In_progress" ? (
                    <Box className="loadingbtn-container2"  >
                        <Box className="dot2" />
                    </Box>
            ) : (
              params.data.duration
            )}
          </>
        ),
      },
      {
        headerName: "Version",
        field: "scraper_version",
        minWidth: 40,
        flex: 1,
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
      
    ]);
  }, [details]);



  const [colDefs, setColDefs] = useState([
    {
      headerName: "Last Schedule Time",
      field: "scheduletime",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Completed On",
      field: "completed_on",
      minWidth: 130,
      flex: 1,
    },
    { headerName: "Status", field: "status", minWidth: 130, flex: 1 },
    {
      field: "Csv",
      value: "1",
      minWidth: 18,
      flex: 1,
      headerClass: "center-header",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      cellRenderer: (params) => (
        <DownloadLoaderCell params={params} on_download={on_download} details={details}/>
      ),
    },
    {
      field: "preview",
      value: "1",
      minWidth: 15,
      flex: 1,
      headerClass: "center-header",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      cellRenderer: (params) => (
        <PreviewLoaderCell params={params} details={details} on_preview={on_preview}/>
      ),
    },

    ...(isApproved
      ? [
          {
            field: "Logs",
            value: "1",
            minWidth: 20,
            flex: 1,
            headerClass: "center-header",
            cellStyle: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            cellRenderer: (params) => (
              <LogLoaderCell params={params} details={details} on_preview={on_preview}/>
            ),
          },
        ]
      : []),
    {
      headerName: "Duration",
      field: "Duration",
      minWidth: 60,
      flex: 1,
      cellStyle: {
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
      },
    },
  ]);

  // Header and row height
  const rowHeight = 24;
  const headerHeight = 28;

  // =============open popup====================

  const [open, setOpen] = React.useState(false);
  const [downloadItems, setDownloadItems] = useState([]);


  const getDownloadHistory = async () => {
    const endp = `v2/view_download_history`;
    try {
      const response = await apiInstance.post(endp, {
        url_id: details.url_id,
        bucket_name: "AIWS-dpaai-project-scraper",
        group_name: details.group_name,
      });
      if (response.statusText ==="OK") {
        setDownloadItems(response.data.download_history);
        console.log(downloadItems);

        setOpen(true);
      } else {
        toast.info("No downloads found for scraper");
        console.log("No download data found");
      }

    } catch (e) {
      console.log(e);
    }
  };

  const handleClickOpen = () => {
    getDownloadHistory();
  };

  return (
    <>
      <Box className="fx_sb ai_end heading_bx">
        <Box>
          <Typography variant="h6" className=" col1 card_title ">
            {isApproved
              ? "Step 2: Verification of Scraper and View Output History"
              : "Output History"}
          </Typography>
        </Box>

        <Box>
          {
            isDownloadable &&  <Button className="approve_btn" onClick={() => handleClickOpen()}>
            {" "}
            Download History{" "}
          </Button>
          }
          
        </Box>
      </Box>
      <Box
        className={"ag-theme-quartz  "}
        style={{ width: "100%", height: "142px" }}
      >
        <AgGridReact
          rowData={response_data}
          columnDefs={colDefs}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          filter={true}
          enableCellTextSelection={true}
        />
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        className="output-history-popup"
      >
        <DialogTitle id="alert-dialog-title2" className=" col1 card_title ">

        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 2,
            marginBottom: "20px ",
          }}
        >
          <HighlightOffIcon fontSize="small" className="gray" />
        </IconButton>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Object.entries(downloadItems).map(([key, value]) => {
              if (key != "zip_files") {
                return (
                  <DownloadAccordian
                    keyName={key}
                    value={value}
                    details={details}
                  />
                );
              }
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Output_History_Table;
