import React, {useState} from 'react'
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
    Box,
    IconButton,
  } from "@mui/material";

const LogLoaderCell = ({params, details, on_preview}) => {
    const [isDownloading, setIsDownloading] = useState(false)
  return (
    <>
    {(params.data.status == "Failed" || (params.data.status =="Completed" && params.data.preview_log)) &&
    details?.project_code != undefined && !isDownloading ? (
      
      <IconButton
        onClick={() =>{
            setIsDownloading(true)
          on_preview(
            {
              json: params.data.preview,
              log: params.data.preview_log,
              csv: params.data.preview_csv,
            },
            details,
            "pre_logs",
            setIsDownloading
          )
        }
        }
      >
        <RemoveRedEyeIcon
          className="comment_icon"
          fontSize="small"
        />
      </IconButton>
    ) : isDownloading ? (<Box className="loadingbtn-container2"  >
        <Box className="dot2" />
        </Box>): (
      "-"
    )}
  </>
  )
}

export default LogLoaderCell