// App.js
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "../src/Assets/CSS/Style.css";
import "../src/App.css";
import "../src/Assets/CSS/Ag_Grid.css";
import "../src/Assets/CSS/Mui.css";
import "../src/Assets/CSS/Media_Queries.css";

import Home from "./Pages/Home";
import Signin from "./Pages/Signin";
import NotFound from "./Pages/NotFound";
import Approved_Details_Page from "./Pages/ApprovedDetailsPage";
import Pending_Details_Page from "./Pages/PendingDetailsPage.js";
import Failed_Details_Page from "./Pages/FailedDetailsPage.js";
import { useState } from "react";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/v1">
            <Home version="v1" />
          </Route>
          
          <Route exact path="/signin">
            <Signin />
          </Route>
          <Route path="/v1/pending-scraper-details/:urlid">
            <Pending_Details_Page version="v1" />
          </Route>
          <Route path="/v1/approved-scraper-details/:urlid">
            <Approved_Details_Page version="v1" detailType={"approved"} />
          </Route>
          <Route path="/v1/failed-scraper-details/:urlid">
          <Failed_Details_Page version="v1" detailType={"failed"} />
          </Route>
          <Route  path="/v1/disabled-scraper-details/:urlid">
            <Failed_Details_Page version="v1" detailType={"disabled"}/>
          </Route>
          

          {/* V2 Routes */}
          <Route exact path="/">
            <Home version="v2" />
          </Route>
          <Route path="/pending-scraper-details/:urlid">
            <Pending_Details_Page version="v2" />
          </Route>
          <Route path="/approved-scraper-details/:urlid">
            <Approved_Details_Page version="v2" detailType={"approved"}/>
          </Route>
          <Route path="/failed-scraper-details/:urlid">
          <Failed_Details_Page version="v2"  detailType={"failed"} />
          </Route>
          <Route path="/disabled-scraper-details/:urlid">
            <Failed_Details_Page version="v2" detailType={"disabled"} />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
