import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState,  useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingBtn } from "./LoadingBtn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import apiInstance from "../apiInterceptor";
import CustomHeader from "./CustomHeader";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleApiError } from "../utils";
const rowHeight = 24;
const headerHeight = 28;

const handleDownload = (data, fileName, setLoading) => {
  if (!data) return;
  console.log("link", data);
  const link = document.createElement("a");
  link.href = data;
  document.body.appendChild(link);
  link.click();
  toast.success("Download started successfully")
  document.body.removeChild(link);
  setLoading(false);
  return;
};

const DownloadAccordian = ({ keyName, value, details }) => {
  const columns = [
    {
      headerName: "",
      headerComponent: CustomHeader,
      headerClass: "center-header",
      checkboxSelection: true, // Checkbox for each row
      maxWidth: 60,
      flex: 1,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    {
      headerName: "File Name",
      field: "name",
      minWidth: 610,
      flex: 1,
    },
    {
      headerName: "Download",
      cellRenderer: (params) => (
        <IconButton
          onClick={() => DownloadFile(params.data.download)}
          disableRipple
        >
          {" "}
          <DownloadIcon
            className="downloadpage_download_icon"
            fontSize="small"
          />
        </IconButton>
      ),
      width: 20,
      flex: 1,
    },
  ];

  // Sample row data
  const rowData = value.map((item) => {
    return {
      name: item.split("/")[item.split("/").length - 1],
      download: item,
    };
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);


  const DownloadFile = async (file) => {
    console.log("here", file);
    const endp = `v2/preview_json_data`;
    setLoading(true);

    try {
      const response = await apiInstance.post(endp, {
        prj_id: `${details.project_code}`,
        log_bucket_file_path: file,
      });

      if (response.statusText === "OK") {
        handleDownload(
          response.data.log_presigned_url,
          file.split("/")[file.split("/").length - 1],
          setLoading
        );
      }
    } catch (e) {
      handleApiError(e)
      console.log(e);
      setLoading(false);
    }
  };
  const handleBulkDownload = async () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const newSelectedItems = selectedData.map((row) => row.download);
    console.log(newSelectedItems);
    if(newSelectedItems.length ==0){
      toast.info("Please select a row first")
      return
    }
    setLoading(true);

    const endp = `v2/bulk_csv_download`;
    try {
      const response = await apiInstance.post(endp, {
        prj_id: `${details.project_code}`,
        csv_add: newSelectedItems,
      });

      if (response.statusText === "OK") {
        handleDownload(response.data.presigned_url, null, setLoading);
       
      }
    } catch (e) {
      handleApiError(e)
      console.log(e);
      setLoading(false);
    }
  };


  const [isWhiteBoxVisible, setWhiteBoxVisible] = useState(false);

  const toggleWhiteBox = () => {
    setWhiteBoxVisible(!isWhiteBoxVisible);
  };

  return (
    <Box key={keyName} className="whitebx bxsh" m={2} mt={3}>
      <Box className="fx_sb">
        <Box onClick={toggleWhiteBox} className="ptr fx1">
          <Typography variant="h5" className="col1 fw5">
            <IconButton disableRipple>
              {isWhiteBoxVisible ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
            {keyName}
          </Typography>
        </Box>
        <Box className="fx_fs">
          <Box mr={2}>
            {loading ? <LoadingBtn/> :
            <Button
              className="approve_btn"
              onClick={() => handleBulkDownload()}
              disabled={loading}
            >
              {"Download"}
            </Button>
}
          </Box>
        </Box>
      </Box>

      {isWhiteBoxVisible && (
        <Box className="outputhis-pop">
          <Box
            style={{ width: "100%", height: "43vh" }}
            className={"ag-theme-quartz"}
          >
            <AgGridReact
              columnDefs={columns}
              rowData={rowData}
              filter={true}
              rowHeight={rowHeight}
              headerHeight={headerHeight}
              enableCellTextSelection={true}
              rowSelection="multiple"
              rowSelectionPageOnly={true}
              pagination={true}
              paginationPageSize={50}
              suppressPaginationPanel={false} // This line ensures the pagination size is fixed and the pagination panel is shown
              suppressPaginationPageSize={false} // This line disables the pagination row count selection
              onGridReady={onGridReady}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DownloadAccordian;
