import {create} from 'zustand';

const useFilterStore = create(set => ({
    projectFilterList:[],
    setProjectFilterList:(newVal)=>set({projectFilterList:newVal}),
    creatorFilterList:[],
    setCreatorFilterList:(newVal)=>set({creatorFilterList:newVal}),
    startDate:null,
    setStartDate:(newVal)=>set({startDate:newVal}),
    toDate:null,
    setToDate:(newVal)=>set({toDate:newVal}),
    currentProjectFilter:null,
    setCurrentProjectFilter:(newVal)=>set({currentProjectFilter:newVal}),
    currentCreatorFilter:null,
    setCurrentCreatorFilter:(newVal)=>set({currentCreatorFilter:newVal}),
}));

export default useFilterStore;
