import { useEffect, useState } from 'react'
import { usePython } from 'react-py'
import Editor from "@monaco-editor/react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox

} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import useCodeEditorStore from '../store/codeEditorStore';
import beautify from 'js-beautify';
import { addHTMLtoPython, updatePythonCode } from '../utils';




function CodeBlock() {
  const [open, setOpen] = useState(false)
  const [openWarning, setOpenWarning] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [lastRun, setLastRun] = useState("")
    const {code, setCode, handleSaveCode,handleSaveCodeHtml, htmlCode, setHtmlCode, initialCode, initialHtml} = useCodeEditorStore()
  function handleEditorChange(value, event) {
    setCode(value)
  }
 
  const { runPython, stdout, stderr, isLoading, isRunning } = usePython()

  const handleCodeCheck = async () =>{
    if(htmlCode == ""){
      setErrorMessage("HTML content cannot be empty")
      setOpenWarning(true)
      return
    }
    if(code ==""){
      setErrorMessage("Code cannot be empty")
      setOpenWarning(true)
      return
    }
    if(code == initialCode && htmlCode == initialHtml){
      setErrorMessage("No modification present in code")
      setOpenWarning(true)
      return
    }

    if((stdout == "" && stderr =="") || stdout == "[]"){
      setErrorMessage("Output cannot be empty, please run code first and try again")
      setOpenWarning(true)
      return
    }
    if(stderr !=""){
      setErrorMessage("Code contains some error, please modify code and try again")
      setOpenWarning(true)
      return
    }
    if(lastRun != code){
      setErrorMessage("Code modified since last run, please run first and try again")
      setOpenWarning(true)
      return
    }
    setOpen(true)
    
  }


  return (
    <>

    <Box style={{display:"flex"}}>
      <Box style={{width:"800px"}}>
      <DialogTitle mb={2} id="alert-dialog-title2" className=" col1 card_title">
            {"HTML :"}
          </DialogTitle>
      <Editor
      height="500px"
      defaultLanguage="html"
      defaultValue={htmlCode}
      onChange={(value,event)=> setHtmlCode(value)}
      options={{minimap: {
        enabled: false  // Disable the minimap
    }}}
    />
      
      </Box>
      <Box style={{width:"600px"}}>
      <DialogTitle mb={2} id="alert-dialog-title2" className=" col1 card_title">
            {"Code :"}
          </DialogTitle>
      <Editor
      height="300px"
      defaultLanguage="python"
      defaultValue={code}
      onChange={handleEditorChange}
      options={{minimap: {
        enabled: false  // Disable the minimap
    }}}
    />
      <DialogTitle mb={2} id="alert-dialog-title2" className=" col1 card_title">
            {"Output :"}
          </DialogTitle>
      <Editor
      height="150px"
      defaultLanguage="json"
      value={stdout != "" ? beautify.js(stdout, {
        indent_size: 2,  // Set indentation size
        space_in_empty_paren: true, // Add space inside empty parentheses
      }) : stderr}
      options={{minimap: {
        enabled: false  // Disable the minimap
    }}}
    />
      </Box>

    
    
    </Box>
     <Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop:"30px",
                        paddingLeft:"10px",
                        display: "flex",
                        justifyContent: "right",
                        height: "30px",
                        margin: "10px 22px 40px 5px",
                      
                      }}
                    >
                      {isLoading ?  
                      <Button disabled={true}>Preparing</Button> :
                      <>
                     

            <Box mr={2}>
             <Button 
                        onClick={(e) => {
                          setLastRun(code)
                          runPython(addHTMLtoPython(updatePythonCode(code), htmlCode))
                        }}
                        autoFocus
                        className="approve_btn"
                        disabled={isLoading || isRunning}
                      >
                       {!isRunning ? "Run" : "Running"}
                      </Button>
                      </Box>
                      <Box>
           
            
              <Button
                onClick={() => {
                  handleCodeCheck()
                }}
                autoFocus
                className="approve_btn"
               
              >
                Save
              </Button>
            </Box>

                      </>}
                    </Box>
                  </Box>




                  <Box className="data preview">
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle style={{display:"flex", justifyContent:"center"}} id="alert-dialog-title2" className=" col1 card_title">
            {"Do you want to save the changes?"}
          </DialogTitle>

 

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  
                  

                
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "20px",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  <Box mr={3}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
            
                    >
                      <Button
                        onClick={() => {
                          // handleSaveCodeHtml(htmlCode, JSON.parse(localStorage.getItem("session")).employeeCode)
                          handleSaveCode(code, JSON.parse(localStorage.getItem("session")).employeeCode)
                          
                          setOpen(false)
                        }}
                        autoFocus
                        className="approve_btn"
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      
                    >
                      <Button
                        onClick={() => {
                          setOpen(false)
                        }}
                        autoFocus
                        className="approve_btn_no"
                      >
                        No
                      </Button>
                    </Box>
                  </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>

      <Box className="data preview">
        <Dialog
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          

 

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  
                <DialogTitle mb={2} style={{display:"flex", justifyContent:"center"}} id="alert-dialog-title2" className=" col1 card_title">
            {errorMessage}
          </DialogTitle>

                
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "20px",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  <Box mr={3}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
            
                    >
                      <Button
                        onClick={() => {
                          
                          setOpenWarning(false)
                        }}
                        autoFocus
                        className="approve_btn"
                      >
                        Ok
                      </Button>
                    </Box>
                  </Box>
                  
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>

    </>
  );
}

export default CodeBlock;
