import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
    return (
        <>
            <Box id="footer">
                <Container maxWidth>
                    <Typography variant='h6' className='bl'> <strong>Copyright ©2023 <a href="https://www.decimalpointanalytics.com/" target="_blank">Decimal
                        Point Anlytics Pvt Ltd.</a></strong> </Typography>
                </Container>
            </Box>
        </>
    )
}

export default Footer
