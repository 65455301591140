import {  toast } from "react-toastify";
import axios from "axios";
import { handleApiError } from "../utils";

export const runScraperOnce = async (urlId, empCode, version) =>{
    const session = JSON.parse(localStorage.getItem("session"));
    const base = process.env.REACT_APP_BASE_URL;
    const endp = `${version}/run_srapper_once`;
    const url = base + endp;
    const data = {
      url_id: urlId,
      emp_id: parseInt(empCode),
    };
    const headers = {
      accept: "application/json",
      Authorization:"Bearer "+session.accessToken,
    };
    try {
      const response = await axios.post(url, data, { headers });
      console.log("POST request successful:", response.data);
      toast.success("Scraper scheduled successfully");
    } catch (error) {
      console.error("Error sending PATCH request:", error);
      handleApiError(error)
    }
}