import React, { useState, useEffect } from 'react';

const CustomHeader = (props) => {
    const [isChecked, setIsChecked] = useState(false);

    const onCheckboxChange = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        selectRowsOnCurrentPage(newCheckedState);
    };

    const selectRowsOnCurrentPage = (isSelected) => {
        const pageSize = props.api.paginationGetPageSize();
        const currentPage = props.api.paginationGetCurrentPage();
        const startRow = currentPage * pageSize;
        const endRow = Math.min(startRow + pageSize, props.api.getDisplayedRowCount());
        
        for (let i = startRow; i < endRow; i++) {
            const rowNode = props.api.getDisplayedRowAtIndex(i);
            rowNode.setSelected(isSelected);
        }
    };

    useEffect(() => {
        const onPaginationChanged = () => {
            setIsChecked(false);
            selectRowsOnCurrentPage(false);
        };

        props.api.addEventListener('paginationChanged', onPaginationChanged);

        return () => {
            props.api.removeEventListener('paginationChanged', onPaginationChanged);
        };
    }, [props.api]);

    return (
        <div>
            <input type="checkbox" checked={isChecked} onChange={onCheckboxChange} />
        </div>
    );
};

export default CustomHeader;
