import React from "react";
import "../App.css"; // Adjust the path to where your CSS file is

const AlertBox = ({ text,title }) => {
  return (
    <div className="alert-box">
      <div className="alert-header">{title}</div>
      <div className="alert-body">{text}</div>
    </div>
  );
};

export default AlertBox;
