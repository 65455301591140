import React, { useState } from 'react'
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,

  } from "@mui/material";
  import "react-toastify/dist/ReactToastify.css";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Link, useHistory, useParams } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import LinkIcon from "@mui/icons-material/Link";
import EditButton from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import Visibility from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import PythonCompiler from './PythonCompiler';
import useCodeEditorStore from '../store/codeEditorStore';
  

const CodeDisplayModal = () => {
  const {openCodeEditor, setOpenCodeEditor} = useCodeEditorStore()
  return (
    <Box  className="data_preview">
        <Dialog height={900}
        style={{margin:"10px"}}
          open={openCodeEditor}
          onClose={() => setOpenCodeEditor(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
          fullWidth
        >
 
    
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenCodeEditor(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                   <PythonCompiler/>
                </Box>
                
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
  )
}

export default CodeDisplayModal