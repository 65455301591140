import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Container maxWidth>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100vh" }}
      >
        <Box className="al_center">
          <Typography style={{ fontSize: "150px", fontWeight: "bold" }}>
            404
          </Typography>
          <Typography variant="h4" className=" gray card_heading">
            Page Not Found
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
};
export default NotFound;
