import React, { useState } from 'react'
import DownloadIcon from "@mui/icons-material/Download";
import {
    Box,
    IconButton,

  } from "@mui/material";

const DownloadLoaderCell = ({params, on_download, details}) => {
    const [isDownloading, setIsDownloading] = useState(false)
  return (
    <>
    {params.data.status == "Completed" && !isDownloading ? (
      <IconButton
        onClick={() =>{
            setIsDownloading(true)
          on_download(
            {
              json: params.data.preview,
              log: params.data.preview_log,
              csv: params.data.preview_csv,
            },
            details,
            "json",
            setIsDownloading
          )
        }
        }
      >
        <DownloadIcon
          className="download_icon comment_icon"
          fontSize="small"
        />
      </IconButton>
    ) : isDownloading ? (<Box className="loadingbtn-container2"  >
        <Box className="dot2" />
        </Box>):(
      "-"
    )}
  </>
  )
}

export default DownloadLoaderCell