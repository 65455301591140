import axios from "axios";
import {  toast } from "react-toastify";
const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
apiInstance.interceptors.request.use(
  function (config) {
    const session = JSON.parse(localStorage.getItem("session"));
    if (session && session.accessToken) {
      config.headers["Authorization"] = `Bearer ${session.accessToken}`;
    }
    config.headers["Accept"] = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor
apiInstance.interceptors.response.use(
  function (response) {
    // Handle successful responses
    // For example, you can modify response data before passing it to components
    return response;
  },
  function (error) {
    if ((error.response && error.response.status === 401) || error.response.status === 403) {
      // toast.error("Your session has expired. Please login again.");
      // Handle unauthorized error, for example, redirect to login page
      localStorage.removeItem("session");
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
