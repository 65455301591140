import {
  Box,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  Button,
  FormControl,
  Autocomplete
} from "@mui/material";

import FilledInput from "@mui/material/FilledInput";

import Dayjs from "dayjs";

import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useState, useEffect, useCallback, useRef } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useHistory } from "react-router-dom";

import ErrorIcon from "@mui/icons-material/Error";
import TodayIcon from "@mui/icons-material/Today";
import SearchIcon from "@mui/icons-material/Search";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

import record_not_found from "../Assets/images/record_not_found.png";

import axios from "axios";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useHomeStore from "../store/homeStore";
import useFilterStore from "../store/filterStateStore";
import Schedule_Using_Date_Time_Frequency from "./Schedule_Using_Date_Time_Frequency";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import apiInstance from "../apiInterceptor";
import { capitalizeFirstLetter, cronToText, dateFormatter, formatTimeString, getCurrentDateTime, handleApiError, transformString } from "../utils";
import FilterRow from "../Components/FilterRow";
import { LoadingBtn } from "../Components/LoadingBtn";

const heading = {
  pending: "Pending For Scheduling",
  approved: "Scheduled Scrapers",
  not_working: "Failed Scrapers",
  disabled: "Disabled Scrapers",
};
function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
// header and row height
const rowHeight = 24;
const headerHeight = 28;

const colWidth = 55;

// BoldCellRenderer definition
const BoldCellRenderer = ({ value }) => {
  return <div style={{ fontWeight: "bold", cursor: "pointer" }}>{value} </div>;
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f7f7f7",
    borderRadius: "13px",
    color: "black",
    fontSize: "12px",
    maxWidth: 300,
    padding: "5px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
  },
});

const formatTooltipContent = (info) => {
  const points = info.split(";");
  return (
    <ul>
      {points.map((point, index) => (
        <li key={index}>{point.trim()}</li>
      ))}
    </ul>
  );
};




const Home = ({
  version,


}) => {
  const pushVersion = version == "v2" ? "" : "/v1";
  const current_state = sessionStorage.getItem("currentState");

  // it defines current tab: pending/scheduled/failed/disabled
  const [progress, setProgress] = useState("pending");
 // contains projects of the user
  const [projects, setProjects] = useState("");
  const session = JSON.parse(localStorage.getItem("session"));

    //download & schedule actions
    // define options for action dropdown
    const multiActionArray= ["Download","Re-Schedule","Disable","Rerun"];

    const [currentAction, setCurrentAction] = useState(null);
    const {projectFilterApplied, setProjectFilterApplied} = useHomeStore()
  const [enableAction, setEnableAction] = useState(false)
  let currentActionDefine = null;
  const StyledIconButton = styled(IconButton)(({ theme, disabled }) => ({
    '&.Mui-disabled': {
      opacity: disabled ? 0.4 : 1,
      pointerEvents: disabled ? 'none' : 'auto',
      
    },
    color:'#1930ab',
    border:'0px',

  }));
  
  const handleDisableComment = (event)=>{
    setDisableComment(event.target.value)
  }


  // changes column header for actions and also current action state
    const handleMultiActionChange = (newVal) =>{
      // && newVal != null && newVal !="Re-run failed scrapers" && progress !="not_working"
      if(projectFilterApplied == false){
        toast.info("Please apply a project filter first", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          })
        return;
      }
      if(newVal == null){
        currentActionDefine=newVal
        setCurrentAction(newVal);
        if(progress =="not_working"){
          setColDef(notWorkingScrapersDT)
        }else{
          setColDef(schedularScraperDT);
        }
      
      return;
      }else{
        currentActionDefine=newVal
        setCurrentAction(newVal);
        
      if(progress == "not_working"){
        setColDef(notWorkingScrapersDT2);
      }else{
        setColDef(schedularScraperDT2);
      }
      }
      
      
    }

    // headers of ag grid for pending tab
  const pendingScraperDT = [
    {
      headerName: "Info",
      field: "Urlid",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <CustomTooltip
            style={{ cursor: "pointer" }}
            title={formatTooltipContent(params.data.MoreInfo)}
            arrow
          >

            <InfoOutlinedIcon fontSize="small" className="info_icon" />
          </CustomTooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "URL",
      field: "Websiteurl",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 2,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Tooltip title={params.data.Websiteurl} arrow>
            <a href={params.data.Websiteurl} target="_blank">
              <InsertLinkOutlinedIcon fontSize="small" className="link_icon" />
            </a>
          </Tooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    {
      headerName: "Status",
      field: "Status",
      minWidth: 130,
      maxWidth: 130,
      flex: 1,
      sortable: true,

      cellRenderer: (params) => (
        <>
          <Box id="status_btn">
            <Tooltip
              title={
                params.data.Status == "yellow"
                  ? "Scraper Validation Pending"
                  : params.data.Status == "red"
                    ? "Awaiting Approval"
                    : params.data.Status == "green"
                      ? "Ready to Schedule"
                      : params.data.Status === "light-red"
                        ? "Working Validation is fail"
                        : "Pending Review"
              }
              arrow
            >
              {params.data.Status == "yellow" && (
                <Box className="yellowbx">
                  <Box className="yellowbx1"></Box>
                </Box>
              )}
              {params.data.Status == "green" && (
                <Box className="greenbx">
                  <Box className="greenbx1"></Box>
                </Box>
              )}
              {params.data.Status == "red" && (
                <Box className="redbx">
                  <Box className="redbx1"></Box>
                </Box>
              )}
              {params.data.Status == "default" && (
                <Box className="grybx">
                  <Box className="grybx1"></Box>
                </Box>
              )}
              {params.data.Status == "light-red" && (
                <Box className="redbx">
                  <Box className="redbx1"></Box>
                </Box>
              )}
            </Tooltip>
          </Box>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      headerClass: "center-header",
    },

    {
      headerName: "Scraper ID",
      field: "Urlid",
      minWidth: 200,
      flex: 0.5,
      cellRenderer: BoldCellRenderer,
      sortable: true,
    },
    {
      headerName: "Scraper Name",
      field: "ScraperName",
      minWidth: 250,
      flex: 0.5,
      sortable: true,
    },

    ...(version === "v2"
      ? [
        {
          headerName: "Scraper Type",
          field: "ScraperType",
          minWidth: 300,
          flex: 1,
          sortable: true,
        },
      ]
      : [
        {
          headerName: "Scraper Type",
          field: "ScraperType",
          minWidth: 400,
          flex: 1,
          sortable: true,
        },
      ]),
    {
      headerName: "Project Name",
      field: "Projectname",
      minWidth: 355,
      flex: 1,
      sortable: true,
    },
    ...(version === "v2"
      ? [
        {
          headerName: "Group Name",
          field: "Groupname",
          minWidth: 120,
          flex: 1,
          sortable: true,
        },
      ]
      : []),


  ];
// headers of ag grid for failed tab
  const notWorkingScrapersDT = [
    {
      headerName: "Info",
      field: "Urlid",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <CustomTooltip
            style={{ cursor: "pointer" }}
            title={formatTooltipContent(params.data.MoreInfo)}
            arrow
          >
            <InfoOutlinedIcon fontSize="small" className="info_icon" />
          </CustomTooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "URL",
      field: "Websiteurl",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 2,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Tooltip title={params.data.Websiteurl} arrow>
            <a href={params.data.Websiteurl} target="_blank">
              <InsertLinkOutlinedIcon fontSize="small" className="link_icon" />
            </a>
          </Tooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    {
      headerName: "Scraper ID",
      field: "Urlid",
      minWidth: 130,
      flex: 0.5,
      cellRenderer: BoldCellRenderer,
      sortable: true,
    },
    {
      headerName: "Recent status",
      field: "Status",
      minWidth: 110,
      maxWidth: 110,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Box id="Recent_status">
            <Tooltip title={params.data.status} arrow>
              {params.data.status === "scheduled" && <FiberManualRecordIcon fontSize="small" className="Scheduled" />}
              {params.data.status === "in_progress" && <FiberManualRecordIcon fontSize="small" className="inprogress" />}
              {params.data.status === "failed" && <FiberManualRecordIcon fontSize="small" className="Failed" />}
              {params.data.status === "completed" && <FiberManualRecordIcon fontSize="small" className="Complete" />}
            </Tooltip>
          </Box>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "Scraper Name",
      field: "ScraperName",
      minWidth: 140,
      flex: 0.5,
      sortable: true,
    },
    {
      headerName: "Scraper Type",
      field: "ScraperType",
      minWidth: 250,
      flex: 1,
      sortable: true,
    },

    {
      headerName: "Project Name",
      field: "Projectname",
      minWidth: 140,
      sortable: true,
      flex: 1,
    },
    ...(version === "v2"
      ? [
        {
          headerName: "Group Name",
          field: "Groupname",
          minWidth: 200,
          flex: 1,
          sortable: true,
        },
      ]
      : []),

  ]

  const notWorkingScrapersDT2 = [
  {
           headerCheckboxSelection: true,
          checkboxSelection: (params) => (params.data.status =="failed"),
          headerName: '',
          field: 'checkbox', 
          maxWidth: 50,  
        },
    {
      headerName: "Info",
      field: "Urlid",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <CustomTooltip
            style={{ cursor: "pointer" }}
            title={formatTooltipContent(params.data.MoreInfo)}
            arrow
          >
            <InfoOutlinedIcon fontSize="small" className="info_icon" />
          </CustomTooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "URL",
      field: "Websiteurl",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 2,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Tooltip title={params.data.Websiteurl} arrow>
            <a href={params.data.Websiteurl} target="_blank">
              <InsertLinkOutlinedIcon fontSize="small" className="link_icon" />
            </a>
          </Tooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    {
      headerName: "Scraper ID",
      field: "Urlid",
      minWidth: 130,
      flex: 0.5,
      cellRenderer: BoldCellRenderer,
      sortable: true,
    },
    {
      headerName: "Recent status",
      field: "Status",
      minWidth: 110,
      maxWidth: 110,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Box id="Recent_status">
            <Tooltip title={params.data.status} arrow>
              {params.data.status === "scheduled" && <FiberManualRecordIcon fontSize="small" className="Scheduled" />}
              {params.data.status === "in_progress" && <FiberManualRecordIcon fontSize="small" className="inprogress" />}
              {params.data.status === "failed" && <FiberManualRecordIcon fontSize="small" className="Failed" />}
              {params.data.status === "completed" && <FiberManualRecordIcon fontSize="small" className="Complete" />}
            </Tooltip>
          </Box>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "Scraper Name",
      field: "ScraperName",
      minWidth: 140,
      flex: 0.5,
      sortable: true,
    },
    {
      headerName: "Scraper Type",
      field: "ScraperType",
      minWidth: 250,
      flex: 1,
      sortable: true,
    },

    {
      headerName: "Project Name",
      field: "Projectname",
      minWidth: 140,
      sortable: true,
      flex: 1,
    },
    ...(version === "v2"
      ? [
        {
          headerName: "Group Name",
          field: "Groupname",
          minWidth: 200,
          flex: 1,
          sortable: true,
        },
      ]
      : []),

  ]
  // headers of ag grid for scheduled tab
  const schedularScraperDT=[
    
    {
      headerName: "Info",
      field: "Urlid",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <CustomTooltip
            style={{ cursor: "pointer" }}
            title={formatTooltipContent(params.data.MoreInfo)}
            arrow
          >
            <InfoOutlinedIcon fontSize="small" className="info_icon" />
          </CustomTooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "URL",
      field: "Websiteurl",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 2,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Tooltip title={params.data.Websiteurl} arrow>
            <a href={params.data.Websiteurl} target="_blank">
              <InsertLinkOutlinedIcon fontSize="small" className="link_icon" />
            </a>
          </Tooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
    },

    {
      headerName: "Scraper ID",
      field: "Urlid",
      minWidth: 130,
      flex: 0.5,
      sortable: true,
      cellRenderer: BoldCellRenderer,
    },

    {
      headerName: "Recent status",
      field: "Status",
      minWidth: 110,
      maxWidth: 110,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Box id="Recent_status">
            <Tooltip title={params.data.Status} arrow>
              {params.data.Status === "Scheduled" && <FiberManualRecordIcon fontSize="small" className="Scheduled" />}
              {params.data.Status === "In Progress" && <FiberManualRecordIcon fontSize="small" className="inprogress" />}
              {params.data.Status === "Failed" && <FiberManualRecordIcon fontSize="small" className="Failed" />}
              {params.data.Status === "Completed" && <FiberManualRecordIcon fontSize="small" className="Complete" />}
            </Tooltip>
          </Box>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },


    {
      headerName: "Scraper Name",
      field: "ScraperName",
      minWidth: 150,
      sortable: true,
      flex: 0.5,
    },

    {
      headerName: "Scraper Type",
      field: "ScraperType",
      minWidth: 170,
      flex: 1,
      sortable: true,
    },

    {
      headerName: "Project Name",
      field: "Projectname",
      minWidth: 150,
      sortable: true,
      flex: 1,
    },
    ...(version === "v2"
      ? [
        {
          headerName: "Group Name",
          field: "Groupname",
          minWidth: 120,
          flex: 1,
          sortable: true,
        },
      ]
      : []),

    // {
    //   headerName: "Frequency",
    //   field: "Frequency",
    //   minWidth: 80,
    //   flex: 1,
    //   sortable: true,
    // },
    // {
    //   headerName: "Next Run",
    //   field: "NextRun",
    //   minWidth: 130,
    //   flex: 1,
    //   sortable: true,
    // },
    {
      headerName: "Last Run",
      field: "LastRun",
      minWidth: 140,
      flex: 1,
      sortable: true,
    },


    {
      headerName: "Duration",
      field: "Duration",
      minWidth: 90,
      flex: 1,
      headerClass: "right-header",
      sortable: true,
      cellStyle: {
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
      },
    },
    {
      headerName: "Actions",
      field: "Preview",
      minWidth: 80,
      maxWidth: 80,
      sortable: true,
      flex: 1,
      headerClass: "center-header",
      cellRenderer: (params) => (
        <>
          {params.data.Preview != null  ? (
            <IconButton
              onClick={() =>
                handleClickOpenPreview(
                  {
                    json: params.data.Preview,
                    log: params.data.Log,
                    csv: params.data.Csv,
                    project_code: params.data.ProjectCode,
                  },
                  params.data.Status == "Failed" && params.data.Log != null ? "pre_logs" : "pre_json"
                )
              }
            >
              <RemoveRedEyeIcon className="comment_icon" fontSize="small" />
            </IconButton>
          ) : (
            "-"
          )}
          {params.data.Preview != null ? (
            <IconButton
              onClick={() =>
                handleClickOpenPreview(
                  {
                    json: params.data.Preview,
                    log: params.data.Log,
                    csv: params.data.Csv,
                    project_code: params.data.ProjectCode,
                  },
                  params.data.Status == "Failed"  && params.data.Log != null ? "logs" : "json"
                )
              }
            >
              <DownloadIcon className="comment_icon" fontSize="small" />
            </IconButton>
          ) : (
            "-"
          )}
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },

  ];

  // headers of ag grid for scheduled tab, but when in action
  const schedularScraperDT2=[

        {
           headerCheckboxSelection: true,
          checkboxSelection: (params) => (currentActionDefine=="Re-Schedule" || currentActionDefine=="Disable" || (currentActionDefine =="Rerun" && params.data.Status =="Failed") || (currentActionDefine=="Download"  && params.data.Preview !== null)),
          headerName: '',
          field: 'checkbox', 
          maxWidth: 50,  
        },

    
    {
      headerName: "Info",
      field: "Urlid",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <CustomTooltip
            style={{ cursor: "pointer" }}
            title={formatTooltipContent(params.data.MoreInfo)}
            arrow
          >
            <InfoOutlinedIcon fontSize="small" className="info_icon" />
          </CustomTooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "URL",
      field: "Websiteurl",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 2,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Tooltip title={params.data.Websiteurl} arrow>
            <a href={params.data.Websiteurl} target="_blank">
              <InsertLinkOutlinedIcon fontSize="small" className="link_icon" />
            </a>
          </Tooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
    },

    {
      headerName: "Scraper ID",
      field: "Urlid",
      minWidth: 130,
      flex: 0.5,
      sortable: true,
      cellRenderer: BoldCellRenderer,
    },

    {
      headerName: "Recent status",
      field: "Status",
      minWidth: 110,
      maxWidth: 110,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Box id="Recent_status">
            <Tooltip title={params.data.Status} arrow>
              {params.data.Status === "Scheduled" && <FiberManualRecordIcon fontSize="small" className="Scheduled" />}
              {params.data.Status === "In Progress" && <FiberManualRecordIcon fontSize="small" className="inprogress" />}
              {params.data.Status === "Failed" && <FiberManualRecordIcon fontSize="small" className="Failed" />}
              {params.data.Status === "Completed" && <FiberManualRecordIcon fontSize="small" className="Complete" />}
            </Tooltip>
          </Box>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "Scraper Name",
      field: "ScraperName",
      minWidth: 150,
      sortable: true,
      flex: 0.5,
    },

    {
      headerName: "Scraper Type",
      field: "ScraperType",
      minWidth: 170,
      flex: 1,
      sortable: true,
    },

    {
      headerName: "Project Name",
      field: "Projectname",
      minWidth: 150,
      sortable: true,
      flex: 1,
    },
    ...(version === "v2"
      ? [
        {
          headerName: "Group Name",
          field: "Groupname",
          minWidth: 120,
          flex: 1,
          sortable: true,
        },
      ]
      : []),

    // {
    //   headerName: "Frequency",
    //   field: "Frequency",
    //   minWidth: 80,
    //   flex: 1,
    //   sortable: true,
    // },
    // {
    //   headerName: "Next Run",
    //   field: "NextRun",
    //   minWidth: 130,
    //   flex: 1,
    //   sortable: true,
    // },
    {
      headerName: "Last Run",
      field: "LastRun",
      minWidth: 140,
      flex: 1,
      sortable: true,
    },

    {
      headerName: "Duration",
      field: "Duration",
      minWidth: 90,
      flex: 1,
      headerClass: "right-header",
      sortable: true,
      cellStyle: {
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
      },
    },
    {
      headerName: "Actions",
      field: "Preview",
      minWidth: 80,
      maxWidth: 80,
      sortable: true,
      flex: 1,
      headerClass: "center-header",
      cellRenderer: (params) => (
        <>
          {params.data.Preview!= null  ? (
            <IconButton
              onClick={() =>
                handleClickOpenPreview(
                  {
                    json: params.data.Preview,
                    log: params.data.Log,
                    csv: params.data.Csv,
                    project_code: params.data.ProjectCode,
                  },
                  params.data.Status == "Failed"  && params.data.Log != null ? "pre_logs" : "pre_json"
                )
              }
            >
              <RemoveRedEyeIcon className="comment_icon" fontSize="small" />
            </IconButton>
          ) : (
            "-"
          )}
          {params.data.Preview != null  ? (
            <IconButton
              onClick={() =>
                handleClickOpenPreview(
                  {
                    json: params.data.Preview,
                    log: params.data.Log,
                    csv: params.data.Csv,
                    project_code: params.data.ProjectCode,
                  },
                  params.data.Status == "Failed"  && params.data.Log != null ? "logs" : "json"
                )
              }
            >
              <DownloadIcon className="comment_icon" fontSize="small" />
            </IconButton>
          ) : (
            "-"
          )}
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];
  // column headers for disabled tab
  const todaysScheduleDT = [
    {
      headerName: "Info",
      field: "Urlid",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 1,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <CustomTooltip
            style={{ cursor: "pointer" }}
            title={formatTooltipContent(params.data.MoreInfo)}
            arrow
          >
            <InfoOutlinedIcon fontSize="small" className="info_icon" />
          </CustomTooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      headerName: "URL",
      field: "Websiteurl",
      minWidth: colWidth,
      maxWidth: colWidth,
      flex: 2,
      sortable: true,
      cellRenderer: (params) => (
        <>
          <Tooltip title={params.data.Websiteurl} arrow>
            <a href={params.data.Websiteurl} target="_blank">
              <InsertLinkOutlinedIcon fontSize="small" className="link_icon" />
            </a>
          </Tooltip>
        </>
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    {
      headerName: "Scraper ID",
      field: "Urlid",
      minWidth: 190,
      flex: 0.5,
      cellRenderer: BoldCellRenderer,
      sortable: true,
    },
    {
      headerName: "Scraper Name",
      field: "ScraperName",
      minWidth: 120,
      flex: 0.5,
      sortable: true,
    },
    {
      headerName: "Scraper Type",
      field: "ScraperType",
      minWidth: 250,
      flex: 1,
      sortable: true,
    },

    {
      headerName: "Project Name",
      field: "Projectname",
      minWidth: 250,
      sortable: true,
      flex: 1,
    },
    ...(version === "v2"
      ? [
        {
          headerName: "Group Name",
          field: "Groupname",
          minWidth: 200,
          flex: 1,
          sortable: true,
        },
      ]
      : []),
    {
      headerName: "Disable Reason",
      field: "Comment",
      minWidth: 300,
      sortable: true,
      flex: 1,
    },
  ];
  const requestOrderRef = useRef(0)
  const [gridApi, setGridApi] = useState(null);

  // have data for selected rows for action
  const [selectedRowForSchedule, setSelectedRowForSchedule] = useState([]);

  // Callbacks , method to communicate with aggrid
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  // updates data of selected rows in aggrid
  const onSelectionChanged = useCallback(() => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);

      if(selectedData.length>0){
        setEnableAction(true)
      }
      setSelectedRowForSchedule(selectedData)
    }
  }, [gridApi]);


  // applies filter on aggrid according to search
  const onFilterTextBoxChanged = useCallback(() => {
    gridApi.setQuickFilter(document.getElementById("filter-text-box").value);
  }, [gridApi]);


  // contains original all scraper data
  const {rowData, setRowData} = useHomeStore()
  

  // header row
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [csure, setSure]  = useState(false)
  const [disableComment, setDisableComment] = useState("");
  const [openScheduler, setOpenScheduler] = useState(false);

  const handleCloseSchedular = ()=>{
    setOpenScheduler(false)
  }
 

  const {colDefs, setColDef} = useHomeStore()
  

  const history = useHistory();
  const location = useLocation();

  // have count of scrapers in each tab
  const [dashboard, setDashboard] = useState({});
  const [intervalId, setIntervalId] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const [fileData, setFileData] = useState({
    data: {},
    id: null,
    type: null,
  });

  const [isDownloadingState, setDownloadingState] = useState(false)

  const [selectedOption] = useState("b");
  const [cronexp, setCronExp] = useState("");
  const [urlIdArray, setUrlIdArray] = useState([]);
  const [jobIdArray, setJobIdArray] = useState([]);


  //filter states
  // contains scrapers data of home page
  const {displayData, setDisplayData} = useHomeStore();

  const {setProjectFilterList} = useFilterStore();
  const { setCreatorFilterList} = useFilterStore();
  const {startDate, setStartDate} = useFilterStore();
  const {toDate, setToDate} =useFilterStore();
  const {currentProjectFilter, setCurrentProjectFilter} =useFilterStore();
  const {currentCreatorFilter, setCurrentCreatorFilter} = useFilterStore();


  // applies filter on grgrid accoring to set data
  const handleApplyFilter = ()=>{
    setEnableAction(true)
    setLoading(true)
        let filteredData = rowData;

          if (currentProjectFilter && currentProjectFilter.name !== "All") {
            setProjectFilterApplied(true)
            filteredData = filteredData.filter(obj => obj.Projectname === currentProjectFilter.name);
          }

          if (currentCreatorFilter && currentCreatorFilter.name !== "All") {
            filteredData = filteredData.filter(obj => obj.Empname === currentCreatorFilter.name);
          }  

        if (startDate) {
          const startDateParsed = Dayjs(startDate).startOf('day'); // Start of the day
          filteredData = filteredData.filter(obj => {
              const date1 = Dayjs(obj.Createdon, "MMM DD, YYYY").startOf('day');
              return date1.isAfter(startDateParsed) || date1.isSame(startDateParsed);
          });
      }
      
      if (toDate) {
          const toDateParsed = Dayjs(toDate).endOf('day'); // End of the day
          filteredData = filteredData.filter(obj => {
              const date1 = Dayjs(obj.Createdon, "MMM DD, YYYY").endOf('day');
              return date1.isBefore(toDateParsed) || date1.isSame(toDateParsed);
          });
      }
        const sortedData = [...filteredData].sort((a, b) => new Date(b.Createdon) - new Date(a.Createdon));
    setDisplayData(sortedData);
          setLoading(false)
    
  }

  useEffect(()=>{
    let tempDisplayData =[]
      tempDisplayData = rowData.filter(obj => obj.Projectname == currentProjectFilter);
      if(currentProjectFilter=="All"){
        tempDisplayData=rowData
      }
      const counts = {
        empNames: {}
      };
      
      tempDisplayData.forEach(({ Empname }) => {
        counts.empNames[Empname] = (counts.empNames[Empname] || 0) + 1;
      });
      let uniqueCreatedByNamesWithCounts = Object.entries(counts.empNames).map(([name, count]) => ({ name, count }));
      uniqueCreatedByNamesWithCounts.unshift({ name: "All", count: tempDisplayData.length});
      setCreatorFilterList(uniqueCreatedByNamesWithCounts)
      
  },[ currentProjectFilter])



  useEffect(()=>{
    
      if(currentCreatorFilter || currentProjectFilter || startDate || toDate){
       
        handleApplyFilter()
        updateCreatorList()
      }
  },[rowData])

  const updateCreatorList = ()=>{
    let tempDisplayData = []
  
     tempDisplayData = rowData.filter(obj => obj.Projectname == currentProjectFilter?.name);
    if (currentProjectFilter?.name == "All" || !currentProjectFilter) {
      tempDisplayData = rowData
    } 
    const counts = {
      empNames: {}
    };

    tempDisplayData.forEach(({ Empname }) => {
      counts.empNames[Empname] = (counts.empNames[Empname] || 0) + 1;
    });
    let uniqueCreatedByNamesWithCounts = Object.entries(counts.empNames).map(([name, count]) => ({ name, count }));
    uniqueCreatedByNamesWithCounts.unshift({ name: "All", count: tempDisplayData.length });
    setCreatorFilterList(uniqueCreatedByNamesWithCounts)
  }
  useEffect(() => {
    updateCreatorList()

  }, [currentProjectFilter])

// clear all filters
  const handleClearFilter = ()=>{
    setLoading(true)
    setCurrentCreatorFilter(null)
    setCurrentProjectFilter(null)
    setProjectFilterApplied(false)
    setStartDate(null)
    if(progress=="approved"){
      setColDef(schedularScraperDT);
    }else if(progress == "not_working"){
      setColDef(notWorkingScrapersDT)
    }else{
      setColDef(pendingScraperDT)
    }
    
    setToDate(null)
    setEnableAction(false)
    setCurrentAction(null)
    setDisplayData(rowData)
    
    setLoading(false)
  }






  const extractLogsFromUrl = async (url) => {
    if (!url) return "No logs found";
    const response = await axios.get(url);
    const data = await response.data;

    return data;
  };

async function handleClickOpenPreview (value, type) {
    await fetchPreviewData(value, type);
    if (type.includes("pre")) setOpen2(true);
  };

  const handleDownload = (data, fileName, type) => {
    if (version == "v2") {
      if (!data){
        setDownloadingState(false)
        return;
      }
      const link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        setDownloadingState(false)
      }, 1000);
      toast.success("Download started successfully")
      document.body.removeChild(link);
      
      return;
    }
  
    const jsonData = JSON.stringify(data);
    let blob = new Blob([jsonData], { type: "application/json" });
    if (type == "logs") {
      blob = new Blob([data], { type: "text/plain" });
    }
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };


  const fetchPreviewData = async (value, type) => {
    const url = `${version}/preview_json_data`;

    try {
      if (version == "v2") {
        // need project id
        const response = await apiInstance.post(
          url,
          {
            prj_id: `${value.project_code}`,
            json_bucket_file_path: value.json?.replace(
              process.env.REACT_APP_BUCKET_URL,
              ""
            ),
            log_bucket_file_path: value.log?.replace(
              process.env.REACT_APP_BUCKET_URL,
              ""
            ),
          }
        );
        let tempValue = value.json;
        if (type.includes("log")) {
          tempValue = value.log;
        }
        if(!tempValue){
          tempValue = "no logs"
        }
        if (response.statusText === "OK") {
          const parts = tempValue
            .replace(process.env.REACT_APP_BUCKET_URL, "")
            .split("/");
          const fileNameDownload = parts[parts.length - 1];
          if (type.includes("json")) {
            setFileData({
              data: response.data.preview,
              id: tempValue.id,
              type: type,
              fileName: fileNameDownload,
              presigned_url: response.data.json_presigned_url,
            });
          } else {
            const logContent = await extractLogsFromUrl(
              response.data.log_presigned_url
            );
            setFileData({
              data: logContent,
              id: tempValue.id,
              type: type,
              fileName: fileNameDownload,
              presigned_url: response.data.log_presigned_url,
            });
          }
          if (!type.includes("pre")) {
            const parts = tempValue
              .replace(process.env.REACT_APP_BUCKET_URL, "")
              .split("/");
    
            const fileNameDownload = parts[parts.length - 1];

            if (type == "logs") {
              handleDownload(
                response.data.log_presigned_url,
                fileNameDownload,
                type
              );
            } else {
              handleDownload(
                response.data.csv_presigned_url
                  ? response.data.csv_presigned_url
                  : response.data.json_presigned_url,
                fileNameDownload,
                type
              );
            }
          }
        }
      } else {
        let tempValue = value.json;
        if (type.includes("log")) {
          tempValue = value.log;
        }
        const response = await axios.get(
          process.env.REACT_APP_BUCKET_URL + tempValue
        );
        if (response.statusText === "OK") {
          const parts = tempValue.split("/");
          const fileNameDownload = parts[parts.length - 1];
          setFileData({
            data: response.data,
            id: tempValue.id,
            type: type,
            fileName: fileNameDownload,
          });
        
          if (!type.includes("pre")) {
            if (type == "logs") {
              handleDownload(response.data, fileNameDownload, type);
            } else {
              handleDownload(response.data, fileNameDownload, type);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleClose2 = () => {
    setOpen2(false);

    setFileData({ data: {}, id: null, type: null });
  };

  const checkStatus = (s) => {
    if (s == "yellow") {
      return "Pending for working validation";
    } else if (s == "red") {
      return "Pending for Approval";
    } else if (s == "green") {
      return "Approved";
    } else if (s == "default") {
      return "Scheduled";
    } else if (s == "in_progress") {
      return "In Progress";
    } else if (s == "failed") {
      return "Failed";
    } else if (s == "completed") {
      return "Completed";
    } else {
   
      return "Scheduled";
    }
  };

  // modify data from response of "get-scraper-details" api according to our requirement (important)
  const handleProgressUpdation = (data) => {
    let transformedData = [];
  
    if (progress == "pending") {
      setColDef(pendingScraperDT);
      transformedData = data.map((obj) => ({
        Urlid: obj.url_id,
        Websiteurl: obj.website_url,
        Projectname: obj.project_name,
        MoreInfo: `Created on: ${dateFormatter(obj.added_on)}; Added By: ${obj.emp_name ? obj.emp_name : "-"
          }`,
        ScraperType:
          obj.is_multiurl && obj.scraper_type && obj.scraper_type === "PAGINATION" ?
          transformString(obj.scraper_type.replace(/_/g, ' ') +
          " > " +
          obj.pagination_type.replace(/_/g, ' ') +
          " > " +
          obj.data_source_type.replace(/_/g, ' ') + " > " + "MULTI URL")
           : obj.scraper_type && obj.scraper_type === "PAGINATION"
            ? transformString(obj.scraper_type.replace(/_/g, ' ') +
            " > " +
            obj.pagination_type.replace(/_/g, ' ') +
            " > " +
            obj.data_source_type.replace(/_/g, ' '))
            : obj.is_multiurl && obj.scraper_type && obj.scraper_type === "NO_PAGINATION"? "No Pagination > Multi Url" 
            :obj.file_download_flag ? "File Download" : obj.scraper_type ? transformString(obj.scraper_type.replace(/_/g, ' ')) : "",
        ScraperName: obj.scraper_name ? capitalizeFirstLetter(obj.scraper_name) : "-",
        Createdon: dateFormatter(obj.added_on),
        AddedBy: obj.emp_name ? obj.emp_name : "-",
        Status: obj.color_status ? obj.color_status : "-",
        Groupname: obj.group_name ? capitalizeFirstLetter(obj.group_name) : "-", 
        Empname: obj.emp_name ? obj.emp_name : "-",
      }));
    } else if (progress == "approved") {
      setColDef(schedularScraperDT);
      transformedData = data.map((obj) => ({
        Urlid: obj.url_id,
        Websiteurl: obj.website_url,
        Projectname: obj.project_name,
        ScraperType:
        obj.is_multiurl && obj.scraper_type && obj.scraper_type === "PAGINATION" ?
        transformString(obj.scraper_type.replace(/_/g, ' ') +
        " > " +
        obj.pagination_type.replace(/_/g, ' ') +
        " > " +
        obj.data_source_type.replace(/_/g, ' ') + " > " + "MULTI URL")
         : obj.scraper_type && obj.scraper_type === "PAGINATION"
          ? transformString(obj.scraper_type.replace(/_/g, ' ') +
          " > " +
          obj.pagination_type.replace(/_/g, ' ') +
          " > " +
          obj.data_source_type.replace(/_/g, ' '))
          : obj.is_multiurl && obj.scraper_type && obj.scraper_type === "NO_PAGINATION"? "No Pagination > Multi Url" 
          :obj.file_download_flag ? "File Download" : obj.scraper_type ? transformString(obj.scraper_type.replace(/_/g, ' ')) : "",
        ScraperName: obj.scraper_name ? capitalizeFirstLetter(obj.scraper_name) : "-",
        Createdon: dateFormatter(obj.updated_on),
        Updatedon: dateFormatter(obj.updated_on),
        AddedBy: obj.added_by_emp_name ? obj.added_by_emp_name : "-",
        LastRun: obj.last_run ? dateFormatter(obj.last_run) : "-",
        NextRun: obj.next_run_time ? dateFormatter(obj.next_run_time) : "-",
        ScheduleBy: obj.scheduled_by_emp_name ? obj.scheduled_by_emp_name : "-",
        Status: obj.progress_status ? checkStatus(obj.progress_status) : "-",
        MoreInfo: `Scheduled On: ${dateFormatter(
          obj.updated_on
        )}; Scheduled By: ${obj.scheduled_by_emp_name ? obj.scheduled_by_emp_name : "-"
          }; Created on: ${dateFormatter(obj.added_on)}; Added By: ${obj.added_by_emp_name ? obj.added_by_emp_name : "-"
          }; Updated On: ${obj.updated_on ? dateFormatter(obj.updated_on) : "-"}`,
        Groupname: obj.group_name ? capitalizeFirstLetter(obj.group_name) : "-",
        Frequency: obj.frequency_data
          ? obj.frequency_data.frequency
          : obj.chrone_expression
            ? cronToText(obj.chrone_expression)
            : "-",
        Duration: obj.time_taken_to_complete
          ? formatTimeString(obj.time_taken_to_complete)
          : "-",
        Preview: obj.output_file_path ? obj.output_file_path : null,
        Log: obj.error_log_file_path ? obj.error_log_file_path : null,
        Csv: obj.csv_output_log_file_path ? obj.csv_output_log_file_path : null,
        OutputFile: obj.csv_output_file_path? obj.csv_output_file_path:null,
        Download: obj.csv_output_file_path
          ? obj.csv_output_file_path
          : obj.output_file_path
            ? obj.output_file_path
            : obj.error_log_file_path
              ? obj.error_log_file_path
              : null,
        ProjectCode: obj.project_code ? obj.project_code : null,
        Empname: obj.scheduled_by_emp_name ? obj.scheduled_by_emp_name : "-",
        Jobid:obj.job_id?obj.job_id : '-',
      }));
    } else if (progress == "not_working") {
      setColDef(notWorkingScrapersDT);
      transformedData = data.map((obj) => ({
        Urlid: obj.url_id,
        Websiteurl: obj.website_url,
        Projectname: obj.project_name,
        ScraperType:
          obj.is_multiurl && obj.scraper_type && obj.scraper_type === "PAGINATION" ?
          transformString(obj.scraper_type.replace(/_/g, ' ') +
          " > " +
          obj.pagination_type.replace(/_/g, ' ') +
          " > " +
          obj.data_source_type.replace(/_/g, ' ') + " > " + "MULTI URL")
           : obj.scraper_type && obj.scraper_type === "PAGINATION"
            ? transformString(obj.scraper_type.replace(/_/g, ' ') +
            " > " +
            obj.pagination_type.replace(/_/g, ' ') +
            " > " +
            obj.data_source_type.replace(/_/g, ' '))
            : obj.is_multiurl && obj.scraper_type && obj.scraper_type === "NO_PAGINATION"? "No Pagination > Multi Url" 
            :obj.file_download_flag ? "File Download" : obj.scraper_type ? transformString(obj.scraper_type.replace(/_/g, ' ')) : "",
        ScraperName: obj.scraper_name ? capitalizeFirstLetter(obj.scraper_name) : "-",
        AddedBy: obj.emp_name ? obj.emp_name : "-",
        Createdon: dateFormatter(obj.failed_on),
        Updatedon: dateFormatter(obj.updated_on),
        Failedon: obj.failed_on ? dateFormatter(obj.failed_on) : "-",
        MoreInfo: `Failed On: ${obj.failed_on ? dateFormatter(obj.failed_on) : "-"
          }; Updated On: ${dateFormatter(
            obj.updated_on
          )};  Created on: ${dateFormatter(obj.added_on)}; Added By: ${obj.emp_name ? obj.emp_name : "-"
          }`,
        Groupname: obj.group_name ? capitalizeFirstLetter(obj.group_name):'-',
        Empname: obj.emp_name ? obj.emp_name : "-",
        status: obj.progress_status ? obj.progress_status : "-"
      }));
    } else if (progress == "disabled") {
      setColDef(todaysScheduleDT);
      transformedData = data.map((obj) => ({
        Urlid: obj.url_id,
        Websiteurl: obj.website_url,
        Projectname: obj.project_name,
        ScraperType:
        obj.is_multiurl && obj.scraper_type && obj.scraper_type === "PAGINATION" ?
        transformString(obj.scraper_type.replace(/_/g, ' ') +
        " > " +
        obj.pagination_type.replace(/_/g, ' ') +
        " > " +
        obj.data_source_type.replace(/_/g, ' ') + " > " + "MULTI URL")
         : obj.scraper_type && obj.scraper_type === "PAGINATION"
          ? transformString(obj.scraper_type.replace(/_/g, ' ') +
          " > " +
          obj.pagination_type.replace(/_/g, ' ') +
          " > " +
          obj.data_source_type.replace(/_/g, ' '))
          : obj.is_multiurl && obj.scraper_type && obj.scraper_type === "NO_PAGINATION"? "No Pagination > Multi Url" 
          :obj.file_download_flag ? "File Download" : obj.scraper_type ? transformString(obj.scraper_type.replace(/_/g, ' ')) : "",
        ScraperName: obj.scraper_name ? capitalizeFirstLetter(obj.scraper_name) : "-",
        Comment: obj.disable_reason ? capitalizeFirstLetter(obj.disable_reason) : "-",
        DisabledOn: obj.disabled_on ? dateFormatter(obj.disabled_on) : "-",
        DisabledBy: obj.disabled_by_emp_name ? obj.disabled_by_emp_name : "-",
        Groupname: obj.group_name ? capitalizeFirstLetter(obj.group_name):'-',
        MoreInfo: `Disabled On: ${obj.disabled_on ? dateFormatter(obj.disabled_on) : "-"
          }; Disabled By: ${obj.disabled_by_emp_name ? obj.disabled_by_emp_name : "-"
          }; Disable Reason: ${obj.disable_reason ? obj.disable_reason : "-"}`,
      }));
    }
  
    setRowData(transformedData);
    const sortedData = [...transformedData].sort((a, b) => new Date(b.Createdon) - new Date(a.Createdon));
    setDisplayData(sortedData);
    setLoading(false);
  };

  const handleProgress = (value) => {
    setProgress(value);
    sessionStorage.setItem("currentState", value);
    setCurrentCreatorFilter(null)
    setCurrentProjectFilter(null)
    setCurrentAction(null)
    setProjectFilterApplied(false)
    setStartDate(null)
    
    setToDate(null)
    setRowData([])
    history.push(`/`)
  };



  // gets count of number of scrapers in each tab
  const dashboardAPI = async () => {
    if (projects) {
      try {
        const endp = `${version}/dashboard-by-project-id/?projects_list=${projects}`;
        const response = await apiInstance.post(endp, null);
        if (response.statusText === "OK") {
     
          setDashboard(response.data.details);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // handles task which needs to be performed when go button is clicked
  const handleScheduleMultiple = async()=>{
    // && currentAction != "Re-run failed scrapers"
      if(projectFilterApplied == false){
        toast.info("Please apply a project filter first",{
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          })
        return;
      }
      if(currentAction == null){
        toast.info("Please select an action first",{
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          })
          return;
      }
      if(selectedRowForSchedule.length ==0){
        toast.info("Please select a row first",{
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          })
        return;
      }
    if(currentAction == "Download"){
  
      let tempCsvArray = []
      let project_code = selectedRowForSchedule[0].ProjectCode
      selectedRowForSchedule.forEach(row=>{
        if(row.OutputFile){
          tempCsvArray.push(row.OutputFile)
        }
        
      })
      try {
        const endp = `${version}/bulk_csv_download`;

        const response = await apiInstance.post(endp, {
          csv_add:tempCsvArray,
          project_code:parseInt(project_code),
        });
        if (response.statusText === "OK") {
          fetch(response.data.presigned_url)
          .then(response => response.blob())
          .then(blob => {
            // Create a temporary link element
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
        
            // Set the desired filename for the downloaded file
            link.download = `${currentProjectFilter.name}_${getCurrentDateTime()}.zip`; // Example: 'projectName_2024-06-28_12-30-45.pdf'
        
            // Programmatically trigger the download
            link.click();
        
            // Clean up
            URL.revokeObjectURL(link.href);
          })
          .catch(error => {
            handleApiError(error)
            console.error('Error downloading file:', error);
          });

          handleClearFilter()

        }
      } catch (error) {
        handleApiError(error)
        console.log("error in setting the table:" + error);
      }

    }else if(currentAction == "Rerun"){
      console.log(selectedRowForSchedule)
      let tempurlarray = []
      selectedRowForSchedule.forEach(row=>{
        tempurlarray.push(row.Urlid);
      })
      try {
        const endp = `${version}/run_multiple_scraper_once`;

        const response = await apiInstance.post(endp, {
          scraper_ids: tempurlarray,
          emp_id: parseInt(session.employeeCode)
        });
        if (response.statusText === "OK") {
          toast.success("Scrapers scheduled successfully")
          handleClearFilter()
          setTimeout(() => {
            window.location.reload()
          }, 2000);

        }
      } catch (error) {
        handleApiError(error)
        console.log("error in running scrapers:" + error);
      }
    }else if(currentAction == "Re-Schedule"){
      let tempurlarray = []
      let tempjobarray = []
      selectedRowForSchedule.forEach(row=>{
        tempurlarray.push(row.Urlid);
        tempjobarray.push(row.Jobid)
      })
      setUrlIdArray(tempurlarray);
      setJobIdArray(tempjobarray)
      // this opens popup for scheduling
      setOpenScheduler(true)
      }else if(currentAction =="Disable"){
        setOpen3(true);
      }

    
  }

  const handleMultiDisable = async ()=>{
    let tempURLArray = []
        selectedRowForSchedule.forEach(row=>{
          if(row.Urlid){
            tempURLArray.push(row.Urlid)
          }
          
        })
        console.log(tempURLArray)
        try {
          const endp = `${version}/delete_scrapper`;
  
          const response = await apiInstance.patch(endp, {
            url_ids:tempURLArray,
            emp_code:parseInt(session.employeeCode),
            disable_flag:true,
            comment:disableComment,
          });
          if (response.statusText === "OK") {
            toast.success("Scrapers disabled successfully")
            window.location.reload()
          }
        } catch (error) {
          handleApiError(error)
          console.log("Error in disabling scrapers" + error);
        }
  }

  const fetchData = async () => {
    if (projects) {
    
      await dashboardAPI();
      const interval = setInterval(async () => await dashboardAPI(), 60000);
      setIntervalId(interval);
      return () => clearInterval(intervalId);
    }
  };

  const handleInterval = () => {
    clearInterval(intervalId);
  };
//makes a list of project: eg: "2013, 2135, 713"
  const makeProjectList = (data) => {
    return data.map((invoice) => invoice.invoice_id).join(",");
  };

  // get all projects of the user from timesheet
  const projectlistAPI = async () => {

    try {
      const url = process.env.REACT_APP_TIMESHEET_PROJECTS;
      let formData = new FormData();
      formData.append("date", getCurrentDate());
      const headers = {
        Authorization: `Bearer ${session.accessToken}`,
      };
   

      const response = await axios.post(url, formData, { headers });

      if (response.statusText === "OK") {
     
        setProjects(makeProjectList(response.data));

    
      } else {
        console.log("failed to get project list");
      }
    } catch (e) {
      console.log("this is what failed while getting project list", e);
    }
  };

  // row data
  useEffect(() => {
   
    if (location.state || session) {
      // checks if data in session, then sets progress
      if (current_state != null) {
        setProgress(current_state);
      }
      projectlistAPI();
      fetchData();
    } else if (!session || !location.state) {
      history.push("/signin");
    }
  }, [projects]);

  
  // gets called first, whne page loads
  useEffect(() => {
    if (location.state || session) {
      getUserDetails();
    } else if (!session || !location.state) {
      history.push("/signin");
    }
    setColDef(pendingScraperDT)
  }, []);

  // fetches user data from timesheet using token, and saves into db
  const getUserDetails = async () => {
    try {
      const url = process.env.REACT_APP_TIMESHEET_USER_DETAILS;

      const headers_ = {
        Authorization: `Bearer ${session.accessToken}`,
      };
      const response = await axios.get(url, { headers: headers_ });
      if (response.statusText === "OK") {
        let user_data = response.data;
        localStorage.setItem("techLead", response.data.is_tech_lead)
        user_data["emp_code"] = parseInt(session.employeeCode);
   
        saveUserInfoIntoDB(user_data);
      }
    } catch (error) {
      console.log("error in setting the table:" + error);
    }
  };
  // creates user if logged in for first time, else updates its information
  const saveUserInfoIntoDB = async (data) => {
    try {
      const endp = `${version}/save-user-details/`;

      const response = await apiInstance.post(endp, data);
      if (response.statusText === "OK") {
        console.log("User data updated successfully");
      }
    } catch (error) {
      console.log("error in setting the table:" + error);
    }
  };


  // fetches data of all scrapers for current progress
  const progressAPI = async () => {
   
    if(rowData.length>0){
      const tempData = [...rowData]
      const sortedData = [...tempData].sort((a, b) => new Date(b.Createdon) - new Date(a.Createdon));
    setDisplayData(sortedData);
      setRowData(sortedData)
      if (progress == "pending"){
        setColDef(pendingScraperDT)
      }else if(progress == "approved"){
        setColDef(schedularScraperDT)
      }else if(progress == "disabled"){
        setColDef(todaysScheduleDT)
      }else if(progress == "not_working"){
        setColDef(notWorkingScrapersDT)
      }
      setLoading(false)
   
      return;
      
    }
    if(projects=="" || !projects){
      return
    }

    if(!session) return
      try {
        const currentRequestOrder = ++requestOrderRef.current;
        const endp = `${version}/get-scrapers-by-project-ids/?progress_status=${progress}&project_ids=${projects}`;
        const response = await apiInstance.get(endp, null);
        if (response.statusText === "OK") {
          if(currentRequestOrder != requestOrderRef.current){
            return 
          }
          handleProgressUpdation(response.data.data);
          
          console.log(progress,response.data.data)
          const counts = {
            projectNames: {},
            empNames: {}
          };

          response.data.data.forEach(({ project_name, emp_name, scheduled_by_emp_name }) => {
            counts.projectNames[project_name] = (counts.projectNames[project_name] || 0) + 1;
            if (progress === "approved") {
                counts.empNames[scheduled_by_emp_name] = (counts.empNames[scheduled_by_emp_name] || 0) + 1;
            } else {
                counts.empNames[emp_name] = (counts.empNames[emp_name] || 0) + 1;
            }
        });

          let uniqueProjectNamesWithCounts = Object.entries(counts.projectNames).map(([name, count]) => ({ name, count }));
          let uniqueCreatedByNamesWithCounts = Object.entries(counts.empNames).map(([name, count]) => ({ name, count }));
          uniqueProjectNamesWithCounts.unshift({ name: "All", count: response.data.data.length });
          uniqueCreatedByNamesWithCounts.unshift({ name: "All", count: response.data.data.length });
          setProjectFilterList([...uniqueProjectNamesWithCounts])
          setCreatorFilterList([...uniqueCreatedByNamesWithCounts])
        }
      } catch (error) {
        console.log("error in setting the table:" + error);
      }
  };

  // gets called when there is a change in progress,or projects of user
  useEffect(() => {
    if (progress && (location.state || session)) {
      setLoading(true);
      progressAPI();
     
    }
  }, [progress, projects]);



  if (location.state || session) {
    return (
      <>
        <Header onLogout={handleInterval} />
        <Box id="page-container" className="al_center">
          <Container maxWidth>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box className="cardbx fx_sb" p={1}>
                  <Box
                    style={{
                      backgroundColor:
                        progress === "pending" ? "#1930ab" : "#effffe",
                      display: "grid",
                      placeItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <IconButton
                      disableRipple
                      onClick={() => handleProgress("pending")}
                    >
                      <ScheduleIcon
                        fontSize="large"
                        className="card_icon4"
                        style={{
                          color: progress === "pending" ? "white" : "#00b0f0",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" gray card_heading4">
                        Pending for Scheduling
                      </Typography>
                      {dashboard.pending_for_approval != null ? (
                        <Typography variant="h5" className=" col1 card_number">
                          {dashboard.pending_for_approval}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h5"
                          className=" col1 card_number"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width={20}
                          />
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box className="cardbx fx_sb" p={1}>
                  <Box
                    style={{
                      backgroundColor:
                        progress === "approved" ? "#1930ab" : "#effffe",
                      display: "grid",
                      placeItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <IconButton
                      disableRipple
                      onClick={() => handleProgress("approved")}
                    >
                      <EventAvailableIcon
                        fontSize="large"
                        className="card_icon4"
                        style={{
                          color: progress === "approved" ? "white" : "#00b0f0",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" gray card_heading4">
                        Scheduled Scrapers
                      </Typography>
                      {dashboard.approved != null ? (
                        <Typography variant="h5" className=" col1 card_number">
                          {dashboard.approved}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h5"
                          className=" col1 card_number"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width={20}
                          />
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box className="cardbx fx_sb" p={1}>
                  <Box
                    style={{
                      backgroundColor:
                        progress === "not_working" ? "#1930ab" : "#effffe",
                      display: "grid",
                      placeItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <IconButton
                      disableRipple
                      onClick={() => handleProgress("not_working")}
                    >
                      <ErrorIcon
                        fontSize="large"
                        className="card_icon4"
                        style={{
                          color:
                            progress === "not_working" ? "white" : "#00b0f0",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" gray card_heading4">
                        Failed Scrapers
                      </Typography>
                      {dashboard.not_working != null ? (
                        <Typography variant="h5" className=" col1 card_number">
                          {dashboard.not_working}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h5"
                          className=" col1 card_number"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width={20}
                          />
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box className="cardbx fx_sb" p={1}>
                  <Box
                    style={{
                      backgroundColor:
                        progress === "disabled" ? "#1930ab" : "#effffe",
                      display: "grid",
                      placeItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <IconButton
                      disableRipple
                      onClick={() => handleProgress("disabled")}
                    >
                      <TodayIcon
                        fontSize="large"
                        className="card_icon4"
                        style={{
                          color: progress === "disabled" ? "white" : "#00b0f0",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box className="al_right" m={1}>
                      <Typography variant="h6" className=" gray card_heading4">
                        Disabled Scrapers
                      </Typography>
                      {dashboard.disable_srapers_count != null ? (
                        <Typography variant="h5" className=" col1 card_number">
                          {dashboard.disable_srapers_count}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h5"
                          className=" col1 card_number"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width={20}
                          />
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box className="whitebx" mt={1}>
              {isLoading ? (
                <Box className="fx_sb_mob ai_center" >
                  <Box className="margin_10">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "20px" }}
                      animation="wave"
                      width={140}
                    />
                  </Box>
                  <Box className="table_searchbx">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "20px" }}
                      animation="wave"
                      width={140}
                    />
                  </Box>
                </Box>
              ) : (

                <Box className="  ai_center" mb={1}>

            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item lg={2} md={2} sm={12} xs={12}>
              <Box className="ai_center">
                    <Typography variant="h6" className=" col1 card_title al_left">
                      {heading[progress]}
                    </Typography>
                  </Box>
              </Grid> 
  
                {(progress =="pending" || progress =="approved") || progress =="not_working" ? (
                <Grid item lg={10} md={10} sm={12} xs={12}>
                 
                <Box >
                      <Grid container alignItems="center" spacing={1}>
                      <Grid item lg={1.5} md={1.5} sm={6} xs={12}>
                      <Box className="schedule ">
                        {(progress == "approved" || progress =="not_working") && 
                          <Autocomplete
                              id="country-select-demo"
                              options={progress == "approved" ? multiActionArray : ["Rerun"]}
                         
                              value={currentAction}
                              onChange={(e,newVal)=> handleMultiActionChange(newVal)}
                              getOptionLabel={(option) => option}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}  >
                                  <Box>{option}</Box>
                                </Box>
                              )}
                              
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select an Action"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                              
                          }
                          </Box>
                        </Grid>
                      <Grid item lg={0.5} md={0.5} sm={6} xs={12}>
               <Box className="al_left">
               {(progress == "approved" || progress =="not_working") && 
                   <StyledIconButton
                   autoFocus 
                   disabled={!enableAction}
                   onClick={handleScheduleMultiple}
                   title="Go"
                 >
                   <ArrowForwardIcon fontSize="small"/> 
                 </StyledIconButton>
  }
               </Box>
              </Grid>
              {/* element for search input */}
                        <Grid item lg={2} md={2} sm={6} xs={12}>
                          <Box className="table_searchbx">
                            <TextField
                              variant="standard"
                              placeholder="URL id, Scraper name, URL"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onFilterTextBoxChanged}
                              id="filter-text-box"
                            />
                          </Box>
                        </Grid>
                        <FilterRow progress={progress} handleApplyFilter={handleApplyFilter} handleClearFilter={handleClearFilter} />
                      </Grid>
                    </Box>
                  </Grid>):( <Box className="table_searchbx">
                    
                    <TextField

                      variant="standard"
                      placeholder="Project name, URL id, Scraper name, URL, Added by"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onFilterTextBoxChanged}
                      id="filter-text-box"
                    />
                  </Box>)}

                </Grid> 
              </Box>
              )}

              <Box
                className={"ag-theme-quartz"}
                style={{ width: "100%", height: "62vh" }}
              >
                {isLoading ? (
                  <>
                    {Array.from({ length: 10 }).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="text"
                        sx={{ fontSize: "18px", width: "100%" }}
                        animation="wave"
                      />
                    ))}
                  </>
                ) : displayData && displayData.length > 0 ? (
                  <AgGridReact 
                    rowData={displayData}
                    columnDefs={colDefs}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    enableCellTextSelection={true}
                    onSelectionChanged={onSelectionChanged}
                    rowSelection="multiple"
                    filter={true}
                    pagination={true}
                    paginationPageSize={100}
                    onGridReady={onGridReady}
                    onCellClicked={(event) => {
                      if (event.colDef.field !== "Websiteurl") {
                        handleInterval();
                        if (
                          progress == "pending" &&
                          event.colDef.field == "Urlid"
                        ) {
                          history.push(
                            `${pushVersion}/pending-scraper-details/${event.data.Urlid}`,
                            {
                              status: progress,
                            }
                          );
                        } else if (
                          progress == "approved" &&
                          event.colDef.field == "Urlid"
                        ) {
                          history.push(
                            `${pushVersion}/approved-scraper-details/${event.data.Urlid}`,
                            {
                              status: progress,
                            }
                          );
                        } else if (
                          progress == "not_working" &&
                          event.colDef.field == "Urlid"
                        ) {
                          history.push(
                            `${pushVersion}/failed-scraper-details/${event.data.Urlid}`,
                            {
                              status: progress,
                            }
                          );
                        } else if (
                          progress == "disabled" &&
                          event.colDef.field == "Urlid"
                        ) {
                          history.push(
                            `${pushVersion}/disabled-scraper-details/${event.data.Urlid}`,
                            {
                              status: progress,
                            }
                          );
                        }
                      }
                    }}
                  />
                ) : (
                  <Box
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={record_not_found}
                      alt="logo"
                      style={{
                        width: "200px",
                        height: "200px",
                        margin: "0 0 10px 0",
                      }}
                    />
                    <Typography
                      className="col1"
                      style={{ fontWeight: 600, fontSize: "20px" }}
                    >
                      No Record Found
                    </Typography>
                  </Box>
                )}
              </Box>


            </Box>
          </Container>
        </Box>
        <Footer />

        <Box className="data preview">
          <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title2" className=" col1 card_title">
              {"Data Preview :"}
            </DialogTitle>

            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose2();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <HighlightOffIcon fontSize="small" className="gray" />
            </IconButton>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box className="whitebx text_area">
                  <Box className="input_field">
                    <TextField
                      id="filled-basic"
                      className="filleddp padding0"
                      variant="filled"
                      multiline
                      minRows={2}
                      maxRows={8}
                      name="message"
                      fullWidth
                      value={
                        fileData.type === "pre_logs"
                          ? fileData.data
                          : JSON.stringify(fileData.data, null, 2)
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {fileData.presigned_url && (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",

                          margin: "10px 0px 0px 0px",
                        }}
                      >
                        <Box>
                        {isDownloadingState ? <LoadingBtn/> :
                          <Button
                            autoFocus
                            className="approve_btn"
                            onClick={() => {
                              setDownloadingState(true)
                              handleDownload(
                                fileData.presigned_url,
                                fileData.fileName,
                                fileData.type
                              );
                            }}
                          >
                            Download
                          </Button>
  }
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Box>
        

        {/* modal for re scheduling  scrapers */}
        <Box className="data preview">
          <Dialog
            open={openScheduler}
            onClose={handleCloseSchedular}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            
          >
        

            <IconButton
              aria-label="close"
              onClick={() => {
                handleCloseSchedular();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <HighlightOffIcon fontSize="small" className="gray" />
            </IconButton>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box  my={5} className="whitebx3 schedule">
                    <Box className="heading_bx fx_sb_mob  ">
                      <Box className="">
                        <Typography variant="h6" className=" col1 card_title ">
                          Re-Schedule
                        </Typography>
                      </Box>

                    </Box>

                    {
                      selectedOption === "a" ? (
                        <Box className="cron_expression" my={2}>
                          <Grid container justifyContent="center" spacing={1}>
                            <Grid item lg={12} md={8} sm={12} xs={12}>
                              <FormControl fullWidth className="input_field">
                                <FilledInput
                                  id="filled-adornment-password"
                                  placeholder="Enter Cron Expression"
                                  value={cronexp || ""}
                                  onChange={(event) => {
                                    setCronExp(event.target.value);
                                  }}
                                  className="textfield_cron"
                                />
                              </FormControl>
                              <Box mt={1} className="al_left">
                                <Typography variant="h6" className="gray desc">
                                  {" "}
                                  Note: A cron expression is a string
                                  representing a schedule in a time-based job
                                  scheduling system (like Unix cron). It is
                                  typically made up of five or six fields,
                                  separated by space, that represent different
                                  units of time.
                                </Typography>

                                <Typography
                                  variant="h6"
                                  className="xstext"
                                  mt={1}
                                >
                                  {" "}
                                  Example: A cron expression 0 12 * * Mon means
                                  the task will run at 12:00 PM every Monday.
                                  Learn more{" "}
                                  <a
                                    href="https://crontab.guru/"
                                    className="a_underline"
                                    target="_blank"
                                  >
                                    click here
                                  </a>
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box className="fx_c" mt={4}>
                            <Button
                              className="approve_btn"

                            >
                              Re-schedule
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Schedule_Using_Date_Time_Frequency
                          urlid={urlIdArray}
                          schedule_flag={true}
                          emp_code={session.employeeCode}
                          job_id={jobIdArray}
                          reschedule_flag={true}
                          version={version}
                          pageType="approved_multiple"
                          setOpenScheduler={setOpenScheduler}
                        />
                      )
                    }
                  </Box>
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Box>
             {/* Modal Box for Disable */}

      <Box className="comment">
        <Dialog
          open={open3}
          onClose={() => setOpen3(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" className=" col1 card_title">
            Comment
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen3(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <HighlightOffIcon fontSize="small" className="gray" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="whitebx text_area">
                <Box className="input_field">
                  <TextField
                   aut
                    id="filled-basic"
                    className="filleddp padding0"
                    variant="filled"
                    multiline
                    name="message"
                    placeholder="Comment"
                    fullWidth
                    // value={disableComment}
                    onBlur={handleDisableComment}
                  />
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "30px",
              margin: "10px 22px 10px 22px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="Are you sure"
                  checked={csure}
                  onChange={() => {
                    setSure(!csure);
                  }}
                  size="10px"
                />
              }
              label={
                <span style={{ fontSize: "12px" }}>
                  Are you sure you want to disable scraper?
                </span>
              }
            />
            <Box>
              <Button
                onClick={() => {
                  handleMultiDisable()
                  progressAPI()
                  setOpen3(false);
                }}
                autoFocus
                className="approve_btn"
                disabled={!csure || disableComment === ""}
              >
                Disable
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
        <ToastContainer
        style={{ fontSize: "16px", width: "600px", height: "200px" }}
      />
      </>
    );
  } else {
    history.push("/signin");
  }
};

export default Home;



