import {
    Box,
    Autocomplete,
    Grid,
    IconButton,
    TextField,
    Typography,
  } from "@mui/material";
  
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; 
import { useState } from "react";
  
  
  const HeaderMappingBox =({data, filteredOptions, keyValueMap, handleApplyMapping, handleDataKeyChange, keyValueMapFinal, handleRemoveOption, mappingOptions})=> {
    const [currOption, setCurrOption] = useState(keyValueMap[data] ? {label:keyValueMap[data]} : null)
    const combinedOptions  = [...filteredOptions]
    const isCurroptionValid = () => {
      for (const key in keyValueMap) {
        if (key !== data && currOption &&  keyValueMap[key].toLowerCase() === currOption.label.toLowerCase()) {
          return false;
        }
      }
      return true;
    };
    if(currOption && mappingOptions.some(option => option.label.toLowerCase() == currOption.label.toLowerCase()) && isCurroptionValid()){
      
      combinedOptions.push({label:currOption.label.toUpperCase()})
    }    

    return (
        <Box mt={2}>
                        <Grid container spacing={1} alignItems="top" justifyContent="center">

                          <Grid item lg={5} md={5} sm={5} xs={5} >
                            <TextField disabled={true} id="outlined-basic" label="Outlined" variant="outlined" value={data} />
                          </Grid>
                          <Grid item lg={1} md={1} sm={1} xs={1} >
                            <IconButton
                              className="col1"
                              
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          </Grid>
                          <Grid item lg={5} md={5} sm={5} xs={5} >
                            <Box className="autocomplete_singal_select"> 

                              <Autocomplete
                              value={currOption}
                                options={combinedOptions}
                                autoHighlight
                                getOptionLabel={(option) => option.label }
                                filterOptions={(options, { inputValue }) => {
                                  return options.filter((option) =>
                                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                                  );
                                }}
                                renderOption={(props, option) => (
                                  <Box component="li" style={{display:'flex',justifyContent:'space-between'}} {...props}>
                                    <Typography variant="h6" className="xstext fw4">{option.label}</Typography>
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Choose a option"
                                  
                                    inputProps={{
                                      ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                                // freeSolo
                                onInputChange={(event, newInputValue) => {
                                  
                                
                                 setCurrOption({label:newInputValue})
                                  handleDataKeyChange(data, newInputValue.toUpperCase())
                                }}
                              />



                            </Box>
                          </Grid>
                          <Grid item lg={1} md={1} sm={1} xs={1} >
                            <IconButton
                              className="col1"
                              title="Add custom fields"
                              onClick={()=> {handleApplyMapping(data,keyValueMap[data])
                                setCurrOption({label:""})}
                              }
                            >
                              <AddCircleOutlineIcon className="large" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Box>
    )
}

export default HeaderMappingBox