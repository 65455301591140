import {create} from 'zustand';

import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme




  
 


const useHomeStore = create(set => ({
    displayData:[],
    setDisplayData: (newData)=> set({displayData:newData}),
    rowData:[],
    setRowData:(newVal)=>set({rowData:newVal}),
    projectFilterApplied:false,
    setProjectFilterApplied:(newVal)=>set({projectFilterApplied:newVal}),
      colDefs: "",
      setColDef: (newVal) => set({colDefs:newVal})
      

}));

export default useHomeStore;
