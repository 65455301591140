import React, { useState } from 'react'
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
    Box,
    IconButton,
  } from "@mui/material";

const PreviewLoaderCell = ({details, params,on_preview}) => {
    const [isDownloading, setIsDownloading] = useState(false)
  return (
    <>
            {params.data.status == "Completed" &&
            details?.project_code != undefined && !isDownloading ? (
              <IconButton
                onClick={() =>{
                    setIsDownloading(true)
                  on_preview(
                    {
                      json: params.data.preview,
                      log: params.data.preview_log,
                      csv: params.data.preview_csv,
                    },
                    details,
                    "pre_json",
                    setIsDownloading
                  )
                }
                }
              >
                <RemoveRedEyeIcon className="comment_icon" fontSize="small" />
              </IconButton>
            ) : isDownloading ? (<Box className="loadingbtn-container2"  >
                <Box className="dot2" />
                </Box>) :(
              "-"
            )}
          </>
  )
}

export default PreviewLoaderCell