import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  Popper,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Dayjs from "dayjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import FormHelperText from "@mui/material/FormHelperText";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useHistory } from "react-router-dom";
import scheduling from "../Assets/images/icon/schedule_1.gif";
import apiInstance from "../apiInterceptor";
const icon = <CheckBoxOutlineBlankIcon className="checkbox_schedule" />;
const checkedIcon = <CheckBoxIcon className="checkbox_schedule" />;
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formatFrequencyData, handleApiError } from "../utils";

const Schedule_Using_Date_Time_Frequency = ({
  urlid,
  schedule_flag,
  emp_code,
  job_id = null,
  reschedule_flag = false,
  freq_details,
  freq_type,
  version,
  cancelEnable,
  pageType,
  setOpenScheduler,
  allowed = true,
  add_flag = false,
  all_desc = []
}) => {

  const [selectedOption, setSelectedOption] = useState("One Time");
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [validationMessage1, setValidationMessage_Weekly] = useState("");
  const [validationMessage2, setValidationMessage_Daily_Monthly] = useState("");
  const [intervalValue, setIntervalValue] = useState(null);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedHours, setSelectedHours] = useState(null);
  const [recurWeeksOn, setRecurWeeksOn] = useState([]);
  const [isValidData, setIsValidData] = useState(false);
  const history = useHistory();
  const [confirmationAlert, setConfirmationAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);


  const dateFormatter = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":");
    const date = new Date();

    let formattedDate = Dayjs(date).hour(hours).minute(minutes).second(seconds);


    return formattedDate;
  };
  function getISTTimeRounded() {
    const now = new Date();

    now.setUTCMinutes(now.getUTCMinutes() + 5 * 60 + 30);

    const minutes = now.getUTCMinutes();
    const roundedMinutes = minutes % 5 === 0 ? minutes : minutes + (5 - minutes % 5);
    now.setUTCMinutes(roundedMinutes);
    now.setUTCSeconds(0); 
    const formattedDate = ('0' + (now.getUTCMonth() + 1)).slice(-2) + '/' +
        ('0' + now.getUTCDate()).slice(-2) + '/' +
        now.getUTCFullYear();

    const formattedTime = ('0' + now.getUTCHours()).slice(-2) + ':' +
        ('0' + now.getUTCMinutes()).slice(-2) + ':00';

    return {
        date: formattedDate,
        time: formattedTime
    };
}

  const resetState = () => {
    setStartDate(null);
    setStartTime(null);
    setIntervalValue(null);
    setSelectedMonths([]);
    setSelectedDays([]);
    setSelectedHours(null);
    setRecurWeeksOn([]);
    setValidationMessage_Weekly("");
    setValidationMessage_Daily_Monthly("");
  };

  const handleHours = (event, value) => {
    setSelectedHours(value);
  };
  const handleIntervalChange_Weekly = (event) => {
    const value = event.target.value;
    const val = parseInt(value, 10);

    if (val > 99) {
      setValidationMessage_Weekly("User can input a maximum of 2 digits");
    } else {
      setIntervalValue(val);
      setValidationMessage_Weekly("");
    }
  };

  const handleIntervalChange_Daily_Monthly = (event) => {
    const value = event.target.value;
    const val = parseInt(value, 10);
    if (val > 999) {
      setValidationMessage_Daily_Monthly(
        "User can input a maximum of 3 digits"
      );
    } else {
      setIntervalValue(val);
      setValidationMessage_Daily_Monthly("");
    }
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    resetState();
  };

  const handleWeeksOnChange = (event, value) => {
    setRecurWeeksOn(value);
  };
  const handleOkClick = () => {
    setOpen1(false); 
  };
  const handleOkClick2 = () => {
    setOpen2(false); 
  };

  const handleMonthChange = (event, value) => {
    setSelectedMonths(value);
  };

  const handleDayChange = (event, value) => {
    setSelectedDays(value);
  };

  const scheduleTask = async () => {
    if(pageType == "approved_multiple"){
      const formattedStartDate = startDate
      ? Dayjs(startDate).format("DD-MM-YYYY")
      : "";
      const formattedStartTime = startTime
        ? Dayjs(startTime).format("HH:mm:ss")
        : "";
      const commonData = urlid.length>1? {
        url_ids:urlid,
        job_ids:job_id,
        frequency: selectedOption,
        start_date: formattedStartDate,
        start_time: formattedStartTime,
        days_of_week: [],
        months: [],
        day: [],
        interval: null,
        schedule_by: parseInt(emp_code),
        reschedule_flag:true,

      }:{
        url_id:urlid[0],
        job_id:job_id[0],
        frequency: selectedOption,
        start_date: formattedStartDate,
        start_time: formattedStartTime,
        days_of_week: [],
        months: [],
        day: [],
        interval: null,
        schedule_by: parseInt(emp_code),
        reschedule_flag:true,
      }
      let requestData = {
        commonData,
      };
      if (selectedOption === "One Time") {
        requestData = {
          ...requestData,
        };
      } else if (selectedOption === "Hourly") {
        const intHours =
          Hours.find((hour) => hour.label === selectedHours.label)?.value || 0;
        requestData.commonData.interval = intHours;
        requestData = {
          ...requestData,
        };
      } else if (selectedOption === "Daily") {
        requestData.commonData.interval = intervalValue;
        requestData = {
          ...requestData,
        };
      } else if (selectedOption === "Weekly" || selectedOption === "weekly") {
        requestData.commonData.days_of_week = recurWeeksOn;
        const integerValues = commonData.days_of_week.map((day) => day.value);
        requestData.commonData.days_of_week = integerValues;
        requestData.commonData.interval = intervalValue;
        requestData = {
          ...requestData,
        };
      } else if (selectedOption === "Monthly") {
        const intMonths = selectedMonths.map((month) => month.value);
        requestData.commonData.months = intMonths;
        const intDays = selectedDays.map((day) => day.value);
        requestData.commonData.day = intDays;
        requestData = {
          ...requestData,
        };
      }
      
      const endp = urlid.length>1? `${version}/schedule_all_task_at_once` : `${version}/schedule_task_by_frequency`;
      try {
        const response = await apiInstance.post(endp, requestData.commonData);
        if (response.status === 200) {
          toast.success(`Succesfully scheduled scrapers`);
            setOpenScheduler(false)
            window.history.go(0);
        }
      } catch (error) {
        handleApiError(error)
      }
      return;
    }
    const formattedStartDate = startDate
      ? Dayjs(startDate).format("DD-MM-YYYY")
      : "";
    const formattedStartTime = startTime
      ? Dayjs(startTime).format("HH:mm:ss")
      : "";
    const commonData = reschedule_flag
      ? {
          start_date: formattedStartDate,
          start_time: formattedStartTime,
          url_id: urlid,
          frequency: selectedOption,
          days_of_week: [],
          months: [],
          day: [],
          interval: null,
          schedule_by: parseInt(emp_code),
          reschedule_flag: true,
          job_id: job_id,
        }
      : {
          start_date: formattedStartDate,
          start_time: formattedStartTime,
          url_id: urlid,
          frequency: selectedOption,
          days_of_week: [],
          months: [],
          day: [],
          interval: 0,
          schedule_by: parseInt(emp_code),
        };

    let requestData = {
      commonData,
    };
    if (selectedOption === "One Time") {
      requestData = {
        ...requestData,
      };
    } else if (selectedOption === "Hourly") {
      const intHours =
        Hours.find((hour) => hour.label === selectedHours.label)?.value || 0;
      requestData.commonData.interval = intHours;
      requestData = {
        ...requestData,
      };
    } else if (selectedOption === "Daily") {
      requestData.commonData.interval = intervalValue;
      requestData = {
        ...requestData,
      };
    } else if (selectedOption === "Weekly" || selectedOption === "weekly") {
      requestData.commonData.days_of_week = recurWeeksOn;
      const integerValues = commonData.days_of_week.map((day) => day.value);
      requestData.commonData.days_of_week = integerValues;
      requestData.commonData.interval = intervalValue;
      requestData = {
        ...requestData,
      };
    } else if (selectedOption === "Monthly") {
      const intMonths = selectedMonths.map((month) => month.value);
      requestData.commonData.months = intMonths;
      const intDays = selectedDays.map((day) => day.value);
      requestData.commonData.day = intDays;
      requestData = {
        ...requestData,
      };
    }

    if(add_flag){
      requestData.commonData.add_flag = true;
    }
    if(all_desc.some(data => data.desc == formatFrequencyData(requestData.commonData))){
      toast.error("Same frequency cannot be present")
      return
    }
    const endp = `${version}/schedule_task_by_frequency`;
    try {
      const response = await apiInstance.post(endp, requestData.commonData);
      if (response.status === 200) {
        toast.success(`Succesfully scheduled scraper for ${commonData.url_id}`);
        if(pageType!="approved" && pageType !="approved2"){
          setTimeout(() => {
            history.push(`/`);
          }, 2000);
        }else{
          setTimeout(() => {
            window.location.reload()
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
      handleApiError(error)
    }
  };
  useEffect(()=>{
    if(pageType == "failed" || pageType == "pending" || pageType=="approved_multiple" || pageType=="approved2"){
      setStartDate(Dayjs(getISTTimeRounded().date))
    setStartTime(dateFormatter(getISTTimeRounded().time))
    }
  },[])
  useEffect(() => {
    switch (selectedOption) {
      case "Weekly":
        if (
          startDate != null &&
          startTime != null &&
          recurWeeksOn.length > 0 &&
          intervalValue != null
        ) {
          setIsValidData(true);
        } else {
          setIsValidData(false);
        }
        break;
      case "Monthly":
        if (
          startDate != null &&
          startTime != null &&
          selectedMonths.length > 0 &&
          selectedDays.length > 0
        ) {
          setIsValidData(true);
        } else {
          setIsValidData(false);
        }
        break;
      case "Hourly":
        if (startDate != null && startTime != null && selectedHours != null) {

          setIsValidData(true);
        } else {
          setIsValidData(false);
        }
        break;
      case "Daily":
        if (
          startDate != null &&
          startTime != null &&
          intervalValue !== null &&
          !Number.isNaN(intervalValue)
        ) {

          setIsValidData(true);

        } else {
          setIsValidData(false);
        }
        break;

      case "One Time":
  
        if (startDate != null && startTime != null) {
          setIsValidData(true);
        } else {
          setIsValidData(false);
        }
        break;
    }
  }, [
    selectedOption,
    startDate,
    startTime,
    recurWeeksOn,
    selectedMonths,
    selectedDays,
    selectedHours,
    intervalValue,
  ]);

  const populateFrequencyData = (frequencyData) => {
    const [day, month, year] = frequencyData.start_date.split("-");

    const defaultSelectedMonths =
      frequencyData.months.length > 0
        ? mapValuesToTitles(Monthly, frequencyData.months)
        : [];
    const options = Monthly.filter((option) =>
      defaultSelectedMonths.includes(option.title)
    );

    const defaultSelectedDays =
      frequencyData.day.length > 0
        ? mapValuesToTitles(Days, frequencyData.day)
        : [];
    const dayoptions = Days.filter((option) =>
      defaultSelectedDays.includes(option.title)
    );

    const defaultSelectedWeekDays =
      frequencyData.days_of_week.length > 0
        ? mapValuesToTitles(Weekdays, frequencyData.days_of_week)
        : [];
    const weekDayOptions = Weekdays.filter((option) =>
      defaultSelectedWeekDays.includes(option.title)
    );
    if (frequencyData.frequency === "Hourly") {
      const option = Hours.find(
        (option) => option.value === frequencyData.interval
      );


      setSelectedHours(option);
    }
   
    setStartDate(Dayjs(`${month}/${day}/${year}`));
    setStartTime(dateFormatter(frequencyData.start_time));
    setIntervalValue(frequencyData.interval);

    setSelectedOption(frequencyData.frequency);
    setSelectedMonths(options);
    setSelectedDays(dayoptions);
    setRecurWeeksOn(weekDayOptions);
  };

  const checkBackDateTime =()=>{
    const formattedStartDate = startDate
      ? Dayjs(startDate).format("DD-MM-YYYY")
      : "";
    const formattedStartTime = startTime
      ? Dayjs(startTime).format("HH:mm:ss")
      : "";
      const dateTimeString = formattedStartDate + ' ' + formattedStartTime;
      const parts = dateTimeString.split(' ');
    const dateParts = parts[0].split('-');
    const timeParts = parts[1].split(':');
    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]);
    date.setHours(date.getHours() + 5); 
    date.setMinutes(date.getMinutes() + 30); 
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 5); 
    currentDate.setMinutes(currentDate.getMinutes() + 30);

    return date < currentDate; 
  }
  const showErrorDateTime = () =>{
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  }

  useEffect(() => {
    if (freq_details && reschedule_flag && freq_type == "date_time_freq") {
      populateFrequencyData(freq_details);
    }
  }, [freq_details, freq_type, reschedule_flag]);

  return (
    <>
      <Box id="schedule_using_date_time_frequency">
        <Box className="cron_expression" my={2}>
          <Box ml={1}>
          {showError && <Box mb={2}
            sx={{
                width: '100%',
                padding: 0.5, 
                backgroundColor: 'error.light', //#eac0c0
                color: 'error.contrastText', //error.main
                borderRadius: 1, 
                border: '1px solid', 
                borderColor: 'error.main', 
                textAlign: 'center',
                fontSize:'14px'
            }}
        >
           Please provide a valid future date or time.
        </Box>}
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedOption}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="One Time"
                  control={<Radio />}
                  label="One Time"
                />
                <FormControlLabel
                  value="Hourly"
                  control={<Radio />}
                  label="Hourly"
                />
                <FormControlLabel
                  value="Daily"
                  control={<Radio />}
                  label="Daily"
                />
                <FormControlLabel
                  value="Weekly"
                  control={<Radio />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="Monthly"
                  control={<Radio />}
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box>
            {selectedOption === "One Time" ? (
              <Box mt={1}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box className="datepicker ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date*"
                          fullWidth
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={Dayjs()}
                          //format="MM/DD/YYYY"
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Start Time*"
                          fullWidth
                          value={startTime}
                          onChange={(time) => setStartTime(time)}
                          //format= "hh:mm A"
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : selectedOption === "Hourly" ? (
              <Box mt={1}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box className="datepicker ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date*"
                          fullWidth
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={Dayjs()} // Set minDate to today's dat
                          //format="MM/DD/YYYY"
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Start Time*"
                          fullWidth
                          value={startTime}
                          onChange={(time) => setStartTime(time)}
                          //format= "hh:mm A"
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="autocomplete_singal_select" mt={1}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={Hours}
                        value={selectedHours}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Hours*"
                            placeholder="Hours*"
                          />
                        )}
                        onChange={handleHours}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : selectedOption === "Daily" ? (
              <Box mt={1}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box className="datepicker ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date*"
                          fullWidth
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={Dayjs()} // Set minDate to today's date
                          //format="MM/DD/YYYY"
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Start Time*"
                          fullWidth
                          value={startTime}
                          onChange={(time) => setStartTime(time)}
                          // format='hh:mm A'
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="datepicker" mt={1}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Recur Every Days*"
                        placeholder="Recur Every Days*"
                        type="number"
                        value={intervalValue}
                        onChange={handleIntervalChange_Daily_Monthly}
                        error={Boolean(validationMessage2)}
                        variant="outlined"
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                      <FormHelperText error>
                        {validationMessage2}
                      </FormHelperText>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : selectedOption === "Weekly" ? (
              <Box className="datetime_frequency    al_center" mt={1}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box className="datepicker ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date*"
                          fullWidth
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={Dayjs()} // Set minDate to today's date
                          //format="MM/DD/YYYY"
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Start Time*"
                          fullWidth
                          value={startTime}
                          onChange={(time) => setStartTime(time)}

                          //format='hh:mm A'
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="datepicker" mt={1}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={Weekdays}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={recurWeeksOn}
                        onChange={handleWeeksOnChange}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                              className="xstext"
                            />
                            {option.title}
                          </li>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Recur Every Weeks On*"
                            placeholder="Recur Every Weeks On*"
                          />
                        )}
                      />
                    </Box>
                  </Grid> */}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="datepicker" mt={1}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={Weekdays}
                        disableCloseOnSelect
                        open={open1}
                        onOpen={() => setOpen1(true)} 
                        onClose={() => setOpen1(false)} 
                        getOptionLabel={(option) => option.title}
                        value={recurWeeksOn}
                        onChange={handleWeeksOnChange}
                        PopperComponent={(props) => (
                          <Popper {...props} style={{ width: props.style.width }}>
                            <Paper {...props}>
                              {props.children}
                              {/* OK button inside dropdown */}
                              <Box display="flex" justifyContent="right" padding={1}>
                                <Button
                                  // variant="contained"
                                  // color="primary"
                                  onClick={handleOkClick}
                                >
                                  OK
                                </Button>
                              </Box>
                            </Paper>
                          </Popper>
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                              className="xstext"
                            />
                            {option.title}
                          </li>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Recur Every Weeks On*"
                            placeholder="Recur Every Weeks On*"
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="datepicker" mt={1}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Recur Interval*"
                        placeholder="Interval*"
                        type="number"
                        value={intervalValue}
                        onChange={handleIntervalChange_Weekly}
                        error={Boolean(validationMessage1)}
                        variant="outlined"
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                      <FormHelperText error>
                        {validationMessage1}
                      </FormHelperText>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box mt={1}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box className="datepicker ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date*"
                          fullWidth
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={Dayjs()} // Set minDate to today's dat
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Start Time*"
                          fullWidth
                          value={startTime}
                          onChange={(time) => setStartTime(time)}
                          //format= "hh:mm A"
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="datepicker " mt={1}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo1"
                        options={Monthly}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        open={open2}
                        onOpen={() => setOpen2(true)} 
                        onClose={() => setOpen2(false)} 
                        value={selectedMonths}
                        onChange={handleMonthChange}
                        PopperComponent={(props) => (
                          <Popper {...props} style={{ width: props.style.width }}>
                            <Paper {...props}>
                              {props.children}
                              {/* OK button inside dropdown */}
                              <Box display="flex" justifyContent="right" padding={1}>
                                <Button
                                  // variant="contained"
                                  // color="primary"
                                  onClick={handleOkClick2}
                                >
                                  OK
                                </Button>
                              </Box>
                            </Paper>
                          </Popper>
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                              className="xstext"
                            />
                            {option.title}
                          </li>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Monthly*"
                            placeholder="Monthly*"
                          />
                        )}
                      />
                    </Box>

                    <Box mt={2}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={Days}
                        getOptionLabel={(option) => option.title}
                        disableCloseOnSelect
                        open={open1}
                        onOpen={() => setOpen1(true)} 
                        onClose={() => setOpen1(false)} 
                        value={selectedDays}
                        onChange={handleDayChange}
                        PopperComponent={(props) => (
                          <Popper {...props} style={{ width: props.style.width }}>
                            <Paper {...props}>
                              {props.children}
                              {/* OK button inside dropdown */}
                              <Box display="flex" justifyContent="right" padding={1}>
                                <Button
                                  // variant="contained"
                                  // color="primary"
                                  onClick={handleOkClick}
                                >
                                  OK
                                </Button>
                              </Box>
                            </Paper>
                          </Popper>
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                              className="xstext"
                            />
                            {option.title}
                          </li>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Days*"
                            placeholder="Days*"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>

          <Box
            className="fx_c"
            mt={
              selectedOption == "Monthly" || selectedOption == "Weekly" ? 1 : 4
            }
          >
            <Button
              className="approve_btn"
              disabled={
                allowed ? reschedule_flag ? !isValidData : !schedule_flag || !isValidData : true
              }
              onClick={() => {
                if(!checkBackDateTime()){
                  setConfirmationAlert(true)
                }else{
                  showErrorDateTime();
                 
                }
              }}
            >
              {reschedule_flag && !add_flag ? "Re-schedule" : "Schedule"}
            </Button>
            {cancelEnable && (
                            <Button style={{marginLeft:'6px'}}
                              className="approve_btn"
                              onClick={cancelEnable}
                            >
                              Cancel
                            </Button>
                          )}
          </Box>
          
        </Box>
      </Box>

      {/* Reschedule Modal */}
      <Box className="data preview">
        <Dialog
          open={confirmationAlert}
          onClose={() => setConfirmationAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="  al_center" my={4}>
                <Box mb={2}>
                  <Box>
                    <img
                      src={scheduling}
                      alt="Re-Schedule"
                      className="remove_icon_gif"
                    />
                  </Box>
                </Box>
                <Typography variant="h5" className="gray fw6">
                  Are you sure you want to{" "}
                  {reschedule_flag  && !add_flag ? "re-schedule" : "schedule"}?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Box className="fx_c" mb={2}>
            <Box mr={1}>
              <Button
                onClick={() => {
                  setConfirmationAlert(false);
                  scheduleTask();
                }}
                autoFocus
                className="approve_btn"
              >
                {reschedule_flag  && !add_flag ? "Re-schedule" : "Schedule"}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => setConfirmationAlert(false)}
                autoFocus
                className="cancel_btn"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default Schedule_Using_Date_Time_Frequency;

const mapValuesToTitles = (optionsArray, values) => {
  return values.map((value) => {
    const option = optionsArray.find((option) => option.value === value);
    return option ? option.title : null;
  });
};

const Hours = [
  { label: "Every 1 Hour", value: 1 },
  { label: "Every 2 Hours", value: 2 },
  { label: "Every 3 Hours", value: 3 },
  { label: "Every 4 Hours", value: 4 },
  { label: "Every 5 Hours", value: 5 },
  { label: "Every 6 Hours", value: 6 },
  { label: "Every 7 Hours", value: 7 },
  { label: "Every 8 Hours", value: 8 },
  { label: "Every 9 Hours", value: 9 },
  { label: "Every 10 Hours", value: 10 },
  { label: "Every 11 Hours", value: 11 },
  { label: "Every 12 Hours", value: 12 },
];

const Days = [
  { title: "1 Day", value: 1 },
  { title: "2 Day", value: 2 },
  { title: "3 Day", value: 3 },
  { title: "4 Day", value: 4 },
  { title: "5 Day", value: 5 },
  { title: "6 Day", value: 6 },
  { title: "7 Day", value: 7 },
  { title: "8 Day", value: 8 },
  { title: "9 Day", value: 9 },
  { title: "10 Day", value: 10 },
  { title: "11 Day", value: 11 },
  { title: "12 Day", value: 12 },
  { title: "13 Day", value: 13 },
  { title: "14 Day", value: 14 },
  { title: "15 Day", value: 15 },
  { title: "16 Day", value: 16 },
  { title: "17 Day", value: 17 },
  { title: "18 Day", value: 18 },
  { title: "19 Day", value: 19 },
  { title: "20 Day", value: 20 },
  { title: "21 Day", value: 21 },
  { title: "22 Day", value: 22 },
  { title: "23 Day", value: 23 },
  { title: "24 Day", value: 24 },
  { title: "25 Day", value: 25 },
  { title: "26 Day", value: 26 },
  { title: "27 Day", value: 27 },
  { title: "28 Day", value: 28 },
  { title: "29 Day", value: 29 },
  { title: "30 Day", value: 30 },
  { title: "31 Day", value: 31 },
  { title: "Last Day", value: -1 },
];

const Weekdays = [
  { title: "Monday", value: 0 },
  { title: "Tuesday", value: 1 },
  { title: "Wednesday", value: 2 },
  { title: "Thursday", value: 3 },
  { title: "Friday", value: 4 },
  { title: "Saturday", value: 5 },
  { title: "Sunday", value: 6 },
];

const Monthly = [
  { title: "January", value: 1 },
  { title: "February", value: 2 },
  { title: "March", value: 3 },
  { title: "April", value: 4 },
  { title: "May", value: 5 },
  { title: "June", value: 6 },
  { title: "July", value: 7 },
  { title: "August", value: 8 },
  { title: "September", value: 9 },
  { title: "October", value: 10 },
  { title: "November", value: 11 },
  { title: "December", value: 12 },
];
